import {
  Box, LinearProgress, Typography,
} from '@mui/material';
import React from 'react';
import logo from '../../assets/common/key-lsi-dark.svg';

function LoaderForm() {
  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      width: '100%',
      marginTop: 30,
    }}
    >
      <Box width="fit-content" display="flex" alignItems="center" flexDirection="column">
        <Box component="img" src={logo} width="50px" height="100%" sx={{ objectFit: 'contain' }} marginBottom={5} />
        <Typography
          textTransform="uppercase"
          variant="h6"
          fontWeight="300"
          letterSpacing="0.1rem"
        >
          Chargement ...
        </Typography>
        <Box width="100%">
          <LinearProgress sx={{ height: '2px' }} variant="indeterminate" color="secondary" />
        </Box>
      </Box>
    </Box>
  );
}

export {
  LoaderForm,
};
