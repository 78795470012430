import React, { useEffect, useMemo, useState } from 'react';
import { Box, Grid } from '@mui/material';
import HouseIcon from '@mui/icons-material/House';
import EuroIcon from '@mui/icons-material/Euro';
import AlignVerticalBottomIcon from '@mui/icons-material/AlignVerticalBottom';
import { FormProvider, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import SavingsIcon from '@mui/icons-material/Savings';
import { useLocation, useNavigate } from 'react-router-dom';
import { CurrencyTextFieldCustom } from '../../../common/inputs/currencytextfield';
import { StateCase } from '../../../common/inputs/statecase';
import { GridContainerFormInput, SectionTitle, SubmitButtonForm } from '../containerform';
import { FieldsArrayHousingLoan } from './fieldssarrayhousingloan';
import { FieldsArrayConsumerLoan } from './fieldsarrayconsumerloan';
import { useUpdateProject } from '../../../hooks/mutation/update-project-mutation.hook';

interface IFormInput {
  amount_rent: string
  amount_pension: string
  amount_others: string
}

function CostContent({
  index, data, lastYear, queryInfo, indexMax, to, indexAccordion, setIndexAccordion,
}: any) {
  const [activeStateOne, setActiveStateOne]: any = useState(false);
  const [activeStateTwo, setActiveStateTwo]: any = useState(false);
  const [activeStateThree, setActiveStateThree]: any = useState(false);
  const [activeStateFour, setActiveStateFour]: any = useState(false);
  const [activeStateFive, setActiveStateFive]: any = useState(false);
  const navigate = useNavigate();
  const { search } = useLocation();

  useEffect(() => {
    if (data?.personal_finance?.cost?.amount_rent > 0) {
      setActiveStateOne(true);
    }
    if (data?.personal_finance?.cost?.housing_loan.length > 0) {
      setActiveStateTwo(true);
    }
    if (data?.personal_finance?.cost?.consumer_loan.length > 0) {
      setActiveStateThree(true);
    }
    if (data?.personal_finance?.cost?.amount_pension > 0) {
      setActiveStateFour(true);
    }
    if (data?.personal_finance?.cost?.amount_others > 0) {
      setActiveStateFive(true);
    }
  }, [data]);

  const methods = useForm<IFormInput>({
    defaultValues: useMemo(() => {
      return data?.personal_finance?.cost;
    }, [data]),
  });
  const { control, reset, handleSubmit } = methods;

  useEffect(() => {
    reset(data?.personal_finance?.cost);
  }, [queryInfo.isSuccess]);

  const { enqueueSnackbar } = useSnackbar();

  const mutate = useUpdateProject({
    onSuccess: () => {
      // eslint-disable-next-line react/prop-types
      queryInfo.refetch();
      setIndexAccordion(indexAccordion + 1);
      return index === indexMax - 1 ? navigate(to + search) : null;
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const btnLabel = index === indexMax - 1 ? "Valider et passer à l'étape suivante" : 'Enregistrer';

  const onSubmit = (form: any) => {
    const formValid: any = {
      data: {
        customers: [{
          customerId: data?._id,
          cost: {
            amount_rent: Number(form?.amount_rent),
            amount_pension: Number(form?.amount_pension),
            amount_others: Number(form?.amount_others),
            housing_loan: form?.housing_loan?.map((e: any) => ({
              monthly_amount: Number(e.monthly_amount),
              loan_amount: Number(e.loan_amount),
              remaining_loan: Number(e.remaining_loan),
              end_date: e.end_date,
            })),
            consumer_loan: form?.consumer_loan?.map((e: any) => ({
              monthly_amount: Number(e.monthly_amount),
              loan_amount: Number(e.loan_amount),
              remaining_loan: Number(e.remaining_loan),
              end_date: e.end_date,
            })),
          },
        }],
      },
      projectId: queryInfo?.data?.getProjectById?._id,
    };
    mutate({ ...formValid });
    // @ts-ignore
    analytics.track('User Clicked On Call To Action', {
      object: 'User',
      action: 'Clicked On Call To Action',
      click_url: to,
      click_text: btnLabel,
      location: 'form-update-cost',
      category: 'submit',
    });
  };

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        id="cost"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          sx={{ paddingX: { xs: 0, md: 7 } }}
        >
          <Box marginBottom={3}>
            <SectionTitle
              text={`Quelles sont vos charges mensuelles sur ${lastYear} ?`}
              subText="⚠ Si vous investissez avec votre
                conjoint(e), vous pouvez mutualiser vos charges à cette étape et ne pas les détailler dans la
                partie de votre conjoint(e)."
            />
            <Box display="flex" alignItems="center" justifyContent="center" flexWrap="wrap">
              <StateCase
                state={activeStateOne}
                setState={setActiveStateOne}
                renderIcon={() => <HouseIcon />}
                xsSize={120}
                mdSize={150}
                title="Loyer"
              />
              <StateCase
                state={activeStateTwo}
                setState={setActiveStateTwo}
                xsSize={120}
                mdSize={150}
                renderIcon={() => <EuroIcon />}
                title="Prêt immobilier"
              />
              <StateCase
                state={activeStateThree}
                setState={setActiveStateThree}
                xsSize={120}
                mdSize={150}
                renderIcon={() => <ShoppingBasketIcon />}
                title="Prêt à la consommation"
              />
              <StateCase
                state={activeStateFour}
                setState={setActiveStateFour}
                xsSize={120}
                mdSize={150}
                renderIcon={() => <SavingsIcon />}
                title="Pension"
              />
              <StateCase
                state={activeStateFive}
                setState={setActiveStateFive}
                xsSize={120}
                mdSize={150}
                renderIcon={() => <AlignVerticalBottomIcon />}
                title="Autres"
              />
            </Box>
          </Box>
          <Box display={activeStateTwo ? 'inherit' : 'none'}>
            <FieldsArrayHousingLoan />
          </Box>
          <Box display={activeStateThree ? 'inherit' : 'none'}>
            <FieldsArrayConsumerLoan />
          </Box>
          <Box display={activeStateOne ? 'inherit' : 'none'}>
            <SectionTitle text="Loyer" />
            <GridContainerFormInput>
              <Grid item xs={1}>
                <CurrencyTextFieldCustom
                  control={control}
                  label="Votre ou vos loyers mensuels"
                  name="amount_rent"
                />
              </Grid>
            </GridContainerFormInput>
          </Box>
          <Box display={activeStateFour ? 'inherit' : 'none'}>
            <SectionTitle text="Pension" />
            <GridContainerFormInput>
              <Grid item xs={1}>
                <CurrencyTextFieldCustom
                  control={control}
                  label="Votre ou vos pensions mensuelles"
                  name="amount_pension"
                />
              </Grid>
            </GridContainerFormInput>
          </Box>
          <Box display={activeStateFive ? 'inherit' : 'none'}>
            <SectionTitle text="Autres" />
            <GridContainerFormInput>
              <Grid item xs={1}>
                <CurrencyTextFieldCustom
                  control={control}
                  label="Autres charges mensuelles"
                  name="amount_others"
                />
              </Grid>
            </GridContainerFormInput>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            sx={{ justifyContent: { xs: 'center', md: 'flex-end' } }}
          >
            <SubmitButtonForm index={index} indexMax={indexMax} label={btnLabel} />
          </Box>
        </Box>
      </Box>
    </FormProvider>
  );
}

export {
  CostContent,
};
