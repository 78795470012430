import React, { useEffect, useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
import { GridContainerFormInput, SectionTitle, SubmitButtonForm } from '../containerform';
import { CurrencyTextFieldCustom } from '../../../common/inputs/currencytextfield';
import { useUpdateProject } from '../../../hooks/mutation/update-project-mutation.hook';

interface IFormInput {
  amount_available: string,
  amount_invested: string,
}

function SavingContent({
  data, index, queryInfo, indexMax, to, indexAccordion, setIndexAccordion,
}: any) {
  const {
    control, reset, handleSubmit,
  } = useForm<IFormInput>({
    defaultValues: useMemo(() => {
      return data?.personal_finance?.savings;
    }, [data]),
  });
  const navigate = useNavigate();
  const { search } = useLocation();

  useEffect(() => {
    reset(data);
  }, [queryInfo.isSuccess]);

  const { enqueueSnackbar } = useSnackbar();

  const mutate = useUpdateProject({
    onSuccess: () => {
      // eslint-disable-next-line react/prop-types
      queryInfo.refetch();
      setIndexAccordion(indexAccordion + 1);
      return index === indexMax - 1 ? navigate(to + search) : null;
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const btnLabel = index === indexMax - 1 ? "Valider et passer à l'étape suivante" : 'Enregistrer';

  const onSubmit = (form: any) => {
    const formValid: any = {
      data: {
        customers: [{
          customerId: data?._id,
          savings: {
            amount_available: Number(form?.amount_available),
            amount_invested: Number(form?.amount_invested),
          },
        }],
      },
      projectId: queryInfo?.data?.getProjectById?._id,
    };
    mutate({ ...formValid });
    // @ts-ignore
    analytics.track('User Clicked On Call To Action', {
      object: 'User',
      action: 'Clicked On Call To Action',
      click_url: to,
      click_text: btnLabel,
      location: 'form-update-saving',
      category: 'submit',
    });
  };

  return (
    <Box
      component="form"
      id="saving"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box
        sx={{ paddingX: { xs: 0, md: 7 } }}
      >
        <SectionTitle
          text="Votre épargne"
          subText="⚠ Si vous investissez avec votre
                conjoint(e), vous pouvez mutualiser votre épargne à cette étape et ne pas la détailler dans
                la partie de votre conjoint(e)."
        />
        <GridContainerFormInput>
          <Grid item xs={1}>
            <CurrencyTextFieldCustom
              control={control}
              label="Épargne disponible"
              name="amount_available"
            />
          </Grid>
          <Grid item xs={1}>
            <CurrencyTextFieldCustom
              control={control}
              label="Épargne placée"
              name="amount_invested"
            />
          </Grid>
        </GridContainerFormInput>
        <Box
          display="flex"
          alignItems="center"
          sx={{
            justifyContent: {
              xs: 'center',
              md: 'flex-end',
            },
          }}
        >
          <SubmitButtonForm index={index} indexMax={indexMax} label={btnLabel} />
        </Box>
      </Box>
    </Box>
  );
}

export {
  SavingContent,
};
