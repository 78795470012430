import React, { useEffect, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Header } from './components/header/header';
import { MaxWidthContainer } from '../../utils/components/max-width-container';
import { useFindUserLoggedIn } from '../../hooks/query/find-user-logged-in-query.hook';
import { Footer } from './components/footer/footer';
import userIcon from '../../assets/common/info.svg';
import projectIcon from '../../assets/common/project.svg';
import docIcon from '../../assets/common/documents.svg';
import questionIcon from '../../assets/common/interrogation.svg';
import referralIcon from '../../assets/common/referral.svg';

function Layout({ disabled, children, disabledNav }: any) {
  const [openMenu, setOpenMenu] = useState(false);
  const [openMenuMobile, setOpenMenuMobile] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryUser = useFindUserLoggedIn();
  const { logout } = useAuth0<{ sub: any; name: string }>();

  const subNavForm = [{
    label: 'Informations personnelles',
    url: '/form/personal-information',
  }, {
    label: 'Situation professionnelle',
    url: '/form/professional-situation',
  }, {
    label: 'Contact',
    url: '/form/contact',
  }, {
    label: 'Revenus professionnels',
    url: '/form/professional-income',
  }, {
    label: 'Revenus additionnels',
    url: '/form/other-income',
  }, {
    label: 'Epargne',
    url: '/form/saving',
  }, {
    label: 'Charges',
    url: '/form/cost',
  }, {
    label: 'Impôts',
    url: '/form/taxes',
  }];

  const menu = [{
    value: 'Mes informations',
    url: 'form/personal-information',
    page: 'form',
    icon: userIcon,
    variant: false,
  }, {
    value: 'Mon projet',
    url: 'project',
    page: 'project',
    icon: projectIcon,
    variant: false,
  }, {
    value: 'Mes documents',
    url: 'documents',
    page: 'documents',
    icon: docIcon,
    variant: false,
  }, {
    value: 'Une question ?',
    url: 'question',
    page: location.pathname === '/contact-us' ? 'contact-us' : 'question',
    icon: questionIcon,
    variant: false,
  }, {
    value: 'Parrainage',
    url: 'referral',
    page: 'referral',
    icon: referralIcon,
    variant: true,
  }];

  const [value, setValue] = useState(subNavForm?.findIndex((f) => f?.url === location?.pathname));
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue(subNavForm?.findIndex((f) => f?.url === location?.pathname));
  }, [location, subNavForm]);

  return (
    <Box
      minHeight={{ xs: '100dvh', md: '100vh' }}
      width="100%"
      height="100%"
      position="relative"
    >
      <Header
        disabled={disabled}
        bottomShadow={disabledNav}
        openMenu={openMenu}
        setOpenMenu={setOpenMenu}
        openMenuMobile={openMenuMobile}
        setOpenMenuMobile={setOpenMenuMobile}
        logout={logout}
        menu={menu}
        queryUser={queryUser}
      />
      {!disabledNav && (
        <>
          <Box
            width="100%"
            display={{ xs: openMenuMobile ? 'none' : 'flex', md: 'flex' }}
            justifyContent="center"
            alignItems="flex-start"
            zIndex={200}
            sx={{
              position: 'fixed',
              top: '0',
              bgcolor: 'text.secondary',
            }}
          >
            <MaxWidthContainer>
              <Box
                marginTop="70px"
                maxWidth={{ xs: 'inherit', md: '100%' }}
                width="100%"
                display="flex"
                justifyContent="center"
              >
                <Tabs
                  value={value >= 0 ? value : 0}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  sx={{
                    '.MuiTab-root.Mui-selected': {
                      color: (theme) => theme.palette.text.primary,
                    },
                  }}
                >
                  {subNavForm?.map((e) => (
                    <Tab
                      key={e?.label}
                      sx={{
                        color: (theme) => theme.palette.text.primary,
                        textTransform: 'none',
                        fontWeight: 400,
                      }}
                      onClick={() => {
                        navigate(e?.url + search);
                        // @ts-ignore
                        analytics.track('User Clicked On Call To Action', {
                          object: 'User',
                          action: 'Clicked On Call To Action',
                          click_url: e?.url,
                          click_text: e?.label,
                          location: 'project-menu-navigation',
                          category: 'link',
                        });
                      }}
                      label={e?.label}
                    />
                  ))}
                </Tabs>
              </Box>
            </MaxWidthContainer>
          </Box>
        </>
      )}
      <Box
        width="100%"
        display={{ md: 'flex', xs: openMenuMobile ? 'none' : 'flex' }}
        alignItems="flex-start"
        justifyContent="center"
        marginTop={disabledNav ? 0 : '130px'}
        minHeight="100vh"
      >
        {children}
      </Box>
      <Footer
        menu={menu}
        disabled={disabled}
        logout={logout}
        queryUser={queryUser}
      />
    </Box>
  );
}

export {
  Layout,
};
