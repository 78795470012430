import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { ProfessionalSituationContent } from '../components/professional-situation/professionalsituationcontent';

function ProfessionalSituation({
  queryInfo,
} : any) {
  const {
    // @ts-ignore
    item, index, indexAccordion, setIndexAccordion, indexMax,
  } = useOutletContext();
  return (
    <ProfessionalSituationContent
      key={item?._id}
      data={item}
      index={index}
      queryInfo={queryInfo}
      indexMax={indexMax}
      to="/form/contact"
      indexAccordion={indexAccordion}
      setIndexAccordion={setIndexAccordion}
    />
  );
}

export {
  ProfessionalSituation,
};
