import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { AllowedOffer } from '../../../api';
import projectWilliam from '../../../assets/projects/project-william.jpeg';
import projectKarl from '../../../assets/projects/project-karl.jpeg';

function OffersContainer({ offers }: any) {
  switch (offers) {
    case AllowedOffer.INELIGIBLE: {
      return <OfferIneligible data={ineligible} />;
    }
    case AllowedOffer.ETINCELLE: {
      return <Offer data={etincelle} />;
    }
    case AllowedOffer.ENVOL: {
      return <Offer data={envol} />;
    }
    case AllowedOffer.ETOILE: {
      return <Offer data={etoile} />;
    }
    default: {
      return (
        <Box />
      );
    }
  }
}

function Offer({ data }: any) {
  return (
    <Box>
      {data?.textHero?.map((e: any) => (
        <Typography
          key={e}
          letterSpacing={{ xs: 'inherit', sm: '0.1rem' }}
          fontWeight={500}
          lineHeight={1.8}
          marginBottom={2}
          textAlign="justify"
        >
          {e}
        </Typography>
      ))}
      <Box
        width="100%"
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        marginTop={{ xs: 5, sm: 10 }}
        height={{ xs: 'auto', md: '280px' }}
      >
        <Box
          width={{ xs: '100%', sm: '50%' }}
          marginBottom={{ xs: 3, md: 'inherit' }}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Typography
            marginBottom={{ xs: 3, md: 'inherit' }}
            textTransform="uppercase"
            fontWeight="bold"
            variant="h4"
            letterSpacing="0.1rem"
          >
            {data.title}
          </Typography>
          <Box width={{ xs: '100%', sm: '60%' }}>
            {data?.list.map((e: any) => (
              <Box display="flex" alignItems="center" key={e?.text} marginBottom={2}>
                <Typography marginRight={1} fontWeight={500}>{e.icon}</Typography>
                <Typography fontWeight={500}>{e?.text}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          width={{ xs: '100%', sm: '50%' }}
          display="flex"
          height="100%"
        >
          <Box
            component="img"
            borderRadius="10px"
            width="100%"
            height="100%"
            sx={{
              objectFit: 'cover',
            }}
            src={data?.img}
          />
        </Box>
      </Box>
      <Box width="100%" display="flex" alignItems="center" justifyContent="center" marginTop={{ xs: 5, sm: 8 }}>
        <Button
          component="a"
          href="https://lessecretsdelimmo.fr/Realisation/"
          variant="contained"
          target="_blank"
          color="secondary"
          sx={{
            paddingY: 1.5,
            paddingX: 3,
          }}
          endIcon={<TrendingFlatIcon fontSize="large" />}
        >
          <Typography
            fontWeight={700}
            letterSpacing={{ xs: 'inherit', sm: '0.1rem' }}
            variant="body2"
          >
            Découvrez nos réalisations
          </Typography>
        </Button>
      </Box>
    </Box>
  );
}

function OfferIneligible({ data }: any) {
  return (
    <Box>
      {data?.textHero?.map((e: any) => (
        <Typography
          key={e}
          letterSpacing={{ xs: 'inherit', sm: '0.1rem' }}
          fontWeight={500}
          lineHeight={1.8}
          marginBottom={2}
          textAlign="justify"
        >
          {e}
        </Typography>
      ))}
      <Box width="100%" display="flex" alignItems="center" justifyContent="center" marginTop={{ xs: 5, sm: 8 }}>
        <Button
          component="a"
          href="https://lessecretsdelimmo.fr/Realisation/"
          variant="contained"
          target="_blank"
          color="secondary"
          sx={{
            paddingY: 1.5,
            paddingX: 3,
          }}
          endIcon={<TrendingFlatIcon fontSize="large" />}
        >
          <Typography
            fontWeight={700}
            letterSpacing={{ xs: 'inherit', sm: '0.1rem' }}
            variant="body2"
          >
            Découvrez nos réalisations
          </Typography>
        </Button>
      </Box>
    </Box>
  );
}

const etincelle = {
  textHero: [
    'Votre budget vous permet d‘investir dans un appartement en Ile-de-France et de bénéficier de notre offre Etincelle clé en main.',
    'Nos équipes dénichent le meilleur bien, nos architectes l\'optimisent et notre entreprise de BTP se charge des travaux.',
    'Tout comme le projet Karl, profitez d‘un bien rentable et à fort potentiel de revente.',
  ],
  title: 'Projet Karl',
  img: projectKarl,
  list: [{
    icon: '🏛',
    text: 'Rénovation et mise en location d’un bien off-market',
  }, {
    icon: '📍',
    text: 'Champigny-sur-marne',
  }, {
    icon: '💎',
    text: 'Apport de : 50 000 €',
  }, {
    icon: '💰',
    text: 'Budget total : 397 000 €',
  }, {
    icon: '📈️',
    text: 'Rentabilité nette : 5,7%',
  }],
};

const envol = {
  textHero: [
    'Votre budget vous permet d‘investir dans une maison ou un immeuble ancien en Ile-de-France et de bénéficier de notre offre Envol clé en main.',
    'Nos équipes dénichent le meilleur bien, nos architectes l\'optimisent et notre entreprise de BTP se charge des travaux.',
    'Tout comme le projet William, profitez d‘un bien rentable et à fort potentiel de revente.',
  ],
  title: 'Projet William',
  img: projectWilliam,
  list: [{
    icon: '🏛',
    text: 'Rénovation et mise en location d’un bien off-market',
  }, {
    icon: '📍',
    text: 'Situé à Noisy le sec',
  }, {
    icon: '💎',
    text: 'Apport de : 175 000 €',
  }, {
    icon: '💰',
    text: 'Budget total : 870 000 €',
  }, {
    icon: '📈️',
    text: 'Rentabilité nette : 6.5%',
  }],
};

const etoile = {
  textHero: [
    'Votre budget vous permet d‘investir dans un immeuble ancien en Ile-de-France et de bénéficier de notre offre Etoile clé en main.',
    'Nos équipes dénichent le meilleur bien, nos architectes l\'optimisent et notre entreprise de BTP se charge des travaux.',
    'Tout comme le projet William, profitez d‘un bien rentable et à fort potentiel de revente.',
  ],
  title: 'Projet William',
  img: projectWilliam,
  list: [{
    icon: '🏛',
    text: 'Rénovation et mise en location d’un bien off-market',
  }, {
    icon: '📍',
    text: 'Situé à Noisy le sec',
  }, {
    icon: '💎',
    text: 'Apport de : 175 000 €',
  }, {
    icon: '💰',
    text: 'Budget total : 870 000 €',
  }, {
    icon: '📈️',
    text: 'Rentabilité nette : 6.5%',
  }],
};

const ineligible = {
  textHero: [
    'Vous n‘êtes actuellement pas éligible à l‘une de nos offres d‘investissement immobilier.',
    'Pas de panique, il se peut que nous n‘ayons pas assez d‘informations sur votre situation professionnelle pour évaluer correctement votre capacité d’emprunt. Vous pouvez à tout moment programmer un rendez-vous téléphonique avec l‘un de nos conseillers depuis votre espace client.',
    'En attendant, découvrez nos réalisations clé en main en Ile-de-France sur notre site.\n',
  ],
};

export {
  OffersContainer,
};
