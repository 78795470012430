import React from 'react';
import { useSnackbar } from 'notistack';
import { useOutletContext } from 'react-router-dom';
import { ContactContent } from '../components/contact/contactcontent';
import { useFindAllBusinessProviders } from '../../hooks/query/find-all-business-providers-query.hook';
import { useUpdateProject } from '../../hooks/mutation/update-project-mutation.hook';

function Contact({ queryInfo }: any) {
  const queryInfoAllBusinessProviders = useFindAllBusinessProviders();
  const { enqueueSnackbar } = useSnackbar();
  const {
    // @ts-ignore
    item, index, indexAccordion, setIndexAccordion, indexMax,
  } = useOutletContext();

  const businessProvidersOptions = queryInfoAllBusinessProviders?.data?.findAllBusinessProviders?.map((e) => ({
    value: e?.name.replaceAll(' ', '_').toUpperCase(),
    label: e?.name,
  }));

  const mutate = useUpdateProject({
    onSuccess: () => {
      queryInfo.refetch();
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  return (
    <ContactContent
      key={item?._id}
      index={index}
      data={item}
      queryInfo={queryInfo}
      indexMax={indexMax}
      to="/form/professional-income"
      indexAccordion={indexAccordion}
      setIndexAccordion={setIndexAccordion}
      queryInfoAllBusinessProviders={queryInfoAllBusinessProviders}
      businessProvidersOptions={businessProvidersOptions}
      mutateProject={mutate}
    />
  );
}

export {
  Contact,
};
