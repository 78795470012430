import {
  Box, Button, Chip, Typography,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useFindUserLoggedIn } from '../../hooks/query/find-user-logged-in-query.hook';
import { scheduleText } from '../schedule.text';
import { CalendlyContainer } from '../components/calendly-container';
import { MessageContainer } from '../../common/components/message-container';
import { CalendlyTaken } from '../components/calendly-taken';
import { LayoutPage } from '../../common/components/layout-page';

function Schedule() {
  const queryInfo = useFindUserLoggedIn();
  const isInvestor = queryInfo?.data?.findUserLoggedIn?.typeformInfo?.investor?.toLowerCase() === 'oui';
  const navigate = useNavigate();
  const firstEventTaken = queryInfo?.data?.findUserLoggedIn?.first_event_taken;

  return (
    <LayoutPage mobileFooter character>
      <>
        {!firstEventTaken
          && (
            <MessageContainer>
              <Typography letterSpacing={{ sx: 'inherit', md: '0.1rem' }} fontWeight={500} lineHeight="22px">
                {scheduleText.message('15')}
              </Typography>
            </MessageContainer>
          )}
        {firstEventTaken ? (
          <CalendlyTaken queryInfo={queryInfo} />
        ) : (
          <MessageContainer>
            <CalendlyContainer
              queryInfo={queryInfo}
            />
          </MessageContainer>
        )}
        {!firstEventTaken
          && scheduleText.messageList.map((e) => (
            <MessageContainer key={e}>
              <Typography
                fontWeight="700"
                letterSpacing={{ sx: 'inherit', md: '0.1rem' }}
                variant="body2"
              >
                {e}
              </Typography>
            </MessageContainer>
          ))}
        {firstEventTaken
          && (
            <MessageContainer>
              <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems="center">
                <Chip label="Optionnel" variant="outlined" size="small" />
                <Button
                  sx={{
                    textTransform: 'none',
                    marginLeft: 1,
                    letterSpacing: '0.1rem',
                    fontWeight: 500,
                    lineHeight: '22px',
                    color: 'text.primary',
                    borderRadius: '100px',
                  }}
                  size="large"
                  endIcon={<ArrowForwardIosIcon />}
                  onClick={async () => {
                    await Promise.all([
                      navigate('/form/create-project'),
                      // @ts-ignore
                      analytics.track('User Clicked On Call To Action', {
                        object: 'User',
                        action: 'Clicked On Call To Action',
                        click_url: '/form/create-project',
                        click_text: scheduleText.scheduledLink,
                        location: 'schedule',
                        category: 'button',
                      }),
                    ]);
                  }}
                >
                  {scheduleText.scheduledLink}
                </Button>
              </Box>
            </MessageContainer>
          )}
      </>
      {(window.location.origin === 'http://localhost:3000' || window.location.origin === 'https://dev.client.lsi.jqtech.fr')
      && !firstEventTaken
        ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            paddingTop={3}
            paddingBottom={5}
          >
            <Button
              sx={{
                textTransform: 'none',
                marginLeft: 1,
                letterSpacing: '0.1rem',
                fontWeight: 500,
                lineHeight: '22px',
                color: 'text.primary',
                borderRadius: '100px',
              }}
              endIcon={<ArrowForwardIosIcon />}
              onClick={() => {
                navigate('/form/create-project');
              }}
            >
              {scheduleText?.buttonSkipDev}
            </Button>
          </Box>
        ) : <Box paddingBottom={5} />}
    </LayoutPage>
  );
}

export {
  Schedule,
};
