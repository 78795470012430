import {
  Box, Grid, styled, Typography,
} from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { MuiTelInput } from 'mui-tel-input';

function MuiPhoneCustom({
  setCountry, control, name, label = ' ', rules = null, size = 'medium', variant = 'standard', placeholder,
}: any) {
  // eslint-disable-next-line consistent-return
  function checkValue(value: any) {
    if (value[1] === '0') {
      return '33';
    }
    return value;
  }

  return (
    <Grid item height={80}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState }) => (
          <Box>
            <CssTextField
              {...field}
              value={field.value ? checkValue(field.value) : null}
              onChange={(e: any, country) => {
                field.onChange(e);
                setCountry(country);
              }}
              placeholder={placeholder}
              fullWidth
              color="secondary"
              label={label}
              variant={variant}
              size={size}
            />
            {fieldState?.error?.message
            && <Typography variant="caption" fontWeight="bold" color="error">{fieldState.error.message}</Typography>}
          </Box>
        )}
      />
    </Grid>
  );
}

const CssTextField = styled(MuiTelInput)(({ theme }) => ({
  label: {
    fontWeight: '400',
    color: theme.palette.text.primary,
  },
}));

export {
  MuiPhoneCustom,
};
