import React from 'react';
import { Box, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import building from '../../assets/questions/building.svg';
import phone from '../../assets/questions/phone.svg';
import { MaxWidthContainer } from '../../utils/components/max-width-container';

function Question() {
  return (
    <MaxWidthContainer>
      <Box
        width={{ xs: '95%', sm: '80%' }}
        height="100vh"
        flexDirection="column"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Case
          icon={phone}
          link="/contact-us"
          title="Nous contacter"
          text="Vous avez une question ou souhaitez davantage de précisions sur un sujet"
        />
        <Case
          icon={building}
          link="/form/create-project"
          title="Investir dans un nouveau projet"
          text="Satisfait ? Nous serions ravis de vous accompagner pour un nouveau projet"
        />
      </Box>
    </MaxWidthContainer>
  );
}

function Case({
  icon, title, text, link,
}: any) {
  const navigate = useNavigate();
  const { search } = useLocation();
  return (
    <Box
      onClick={() => {
        navigate(`${link}${search}`);
        // @ts-ignore
        analytics.track('User Clicked On Call To Action', {
          object: 'User',
          action: 'Clicked On Call To Action',
          click_url: '/contact-us',
          click_text: 'Nous contacter',
          location: 'question',
          category: 'button',
        });
      }}
      width={{ xs: '95%', sm: '80%' }}
      marginY={2}
      height={{ xs: 280, sm: 200 }}
      display="flex"
      bgcolor="primary.light"
      alignItems="center"
      justifyContent="space-around"
      flexDirection={{ xs: 'column', sm: 'row' }}
      sx={{
        cursor: 'pointer',
        transition: 'transform 0.3s',
        borderRadius: '0 10px 10px 10px',
      }}
    >
      <Box width={{ xs: '90%', sm: '20%' }} display="flex" alignItems="center" justifyContent="center">
        <Box
          component="img"
          src={icon}
          width={{ xs: '60px', sm: '80px' }}
          sx={{
            objectFit: 'cover',
          }}
        />
      </Box>
      <Box width={{ xs: '90%', sm: '40%' }}>
        <Typography marginBottom={{ xs: 3, sm: 0 }} textAlign={{ xs: 'center', sm: 'left' }} fontWeight="600" variant="h5">{title}</Typography>
        <Typography fontWeight="400" variant="body1" textAlign={{ xs: 'center', sm: 'left' }}>{text}</Typography>
      </Box>
      <Box width="20%" display={{ xs: 'none', sm: 'flex' }} alignItems="center" justifyContent="flex-end" paddingRight={5}>
        <ArrowForwardIosIcon color="secondary" fontSize="small" />
      </Box>
    </Box>
  );
}

export {
  Question,
};
