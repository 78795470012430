import React, { useState } from 'react';
import {
  Box, Button, Menu, MenuItem, Typography, IconButton, Snackbar, Alert, Tooltip, Grid,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ClearIcon from '@mui/icons-material/Clear';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import { LoadingButton } from '@mui/lab';
import document_icon_id from '../../assets/documents/document-icon-id.svg';
import document_union_id from '../../assets/documents/document-icon-union.svg';
import document_other_id from '../../assets/documents/document-icon-other.svg';
import document_other_contract from '../../assets/documents/document-other-contract.svg';

import {
  AllowedDocumentLabel,
} from '../../api';
import { fileLabelOptions, fileLabelOptionsInput } from '../../data/data';
import { useGetAccessToken } from '../../hooks/auth/get-access-token.hook';
import { useGetProjectById } from '../../hooks/query/get-project-by-id-query.hook';
import { useDeleteDocumentsFromProject } from '../../hooks/mutation/delete-documents-from-project-mutation.hook';
import { MaxWidthContainer } from '../../utils/components/max-width-container';

interface IFormInput {
  file: string
}

function Documents() {
  const [searchParams] = useSearchParams();
  const [uploadMessage, setUploadMessage] = useState({ text: '', type: '' });
  const [openSnack, setOpenSnack] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [documentType, setDocumentType] = useState('');
  const projectId = searchParams.get('projectId');
  const { accessToken } = useGetAccessToken();
  const queryInfo = useGetProjectById(searchParams.get('projectId'));
  const [loading, setLoading] = useState(false);

  const open = Boolean(anchorEl);
  const {
    control, handleSubmit, watch, setValue,
  } = useForm<IFormInput>({});

  const onSubmit = (form: any): any => {
    setLoading(true);
    const formData = new FormData();
    for (const file of form.file) {
      formData.append('files', file);
    }
    formData.append('projectId', projectId ?? '');
    formData.append('label', documentType);

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${accessToken}`);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formData,
    };

    fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/api/project/upload`, requestOptions).then((response) => response.json())
      .then((result) => {
        if (result.success) {
          queryInfo.refetch();
          setUploadMessage({ text: 'Votre ou vos document(s) ont été importé avec succès !', type: 'success' });
          setOpenSnack(true);
          setValue('file', '');
          setLoading(false);
        }
      }).catch((err) => {
        setOpenSnack(true);
        setUploadMessage({ text: `Une erreur est survenue : ${err.message}`, type: 'error' });
        setLoading(false);
      });
  };

  const handleCloseSnack = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
    setUploadMessage({ text: '', type: '' });
  };

  const mutate = useDeleteDocumentsFromProject({
    onSuccess: () => {
      queryInfo.refetch();
      setOpenSnack(true);
      setValue('file', '');
    },
    onError: (err: any) => {
      setOpenSnack(true);
      setUploadMessage({ text: `Une erreur est survenue : ${err.message}`, type: 'error' });
    },
  });

  const handleClick = (event: { currentTarget: React.SetStateAction<null> }) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const chooseIcon = (type: any) => {
    switch (type) {
      case AllowedDocumentLabel.ID_DOC:
        return document_icon_id;
      case AllowedDocumentLabel.WEDDING_CONTRAT_DOC:
        return document_union_id;
      case AllowedDocumentLabel.OTHER_DOC:
        return document_other_id;
      case AllowedDocumentLabel.CONTRAT:
        return document_other_contract;
      default:
        return document_other_id;
    }
  };

  const checkFileName = (files: any) => {
    if (files) {
      let filesName = '';
      for (const file of files) {
        // eslint-disable-next-line no-multi-assign
        filesName = filesName += `${file?.name}\n`;
      }
      return filesName;
    }
    return 'Le fichier';
  };

  return (
    <MaxWidthContainer>
      <Snackbar open={openSnack} onClose={handleCloseSnack} autoHideDuration={5000}>
        <Alert
          severity={uploadMessage?.type === 'success' ? 'success' : 'error'}
          onClose={handleCloseSnack}
          sx={{ width: '100%' }}
        >
          {uploadMessage?.text}
        </Alert>
      </Snackbar>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        paddingTop={{ xs: 15, md: 16 }}
        width={{ xs: '95%', md: '80%' }}
      >
        <Box display="flex" justifyContent="space-between" flexDirection={{ xs: 'column', sm: 'row' }}>
          <Box display="flex" flexDirection="column" paddingLeft={{ xs: 0, sm: 2 }}>
            <Box
              display="flex"
              justifyContent={{ xs: 'center', sm: 'flex-start' }}
              marginBottom={{ xs: 2, sm: 0 }}
            >
              <Typography variant="h5" fontWeight="700" textTransform="uppercase">
                Mon espace documents
              </Typography>
            </Box>
            <Typography
              variant="h6"
              textAlign={{ xs: 'center', sm: 'left' }}
              fontWeight="400"
              color="text.primary"
            >
              Consultez ou importez vos documents
            </Typography>
          </Box>
          <Box marginTop={{ xs: 2, sm: 0 }} paddingRight={{ xs: 0, sm: 2 }} width={{ xs: '100%', sm: 300 }}>
            <Button
              sx={{
                marginRight: 3,
                fontWeight: 'bold',
                letterSpacing: { xs: 'inherit', sm: '0.1rem' },
              }}
              fullWidth
              color="secondary"
              component="span"
              variant="contained"
              onClick={(e: any) => handleClick(e)}
              endIcon={<KeyboardArrowDownIcon />}
            >
              Importer vos documents
            </Button>
            <Box
              component="form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItem disabled>Sélectionner le type de document à importer</MenuItem>
                {fileLabelOptionsInput?.map((e, i) => (
                  <label key={e?.value + i.toString()}>
                    <MenuItem>
                      {e.label}
                    </MenuItem>
                    <Controller
                      name="file"
                      control={control}
                      render={({ field }) => (
                        <Box
                          component="input"
                          sx={{ display: 'none' }}
                          multiple
                          id="contained-button-file"
                          // eslint-disable-next-line no-shadow
                          onChange={(elem) => {
                            // @ts-ignore
                            const file = elem.target.files ?? null;
                            if (!file) return;
                            field.onChange(file);
                            setDocumentType(e.value);
                            setAnchorEl(null);
                          }}
                          type="file"
                        />
                      )}
                    />
                  </label>
                ))}
              </Menu>
              {watch('file')
                && (
                  <Box display="flex" flexDirection="column">
                    <Box
                      bgcolor="text.secondary"
                      marginTop={1}
                      padding={1}
                      display="flex"
                      borderRadius="5px"
                    >
                      <Typography
                        variant="caption"
                        sx={{
                          width: '95%',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                      >
                        {
                          checkFileName(watch('file'))
                        }
                      </Typography>
                      <Box
                        width="5%"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => setValue('file', '')}
                      >
                        <ClearIcon fontSize="small" color="error" />
                      </Box>
                    </Box>
                    <LoadingButton
                      fullWidth
                      sx={{
                        marginRight: 3, marginTop: 1, textTransform: 'none', fontWeight: 'bold',
                      }}
                      color="primary"
                      variant="outlined"
                      type="submit"
                      loading={loading}
                    >
                      Télécharger
                    </LoadingButton>
                  </Box>
                )}
            </Box>
          </Box>
        </Box>
        <Box width="100%" display="flex" justifyContent="center">
          <Grid container columns={{ xs: 2, md: 5 }} spacing={2} width="100%" marginTop={5} marginBottom={20}>
            {queryInfo?.data?.getProjectById?.documents?.map((row: any, i: any) => (
              <Grid key={row?._id} item xs={1} display="flex" alignItems="center" flexDirection="column">
                <Box
                  width={50}
                  margin={2}
                  height={50}
                  marginBottom={1}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box
                    component="img"
                    src={chooseIcon(row?.type)}
                    width="100%"
                    sx={{ objectFit: 'contain', display: 'block' }}
                  />
                </Box>
                <Box display="flex">
                  <Typography
                    marginY={1}
                    variant="body2"
                    fontWeight="bold"
                  >
                    {fileLabelOptions?.find((f) => f?.value === row?.type)?.label}
                  </Typography>
                  {row?.type !== AllowedDocumentLabel.CONTRAT
                    && (
                      <IconButton
                        onClick={() => {
                          setUploadMessage({
                            text: `Le document ${row?.path.split('-').map((e: any) => e).slice(1).join('-')} a bien été supprimé !`,
                            type: 'success',
                          });
                          mutate({ documentId: row?._id });
                        }}
                        color="error"
                        size="small"
                      >
                        <DeleteIcon
                          fontSize="small"
                        />
                      </IconButton>
                    )}
                </Box>
                <Tooltip title={row?.path.split('-').map((e: any) => e).slice(1).join('-')}>
                  <Box
                    component="a"
                    target="_blank"
                    href={row?.url}
                    rel="noreferrer"
                    style={{ textDecoration: 'none' }}
                  >
                    <Typography
                      width="130px"
                      textAlign="center"
                      variant="body2"
                      color="primary"
                      sx={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        textDecoration: 'underline',
                        overflow: 'hidden',
                      }}
                    >
                      {row?.path.split('-').map((e: any) => e).slice(1).join('-')}
                    </Typography>
                  </Box>
                </Tooltip>
                <Typography variant="body2">{format(new Date(row?.createdAt), 'dd/MM/yyyy')}</Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </MaxWidthContainer>
  );
}

export {
  Documents,
};
