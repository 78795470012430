import React, { useEffect, useMemo, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import HouseIcon from '@mui/icons-material/House';
import EuroIcon from '@mui/icons-material/Euro';
import AlignVerticalBottomIcon from '@mui/icons-material/AlignVerticalBottom';
import { useLocation, useNavigate } from 'react-router-dom';
import { TextFieldCustom } from '../../../common/inputs/textfield';
import { CurrencyTextFieldCustom } from '../../../common/inputs/currencytextfield';
import { StateCase } from '../../../common/inputs/statecase';
import { GridContainerFormInput, SectionTitle, SubmitButtonForm } from '../containerform';
import { FieldArrayOthers } from './fieldarrayothers';
import { useUpdateProject } from '../../../hooks/mutation/update-project-mutation.hook';

interface IFormInput {
  number_real_estate: string
  amount_real_estate: string
  amount_financial_products: string
  others: any
}

function OtherIncomeContent({
  lastYear,
  data,
  index,
  queryInfo,
  indexMax,
  to,
  indexAccordion,
  setIndexAccordion,
}: { lastYear: number, data: any, index: number, queryInfo: any, indexMax: number, to: string, indexAccordion: any, setIndexAccordion: any }) {
  const [activeStateOne, setActiveStateOne]: any = useState(false);
  const [activeStateTwo, setActiveStateTwo]: any = useState(false);
  const [activeStateThree, setActiveStateThree]: any = useState(false);
  const navigate = useNavigate();
  const { search } = useLocation();

  const methods = useForm<IFormInput>({
    defaultValues: useMemo(() => {
      return data?.personal_finance?.income;
    }, [data]),
  });
  const { control, reset, handleSubmit } = methods;

  useEffect(() => {
    if (data?.personal_finance?.income?.number_real_estate > 0 || data?.personal_finance?.income?.amount_real_estate > 0) {
      setActiveStateOne(true);
    }
    if (data?.personal_finance?.income?.amount_financial_products > 0) {
      setActiveStateTwo(true);
    }
    if (data?.personal_finance?.income?.others?.length > 0) {
      setActiveStateThree(true);
    }
  }, [data]);

  useEffect(() => {
    reset(data?.personal_finance?.income);
  }, [queryInfo.isSuccess]);

  const { enqueueSnackbar } = useSnackbar();

  const mutate = useUpdateProject({
    onSuccess: () => {
      queryInfo.refetch();
      setIndexAccordion(indexAccordion + 1);
      return index === indexMax - 1 ? navigate(to + search) : null;
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const btnLabel = index === indexMax - 1 ? "Valider et passer à l'étape suivante" : 'Enregistrer';

  const onSubmit = (form: any) => {
    const formValid: any = {
      data: {
        customers: [{
          customerId: data?._id,
          income: {
            number_real_estate: Number(form?.number_real_estate),
            amount_real_estate: Number(form?.amount_real_estate),
            amount_financial_products: Number(form?.amount_financial_products),
            others: form?.others?.map((e: any) => ({ ...e, amount: Number(e.amount) })),
          },
        }],
      },
      projectId: queryInfo?.data?.getProjectById?._id,
    };
    mutate({ ...formValid });
    // @ts-ignore
    analytics.track('User Clicked On Call To Action', {
      object: 'User',
      action: 'Clicked On Call To Action',
      click_url: to,
      click_text: btnLabel,
      location: 'form-update-other-income',
      category: 'submit',
    });
  };

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        id="other-income"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box paddingX={{ xs: 0, md: 7 }}>
          <Box marginBottom={3}>
            <SectionTitle
              text={`Avez-vous eu des revenus additionnels sur ${lastYear} ?`}
              subText="⚠ Si vous investissez avec votre
                conjoint(e), vous pouvez mutualiser vos revenus additionnels à cette étape et ne pas les détailler dans
                la
                partie de votre conjoint(e)."
            />
            <Box display="flex" alignItems="center" justifyContent="center" flexWrap="wrap">
              <StateCase
                state={activeStateOne}
                setState={setActiveStateOne}
                renderIcon={() => <HouseIcon />}
                title="Revenus Fonciers"
                xsSize={140}
                mdSize={290}
                subtitle="(loyers)"
              />
              <StateCase
                state={activeStateTwo}
                setState={setActiveStateTwo}
                renderIcon={() => <EuroIcon />}
                title="Revenus Financiers"
                xsSize={140}
                mdSize={290}
                subtitle="(actions, dividendes, plus-values, etc.)"
              />
              <StateCase
                state={activeStateThree}
                setState={setActiveStateThree}
                renderIcon={() => <AlignVerticalBottomIcon />}
                title="Autres"
                xsSize={140}
                mdSize={290}
                subtitle="(pensions, allocations, etc.)"
              />
            </Box>
          </Box>
          <Box display={activeStateOne ? 'inherit' : 'none'}>
            <SectionTitle
              text="Revenus fonciers"
              subText={`⚠ Indiquez la somme de vos loyers annuels perçus sur ${lastYear}`}
            />
            <GridContainerFormInput>
              <Grid item xs={1}>
                <TextFieldCustom
                  type="number"
                  control={control}
                  name="number_real_estate"
                  label="Nombre de biens en location"
                />
              </Grid>
              <Grid item xs={1}>
                <CurrencyTextFieldCustom
                  control={control}
                  name="amount_real_estate"
                  label="Revenus locatifs annuels"
                />
              </Grid>
            </GridContainerFormInput>
          </Box>
          <Box display={activeStateTwo ? 'inherit' : 'none'} marginBottom={3}>
            <SectionTitle
              text="Revenus financiers"
              subText={`⚠ Indiquez le montant de vos revenus issus de produits financiers sur ${lastYear}`}
            />
            <GridContainerFormInput>
              <Grid item xs={1}>
                <CurrencyTextFieldCustom
                  control={control}
                  name="amount_financial_products"
                  label="Montant total annuel"
                />
              </Grid>
            </GridContainerFormInput>
            <Typography
              fontStyle="italic"
              fontWeight="bold"
              variant="caption"
            />
          </Box>
          <Box display={activeStateThree ? 'inherit' : 'none'}>
            <FieldArrayOthers lastYear={lastYear} />
          </Box>
          <Box display="flex" alignItems="center" justifyContent={{ xs: 'center', md: 'flex-end' }}>
            <SubmitButtonForm index={index} indexMax={indexMax} label={btnLabel} />
          </Box>
        </Box>
      </Box>
    </FormProvider>
  );
}

export {
  OtherIncomeContent,
};
