import React from 'react';
import { MaxWidthContainer } from '../../utils/components/max-width-container';
import { CreateProjectContent } from '../components/create-project/createproject';

function CreateProject() {
  return (
    <MaxWidthContainer>
      <CreateProjectContent />
    </MaxWidthContainer>
  );
}

export {
  CreateProject,
};
