import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DisplayProperty } from '../components/display-property';
import { euro } from '../../common/inputs/format/euro.format';
import budget from '../../assets/property/budget.png';
import renta from '../../assets/property/rentabilite.png';
import travaux from '../../assets/property/travaux.png';
import meubles from '../../assets/property/meubles.png';
import temps from '../../assets/property/temps.png';
import { useGetMatchingById } from '../../hooks/query/get-matching-by-id-query.hook';
import { flatTypeOptions, offersOptions, propertyTypeOptions } from '../../data/data';

function PropertyContainer() {
  const [props, setProps]: any = useState({});
  const [searchParams] = useSearchParams();
  const [data, setData]: any = useState(null);
  const queryInfo = useGetMatchingById(searchParams.get('matchingId'));

  const propertyColor = {
    green: '#DBEBD8',
    pink: '#CAA9C0',
    lightGold: '#F7F1E3',
    darkGreen: '#B9C7B7',
  };

  function calcPercentage(value1: any, value2: any) {
    const calc = (((value1 - value2) / value2) * 100).toFixed(1);
    // @ts-ignore
    const operator = calc >= 0 ? '' : 'moins cher que les biens du quartier';
    return `${Math.abs(Number(calc))}% ${operator}`;
  }

  useEffect(() => {
    setData({
      ...queryInfo,
      id: queryInfo?.data?.getMatchingById?._id,
      state: queryInfo?.data?.getMatchingById?.state,
      top: {
        title: queryInfo?.data?.getMatchingById?.project?.offer ? `Projet ${offersOptions?.find((f) => f.value === queryInfo?.data?.getMatchingById?.project?.offer)?.label} ${queryInfo?.data?.getMatchingById?.property?.description?.flat_type ? flatTypeOptions?.find((f) => f.value === queryInfo?.data?.getMatchingById?.property?.description?.flat_type)?.label : ''} ${queryInfo?.data?.getMatchingById?.property?.description?.surface ? `de ${queryInfo?.data?.getMatchingById?.property?.description?.surface}m2` : ''} ${queryInfo?.data?.getMatchingById?.property?.localisation?.street_address?.locality ? `à ${queryInfo?.data?.getMatchingById?.property?.localisation?.street_address?.locality} ${queryInfo?.data?.getMatchingById?.property?.localisation?.street_address?.zipcode}` : '' || ''}` : '',
        address: queryInfo?.data?.getMatchingById?.property?.localisation?.street_address?.address_line_1,
        image: queryInfo?.data?.getMatchingById?.property?.propertyPictureDocuments,
        dataCase: [{
          title: 'Budget',
          value: queryInfo?.data?.getMatchingById?.property?.finance?.total_budget ? euro.format(queryInfo?.data?.getMatchingById?.property?.finance?.total_budget) : '',
          secondValue: null,
          tooltip: 'Le montant total à investir, incluant frais et travaux',
        }, {
          title: 'Prix FAI',
          value: queryInfo?.data?.getMatchingById?.property?.finance?.expected_price ? euro.format(queryInfo?.data?.getMatchingById?.property?.finance?.expected_price) : '',
          secondValue: queryInfo?.data?.getMatchingById?.property?.finance?.district_price_per_m2 ? `${calcPercentage(queryInfo?.data?.getMatchingById?.property?.finance?.expected_price_per_m2, queryInfo?.data?.getMatchingById?.property?.finance?.district_price_per_m2)}` : null,
          tooltip: 'Le prix du bien, frais d\'agence inclus',
        }, {
          title: 'Prix/m2',
          value: queryInfo?.data?.getMatchingById?.property?.finance?.expected_price_per_m2 ? euro.format(queryInfo?.data?.getMatchingById?.property?.finance?.expected_price_per_m2) : '',
          secondValue: queryInfo?.data?.getMatchingById?.property?.finance?.district_price_per_m2 ? `${calcPercentage(queryInfo?.data?.getMatchingById?.property?.finance?.expected_price_per_m2, queryInfo?.data?.getMatchingById?.property?.finance?.district_price_per_m2)}` : null,
          tooltip: 'le prix du bien au m2, frais d\'agence inclus',
        }, {
          title: 'Rentabilité',
          value: queryInfo?.data?.getMatchingById?.property?.finance?.lsi_profitability ? `${queryInfo?.data?.getMatchingById?.property?.finance?.lsi_profitability.toFixed(1)}%` : '',
          secondValue: 'Ce projet présente une excellente rentabilité',
          tooltip: 'Le taux de rentabilité du projet, incluant tous les frais et les travaux',
        }],
      },
      generalInformations: {
        propertyTitle: queryInfo?.data?.getMatchingById?.property?.type ? `${propertyTypeOptions?.find((f) => f.value === queryInfo?.data?.getMatchingById?.property?.type)?.label} ${queryInfo?.data?.getMatchingById?.property?.description?.flat_type ? `type ${flatTypeOptions?.find((f) => f.value === queryInfo?.data?.getMatchingById?.property?.description?.flat_type)?.label}` : ''} ${queryInfo?.data?.getMatchingById?.property?.description?.surface ? `de ${queryInfo?.data?.getMatchingById?.property?.description?.surface}m2` : ''} ${queryInfo?.data?.getMatchingById?.property?.localisation?.street_address?.locality ? `à ${queryInfo?.data?.getMatchingById?.property?.localisation?.street_address?.locality} ${queryInfo?.data?.getMatchingById?.property?.localisation?.street_address?.zipcode}` : '' || ''}` : '',
      },
      financialInformations: {
        // eslint-disable-next-line no-unsafe-optional-chaining
        calcBeforeRenovation: `${calcPercentage(queryInfo?.data?.getMatchingById?.property?.finance?.expected_price_per_m2, queryInfo?.data?.getMatchingById?.property?.finance?.district_price_per_m2)}`,
        // eslint-disable-next-line no-unsafe-optional-chaining
        dataCase: [{
          icon: budget,
          title: 'Budget global',
          value: queryInfo?.data?.getMatchingById?.property?.finance?.total_budget ? euro.format(queryInfo?.data?.getMatchingById?.property?.finance?.total_budget) : '',
        }, {
          icon: renta,
          title: 'Rentabilité',
          value: queryInfo?.data?.getMatchingById?.property?.finance?.lsi_profitability ? `${queryInfo?.data?.getMatchingById?.property?.finance?.lsi_profitability.toFixed(1)}%` : '',
        }],
        dataCaseRenovation: [{
          icon: travaux,
          title: 'Coût estimé des travaux',
          value: queryInfo?.data?.getMatchingById?.property?.renovation?.renovation_cost ? euro.format(queryInfo?.data?.getMatchingById?.property?.renovation?.renovation_cost) : '',
        }, {
          icon: meubles,
          title: 'Coût estimé des meubles',
          value: queryInfo?.data?.getMatchingById?.property?.renovation?.furniture_cost ? euro.format(queryInfo?.data?.getMatchingById?.property?.renovation?.furniture_cost) : '',
        }, {
          icon: temps,
          title: 'Durée estimée des travaux',
          value: queryInfo?.data?.getMatchingById?.property?.renovation?.renovationDuration ? `${queryInfo?.data?.getMatchingById?.property?.renovation?.renovationDuration} mois` : '',
        }],
      },
      documents: queryInfo?.data?.getMatchingById?.property?.propertyDocuments,
    });
  }, [queryInfo?.data?.getMatchingById]);

  useEffect(() => {
    setProps({
      data,
      queryInfo,
      color: propertyColor,
    });
  }, [data, queryInfo.isSuccess]);

  return (
    <>
      {queryInfo && queryInfo.isSuccess
        && <DisplayProperty {...props} />}
    </>
  );
}

export {
  PropertyContainer,
};
