import { Box } from '@mui/material';
import React from 'react';

function MessageContainer({ children, display = 'flex' }: any) {
  return (
    <Box
      display={display}
      flexDirection="column"
      width="100%"
      bgcolor="primary.light"
      marginTop={1}
      borderRadius="0 10px 10px 10px"
      paddingX={{ xs: 2, md: 4 }}
      paddingY={{ xs: 2, md: 5 }}
      boxSizing="border-box"
    >
      {children}
    </Box>
  );
}
export {
  MessageContainer,
};
