import React from 'react';
import { PropertyContainer } from '../containers/container-property';
import { MaxWidthContainer } from '../../utils/components/max-width-container';

function Property() {
  return (
    <MaxWidthContainer>
      <PropertyContainer />
    </MaxWidthContainer>
  );
}

export {
  Property,
};
