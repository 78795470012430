import React from 'react';
import { Box, Typography, alpha } from '@mui/material';
import { MaxWidthContainer } from '../../utils/components/max-width-container';

interface Props {
  text: {
    title: string,
    dot1: string,
    dot2: string,
    dot3: string,
    dot4: string,
  }
}

function ReferralContent({ text }: Props) {
  return (
    <Box bgcolor="success.light" width="100%" paddingTop={{ xs: 8, md: 10 }} paddingBottom={{ xs: 2.5, md: 5 }}>
      <MaxWidthContainer>
        <Box width={{ xs: '95%', md: '80%' }} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
          <Typography variant="h3" fontWeight="700">
            {text.title}
          </Typography>
          <Box display="flex" width="100%" flexDirection="column" marginTop={10} alignItems="center">
            <Block index="1" text={text.dot1} />
            <Block index="2" text={text.dot2} />
            <Block index="3" text={text.dot3} opacity={0.35} />
            <Block index="4" text={text.dot4} disabledBar opacity={0.1} />
          </Box>
        </Box>
      </MaxWidthContainer>
    </Box>
  );
}

interface BlockProps {
  index: string
  text: string
  disabledBar?: boolean
  opacity?: number
}

function Block({
  index, text, disabledBar = false, opacity = 1,
} : BlockProps) {
  return (
    <Box display="flex" height={{ xs: 'auto', md: '110px' }} width={{ xs: '90%', md: '80%' }} marginBottom={{ xs: 4, md: 'inherit' }}>
      <Box
        position="relative"
        width="46px"
        minWidth="46px"
        border="2px solid"
        borderColor={(theme) => alpha(theme.palette.secondary.main, opacity)}
        height="46px"
        borderRadius="5px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          display={disabledBar ? 'none' : { xs: 'none', md: 'inherit' }}
          position="absolute"
          width="2px"
          height="calc(110px - 46px)"
          bgcolor={(theme) => alpha(theme.palette.secondary.main, opacity)}
          top="100%"
          left="50%"
          sx={{
            transform: 'translateX(-50%)',
          }}
        />
        <Typography fontWeight="700" variant="h4">
          {index}
        </Typography>
      </Box>
      <Typography
        whiteSpace={{ xs: 'inherit', md: 'pre' }}
        lineHeight="27px"
        fontWeight={500}
        marginLeft="50px"
        variant="h6"
        letterSpacing="0.1rem"
      >
        {text}
      </Typography>
    </Box>
  );
}

export {
  ReferralContent,
};
