import React, { useState } from 'react';
import {
  Box, Typography, Accordion, AccordionDetails, AccordionSummary,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MaxWidthContainer } from '../../utils/components/max-width-container';

interface Faq {
  answer: string,
  response: string
}

interface Props {
  text: {
    title: string
    faq: Faq[]
  }
}

function Faq({ text }: Props) {
  return (
    <Box width="100%" paddingY={{ xs: 5, md: 10 }}>
      <MaxWidthContainer>
        <Box width={{ xs: '95%', md: '80%' }} display="flex" flexDirection="column">
          <Typography textTransform="uppercase" variant="h2" fontWeight="700" letterSpacing="0.1rem" marginBottom={4}>
            {text.title}
          </Typography>
          <Box width="100%">
            {text.faq.map((e) => (
              <FaqAccordion key={e?.answer} answer={e?.answer} response={e?.response} />
            ))}
          </Box>
        </Box>
      </MaxWidthContainer>
    </Box>
  );
}

function FaqAccordion({ answer, response }: Faq) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Accordion
      elevation={0}
      square
      onChange={(e, expanded) => {
        setIsOpen(expanded);
      }}
    >
      <AccordionSummary
        sx={{
          paddingY: 1,
          borderBottom: isOpen ? 'none' : '1px solid #D9D9D9',
        }}
        expandIcon={<ExpandMoreIcon color="secondary" />}
      >
        <Typography
          fontSize="18px"
          width="80%"
          fontWeight={500}
        >
          {answer}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          variant="body1"
          fontWeight={400}
        >
          {response}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}

export {
  Faq,
};
