import React from 'react';
import { Box, Typography } from '@mui/material';

function StateCase({
  state, setState, title, subtitle, renderIcon: Icon, xsSize, mdSize,
}: any) {
  const toggle = () => {
    state ? setState(false) : setState(true);
  };
  return (
    <Box onClick={toggle} marginBottom={3}>
      {state
        ? (
          <Box
            sx={{
              width: {
                md: mdSize,
                xs: xsSize,
              },
              height: 100,
              marginX: 2,
              cursor: 'pointer',
              borderRadius: '10px',
              transition: 'all 0.3s',
              flexDirection: 'column',
              boxShadow: 'none',
              border: '1px solid',
              borderColor: 'primary.main',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              ':hover': {
                transform: 'scale(1.03)',
              },
            }}
          >
            <Icon />
            <Typography paddingTop={1} variant="caption" textAlign="center" color="primary" fontWeight="bold">{title}</Typography>
            <Typography variant="caption" color="primary" textAlign="center" fontWeight="bold">{subtitle}</Typography>
          </Box>
        ) : (
          <Box
            sx={{
              width: {
                md: mdSize,
                xs: xsSize,
              },
              height: 100,
              marginX: 2,
              cursor: 'pointer',
              flexDirection: 'column',
              transition: 'all 0.3s',
              borderRadius: '10px',
              boxShadow: 'none',
              border: '1px solid',
              borderColor: 'secondary.main',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              ':hover': {
                transform: 'scale(1.03)',
              },
            }}
          >
            <Icon />
            <Typography paddingTop={1} variant="caption" textAlign="center" fontWeight="400">{title}</Typography>
            <Typography variant="caption" textAlign="center" fontWeight="400">{subtitle}</Typography>
          </Box>
        )}
    </Box>
  );
}

export {
  StateCase,
};
