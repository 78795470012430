import React, { useEffect, useState } from 'react';
import {
  Box, Tab, Tabs,
} from '@mui/material';
import { Link as ScrollLink } from 'react-scroll';
import { useDeviceSize } from '../../hooks/utils/device-size.hook';
import { useScroll } from '../../hooks/utils/scroll.hook';

function Menu({
  section1, section2, section3, section4,
}: any) {
  const { ipadSize } = useDeviceSize();
  const [value, setValue] = useState(0);
  const { scrollYPage } = useScroll();

  const topSection1 = section1?.current?.offsetTop;
  const heightSection1 = section1?.current?.offsetHeight;
  const topSection2 = section2?.current?.offsetTop;
  const heightSection2 = section2?.current?.offsetHeight;
  const topSection3 = section3?.current?.offsetTop;
  const heightSection3 = section3?.current?.offsetHeight;
  const topSection4 = section4?.current?.offsetTop;
  const heightSection4 = section4?.current?.offsetHeight;

  useEffect(() => {
    // @ts-ignore
    if (scrollYPage >= topSection1 - 200 && scrollYPage <= (heightSection1 + topSection1 - 200)) setValue(0);
    // @ts-ignore
    else if (scrollYPage >= topSection2 - 200 && scrollYPage <= (heightSection2 + topSection2 - 200)) setValue(1);
    // @ts-ignore
    else if (scrollYPage >= topSection3 - 200 && scrollYPage <= (heightSection3 + topSection3 - 200)) setValue(2);
    // @ts-ignore
    else if (scrollYPage >= topSection4 - 200 && scrollYPage <= (heightSection4 + topSection4 - 200)) setValue(3);
  }, [heightSection1, heightSection2, heightSection3, heightSection4, scrollYPage, topSection1, topSection2, topSection3, topSection4]);

  const menuScroll = [{
    to: 'informationsGenerales',
    label: 'Informations générales',
  }, {
    to: 'informationsFinancieres',
    label: 'Informations financières',
  }, {
    to: 'estimationTravaux',
    label: 'Estimation travaux',
  }, {
    to: 'propertyDocuments',
    label: 'Documents',
  }];

  return (
    <Box
      position="fixed"
      zIndex={10}
      width="100%"
      maxWidth="1400px"
      top="70px"
      left="50%"
      sx={{
        bgcolor: 'background.paper',
        transform: 'translateX(-50%)',
      }}
    >
      <Tabs variant={ipadSize ? 'fullWidth' : 'scrollable'} value={value} centered={ipadSize} scrollButtons="auto">
        {
          menuScroll?.map((e, i) => (
            <ScrollLink key={e?.label} to={e?.to} activeClass="active" spy smooth offset={-200} duration={500}>
              <Tab label={e?.label} onChange={() => setValue(i)} sx={{ textTransform: 'none', minWidth: '200px' }} />
            </ScrollLink>
          ))
        }
      </Tabs>
    </Box>
  );
}

export {
  Menu,
};
