import { Box, Typography } from '@mui/material';
import React from 'react';

function ModeleSent() {
  return (
    <Box width="100%" marginTop={5}>
      <Box>
        <Box width="100%" display="flex" paddingY={2} flexDirection="column">
          <Typography
            fontWeight={500}
            paddingBottom={1}
            textAlign="center"
          >
            Un email vient de vous être envoyé afin de signer en ligne le Mandat.
          </Typography>
          <Box
            width="100%"
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
            display="flex"
            paddingTop={4}
          >
            <Box
              padding={0}
              paddingY={0}
              paddingX={0}
              sx={{
                bgcolor: 'secondary.main',
                color: 'text.secondary',
                borderRadius: '5px',
                padding: '18px',
                width: 230,
                height: 40,
                textTransform: 'uppercase',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                letterSpacing: { xs: 'inherit', sm: '0.1rem' },
              }}
            >
              <Typography fontWeight="bold" variant="body2">Mandat envoyé 😀</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export {
  ModeleSent,
};
