import React from 'react';
import { Box, Typography } from '@mui/material';
import { LinkWithQuery } from '../../../../utils/components/link-with-query-params';

function MenuLinks({ menu, disabled }: any) {
  return (
    <Box
      height={{ xs: '160px', sm: '120px' }}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems={{ xs: 'center', sm: 'flex-end' }}
    >
      {menu.map((e: any) => {
        if (disabled) return null;
        return (
          <Box
            key={e?.value}
          >
            <LinkWithQuery
              to={`/${e.url}`}
              onClick={() => {
                // @ts-ignore
                analytics.track('User Clicked On Call To Action', {
                  object: 'User',
                  action: 'Clicked On Call To Action',
                  click_url: `/${e.url}`,
                  click_text: e?.value,
                  location: 'top-menu-navigation',
                  category: 'link',
                });
              }}
            >
              <Typography
                sx={{
                  ':hover': {
                    textDecoration: 'underline',
                  },
                }}
                color="text.secondary"
                variant="caption"
                fontWeight="500"
                letterSpacing="0.1rem"
                textTransform="uppercase"

              >
                {e.value}
              </Typography>
            </LinkWithQuery>
          </Box>
        );
      })}
    </Box>
  );
}

export {
  MenuLinks,
};
