import {
  Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Typography,
} from '@mui/material';
import React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function ContainerForm({ children, title }: any) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      paddingTop={5}
      paddingBottom={10}
      sx={{
        width: {
          xs: '95%',
          md: '80%',
        },
      }}
    >
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
      >
        <Box
          paddingX={1}
          display="flex"
          sx={{
            justifyContent: {
              xs: 'center',
              md: 'inherit',
            },
          }}
        >
          <Typography
            textTransform="uppercase"
            variant="h5"
            marginBottom={1}
            fontWeight="700"
            marginRight={1}
            letterSpacing={{ xs: 'inherit', md: '0.1rem' }}
          >
            {title}
          </Typography>
        </Box>
      </Box>
      <Box width="100%" marginY={2}>
        {children}
      </Box>
    </Box>
  );
}

function AccordionContainer({
  children, data, index, indexAccordion, setIndexAccordion,
}: any) {
  const checkAccordion = () => {
    if (index === indexAccordion) setIndexAccordion(null);
    if (index !== indexAccordion) setIndexAccordion(index);
  };

  return (
    <Accordion
      expanded={index === indexAccordion}
      onChange={checkAccordion}
      sx={{
        marginBottom: 1,
        width: '100%',
        display: 'flex',
        bgcolor: (theme) => theme.palette.primary.light,
        borderRadius: '0px 10px 10px 10px !important',
        paddingX: 2,
        paddingY: 2,
        flexDirection: 'column',
        boxSizing: 'border-box',
        boxShadow: 'none',
        ':before': {
          content: 'none',
        },
      }}
    >
      <AccordionSummary
        sx={{
          paddingRight: {
            xs: 2,
            md: 7,
          },
        }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography
          sx={{
            paddingLeft: {
              xs: 0,
              md: 3,
            },
          }}
          variant="h6"
          color="text.primary"
          fontWeight="400"
          letterSpacing="0.1rem"
        >
          {`${data?.personal_info?.first_name} ${data?.personal_info?.last_name}`}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}

function GridContainerFormInput({ children }: any) {
  return (
    <Grid
      container
      display="flex"
      columns={{ xs: 1, md: 2 }}
      spacing={{ xs: 0, md: 7 }}
      sx={{
        width: {
          xs: 'auto',
          md: 700,
        },
        padding: {
          xs: 'inherit',
          md: '10px 24px',
        },
      }}
    >
      {children}
    </Grid>
  );
}

function SectionTitle({ text, subText }: any) {
  return (
    <Box marginBottom={3} display="flex" flexDirection="column">
      <Typography letterSpacing="0.1rem" variant="h6" fontWeight="200">{text}</Typography>
      <Typography
        fontWeight="bold"
        variant="caption"
        sx={{
          marginTop: {
            xs: 2,
            md: 0,
          },
        }}
      >
        {subText}
      </Typography>
    </Box>
  );
}

function SubmitButtonForm({
  index, indexMax, label,
}: any) {
  return (
    <Button
      variant="contained"
      sx={{
        textTransform: 'none',
        letterSpacing: { xs: 'inherit', md: '0.1rem' },
      }}
      type="submit"
      color="secondary"
      endIcon={index === indexMax - 1 ? <ArrowForwardIosIcon /> : null}
    >
      {label}
    </Button>
  );
}

export {
  ContainerForm,
  SectionTitle,
  SubmitButtonForm,
  AccordionContainer,
  GridContainerFormInput,
};
