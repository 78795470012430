import React from 'react';
import { Box, Typography } from '@mui/material';

function OptionsLinks({ logout, queryUser }: any) {
  return (
    <Box
      width={{ xs: '100%', sm: 'inherit' }}
      height={{ xs: 'auto', sm: '120px' }}
      flexDirection="column"
      display="flex"
      justifyContent="space-between"
      alignItems={{ xs: 'center', sm: 'flex-end' }}
    >
      <Box display={{ xs: 'flex', sm: 'inherit' }} flexDirection={{ xs: 'column', sm: 'inherit' }} alignItems={{ xs: 'center', sm: 'inherit' }}>
        <Typography
          color="text.secondary"
          variant="body2"
          fontWeight="500"
          whiteSpace="nowrap"
          letterSpacing="0.1rem"
          marginTop={{ xs: 6, sm: 'inherit' }}
        >
          {queryUser?.data?.findUserLoggedIn?.email}
        </Typography>
        <Typography
          marginTop={{ xs: 2, sm: 0.5 }}
          color="error.main"
          onClick={() => logout()}
          variant="caption"
          fontWeight="500"
          letterSpacing="0.1rem"
          whiteSpace="nowrap"
          textTransform="uppercase"
          sx={{
            cursor: 'pointer',
            ':hover': {
              textDecoration: 'underline',
            },
          }}
        >
          Se déconnecter
        </Typography>
      </Box>
      <Box
        width={{ xs: '100%', sm: 'inherit' }}
        display={{ xs: 'flex', sm: 'inherit' }}
        flexDirection={{ xs: 'row', sm: 'inherit' }}
        justifyContent={{ xs: 'space-around', sm: 'inherit' }}
        alignItems={{ xs: 'center', sm: 'inherit' }}
      >
        <Box
          marginTop={{ xs: 3, sm: 'inherit' }}
          component="a"
          sx={{ textDecoration: 'none' }}
          href="https://lessecretsdelimmo.fr/mentions-legales"
          target="_blank"
          rel="noreferrer"
        >
          <Typography
            sx={{
              ':hover': {
                textDecoration: 'underline',
              },
            }}
            color="text.secondary"
            variant="caption"
            fontWeight="500"
            letterSpacing="0.1rem"
            textTransform="uppercase"
            whiteSpace="nowrap"
          >
            Mentions légales
          </Typography>
        </Box>
        <Box
          marginTop={{ xs: 3, sm: 0.5 }}
          component="a"
          sx={{ textDecoration: 'none' }}
          href="https://lessecretsdelimmo.fr/confidentialite-donnees-personnelles"
          target="_blank"
          rel="noreferrer"
        >
          <Typography
            sx={{
              ':hover': {
                textDecoration: 'underline',
              },
            }}
            color="text.secondary"
            variant="caption"
            fontWeight="500"
            letterSpacing="0.1rem"
            textTransform="uppercase"
            whiteSpace="nowrap"
          >
            Données personnelles
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export {
  OptionsLinks,
};
