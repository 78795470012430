import React, {
  useEffect, useMemo, useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { Box, Grid } from '@mui/material';
import { isPossibleNumber, isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import { useLocation, useNavigate } from 'react-router-dom';
import { TextFieldCustom } from '../../../common/inputs/textfield';
import { GridContainerFormInput, SectionTitle, SubmitButtonForm } from '../containerform';
import AutocompleteAddress from '../../../common/inputs/autocompleteaddress';
import { CustomerSource } from './customersource';
import { customerSourceOptions } from '../../../data/data';
import { useUpdatePersonalInfo } from '../../../hooks/mutation/update-personal-info-mutation.hook';
import { MuiPhoneCustom } from '../../../common/inputs/mui-phone';

interface IFormInput {
  email: string
  phone: string
  street_address: string
  customer_source: string
  business_provider: string
}

function ContactContent({
  data,
  index,
  queryInfo,
  indexMax,
  to,
  indexAccordion,
  setIndexAccordion,
  queryInfoAllBusinessProviders,
  businessProvidersOptions,
  mutateProject,
}: any) {
  const countryCode = data?.personal_info?.phone && parsePhoneNumber(data?.personal_info?.phone, 'FR');
  const [country, setCountry] = useState<any>({
    countryCode: countryCode?.country ? countryCode?.country : 'FR',
    countryCallingCode: countryCode?.countryCallingCode ? countryCode?.countryCallingCode : '33',
  });
  const navigate = useNavigate();
  const { search } = useLocation();
  const methods = useForm<IFormInput>({
    defaultValues: useMemo(() => {
      let validPhone = null;
      const { phoneNumber = null, email = null } = queryInfo?.data?.getProjectById?.createdBy;
      if (!data?.personal_info.phone) {
        try {
          if (isPossibleNumber(phoneNumber, 'FR')) {
            const phone = parsePhoneNumber(phoneNumber, 'FR');
            validPhone = phone.formatInternational();
          }
        } catch (e) {
          console.log('PHONE ERROR');
        }
      } else {
        validPhone = data?.personal_info.phone;
      }
      return {
        ...data?.personal_info,
        customer_source: customerSourceOptions?.find((f) => f.value === queryInfo?.data?.getProjectById?.customer_source),
        business_provider: businessProvidersOptions?.find((f: any) => f.value === queryInfo?.data?.getProjectById?.business_provider),
        phone: validPhone,
        email: !data?.personal_info.email ? email : data?.personal_info.email,
      };
    }, [data]),
  });

  const {
    control, reset, watch, handleSubmit,
  } = methods;

  useEffect(() => {
    let validPhone = null;
    const { phoneNumber = null, email = null } = queryInfo?.data?.getProjectById?.createdBy;
    if (!data?.personal_info.phone) {
      try {
        if (isPossibleNumber(phoneNumber, 'FR')) {
          const phone = parsePhoneNumber(phoneNumber, 'FR');
          validPhone = phone.formatInternational();
        }
      } catch (e) {
        console.log('PHONE ERROR');
      }
    } else {
      validPhone = data?.personal_info.phone;
    }

    reset({
      ...data?.personal_info,
      customer_source: customerSourceOptions?.find((f) => f.value === queryInfo?.data?.getProjectById?.customer_source),
      business_provider: businessProvidersOptions?.find((f: any) => f.value === queryInfo?.data?.getProjectById?.business_provider),
      phone: validPhone,
      email: !data?.personal_info.email ? email : data?.personal_info.email,
    });
  }, [queryInfo.isSuccess]);

  const { enqueueSnackbar } = useSnackbar();

  const mutate = useUpdatePersonalInfo({
    onSuccess: () => {
      // eslint-disable-next-line react/prop-types
      queryInfo.refetch();
      setIndexAccordion(indexAccordion + 1);
      return index === indexMax - 1 ? navigate(to + search) : null;
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const btnLabel = index === indexMax - 1 ? "Valider et passer à l'étape suivante" : 'Enregistrer';

  const onSubmit = async (form: any) => {
    const formValidProject: any = {
      data: {
        customer_source: form?.customer_source?.value,
        business_provider: form?.business_provider?.value,
      },
      projectId: queryInfo?.data?.getProjectById?._id,
    };
    const formValid: any = {
      personalInformations: {
        email: form?.email,
        phone: form?.phone?.replaceAll(' ', ''),
        street_address: {
          address_line_1: form?.street_address?.address_line_1,
          country: form?.street_address?.country,
          locality: form?.street_address?.locality,
          zipcode: form?.street_address?.zipcode,
          region: '',
        },
      },
      customerId: data?._id,
    };

    await Promise.all([
      mutateProject({ ...formValidProject }),
      mutate({ ...formValid }),
    ]);

    if (formValid?.phone) {
      const userId = localStorage.getItem('userId') || null;
      // @ts-ignore
      analytics.track({
        event: 'Phone Added',
        userId,
        properties: {
          object: 'User',
          action: 'Phone Added',
          project_id: formValidProject.projectId,
        },
      });
    }
    // @ts-ignore
    analytics.track('User Clicked On Call To Action', {
      object: 'User',
      action: 'Clicked On Call To Action',
      click_url: to,
      click_text: btnLabel,
      location: 'form-update-contact',
      category: 'submit',
    });
  };

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        id="contact"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box sx={{ paddingX: { xs: 0, md: 7 } }}>
          <Box>
            <SectionTitle text="E-mail & téléphone" />
            <GridContainerFormInput>
              <Grid item xs={1}>
                <TextFieldCustom
                  rules={{
                    required: 'Veuillez renseigner votre email',
                  }}
                  type="email"
                  control={control}
                  label="Votre email *"
                  name="email"
                />
              </Grid>
              <Grid item xs={1}>
                <MuiPhoneCustom
                  control={control}
                  name="phone"
                  placeholder="+33 X XX XX XX XX"
                  label="Votre téléphone *"
                  setCountry={setCountry}
                  rules={{
                    validate: (value: string) => {
                      return isValidPhoneNumber(value, country?.countryCode?.toUpperCase()) || `Merci de renseigner un format valide (+${country?.countryCallingCode} XX XX XX XX).`;
                    },
                  }}
                />
              </Grid>
            </GridContainerFormInput>
          </Box>
          <Box>
            <SectionTitle text="Adresse" />
            <GridContainerFormInput>
              <Grid item xs={2}>
                <AutocompleteAddress
                  control={control}
                  required
                  name="street_address"
                  label="Votre adresse"
                />
              </Grid>
            </GridContainerFormInput>
            {queryInfoAllBusinessProviders.isSuccess && businessProvidersOptions
              && index === 0
              && (
                <CustomerSource
                  businessProvidersOptions={businessProvidersOptions}
                  queryInfoAllBusinessProviders={queryInfoAllBusinessProviders}
                  control={control}
                  reset={reset}
                  watch={watch}
                />
              )}
            <Box
              display="flex"
              alignItems="center"
              sx={{
                justifyContent: {
                  xs: 'center',
                  md: 'flex-end',
                },
              }}
            >
              <SubmitButtonForm index={index} indexMax={indexMax} label={btnLabel} />
            </Box>
          </Box>
        </Box>
      </Box>
    </FormProvider>
  );
}
export {
  ContactContent,
};
