import React from 'react';
import {
  Accordion, AccordionDetails, AccordionSummary, Box,
} from '@mui/material';
import { PropertyContent } from './property-content';
import { AllowedMatchingState } from '../../../api';
import { PropertySummary } from './summary/property-summary';

function PreviewProperty({
  data, queryInfoById, chooseStep,
}: any) {
  function comparaison(a: string, b: string) {
    if (a < b) return -1;
    return 1;
  }

  return (
    <Box
      width="100%"
      justifyContent="center"
      display="flex"
      flexDirection="column"
    >
      {data && data?.sort((a: any, b: any) => comparaison(a?.state, b?.state)).map((e: any) => (
        <Accordion
          key={e?.matchingId}
          expanded={e?.state === AllowedMatchingState.ACCEPTED || e?.state === AllowedMatchingState.PENDING}
          disabled={e?.state === AllowedMatchingState.REFUSED}
          elevation={0}
          sx={{
            marginY: 1,
            display: 'flex',
            width: '100%',
            bgcolor: (theme) => theme.palette.primary.light,
            borderRadius: '0px 10px 10px 10px !important',
            paddingX: 2,
            paddingY: 2,
            flexDirection: 'column',
            boxSizing: 'border-box',
            boxShadow: 'none',
            '&:before': {
              content: 'none',
            },
          }}
        >
          <AccordionSummary
            sx={{
              cursor: 'inherit !important',
            }}
          >
            <PropertySummary
              data={e}
              queryInfoById={queryInfoById}
              chooseStep={chooseStep}
              state={e?.state}
            />
          </AccordionSummary>
          <AccordionDetails sx={{ paddingTop: 2 }}>
            <Box>
              <PropertyContent
                data={e}
                state={e?.state}
                queryInfoById={queryInfoById}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}

export {
  PreviewProperty,
};
