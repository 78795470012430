import appart1 from '../assets/schedule/appart_1.jpg';
import appart2 from '../assets/schedule/appart_2.jpg';
import appart3 from '../assets/schedule/appart_3.jpg';

const scheduleText = {
  message: (duration: string) => `Programmez un appel de ${duration} minutes avec moi afin de définir votre profil investisseur, vos options de financement et votre stratégie patrimoniale !`,
  messageList: ['👷 200 investisseurs accompagnés', '🏛️ 20 projets de plus d’un million d\'€', '💰100 millions d’€ investis dans l’ancien'],
  scheduledLink: 'Calculer ma capacité d‘emprunt (durée : 6 à 8 min)',
  pictures: [{
    img: appart1,
    text: 'Voir le projet William',
    link: 'https://lessecretsdelimmo.fr/Realisation/projet-william/',
  }, {
    img: appart2,
    text: 'Voir le projet Karl',
    link: 'https://lessecretsdelimmo.fr/Realisation/projet-karl/',
  }, {
    img: appart3,
    text: 'Voir le projet Aurore',
    link: 'https://lessecretsdelimmo.fr/Realisation/projet-aurore/',
  }],
  buttonSkipDev: 'SKIP (Seulement en dev)',
  calendlyTaken: {
    message1: ['⏰ Votre rendez-vous du', 'est confirmé !'],
    messageWihoutDate: '⏰ Votre rendez-vous est confirmé !',
    message2: ['Une question ?', 'Contactez-moi directement sur mon email'],
    mail: 'souheil@lessecretsdelimmo.fr',
    message3: 'En attendant, vous pouvez consulter nos réalisations (incluant des visites immersives en 3D) ou calculer votre capacité d‘emprunt en complétant notre formulaire.',
  },
};

export {
  scheduleText,
};
