import { Box, Typography } from '@mui/material';
import React from 'react';
import { PropertyLayout } from '../display-property';
import { BuildingTrade } from './building-trade';

function EstimateRenovation({ data, color }: any) {
  return (
    <PropertyLayout>
      <Typography
        fontWeight="600"
        marginBottom={4}
        variant="h5"
      >
        Estimation travaux
      </Typography>
      <Box
        display="flex"
        justifyContent="space-around"
        sx={{
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        {data?.financialInformations?.dataCaseRenovation?.map((e: any, i: any) => (
          <Box
            key={e?.title + i.toString()}
            borderRadius="30px"
            bgcolor={color?.lightGold}
            display={e.value ? 'flex' : 'none'}
            justifyContent="space-around"
            alignItems="center"
            sx={{
              width: { xs: '100%', md: '30%' },
              paddingY: { xs: 1, md: 1.5 },
              marginY: { xs: 1, md: 0 },
            }}
          >
            <Box display="flex" alignItems="center" justifyContent="center" marginRight={3}>
              <Box
                component="img"
                src={e?.icon}
                sx={{
                  objectFit: 'contain',
                  display: 'block',
                  marginRight: '15px',
                  width: { xs: '30px', md: '35px' },
                }}
              />
              <Typography variant="body1">{e?.title}</Typography>
            </Box>
            <Typography variant="body1" color="primary">{e?.value}</Typography>
          </Box>
        ))}
      </Box>
      <BuildingTrade data={data} />
    </PropertyLayout>
  );
}

export {
  EstimateRenovation,
};
