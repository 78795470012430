import React from 'react';
import { Box } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

export const LinkWithQuery = ({
  children, to, disabled, textDecoration = false, fullwidth = false, ...props
}: any) => {
  const { search } = useLocation();
  return (
    <Box
      component={Link}
      {...props}
      to={to + search}
      sx={{
        color: 'inherit',
        textDecoration: textDecoration ? 'underline' : 'none',
        pointerEvents: disabled ? 'none' : 'inherit',
        width: fullwidth ? '100%' : 'auto',
      }}
    >
      {children}
    </Box>
  );
};
