const questionText = {
  contactUs: {
    buttonBefore: 'Précédent',
    text1: 'Adressez votre question par email à',
    email: 'contact@lessecretsdelimmo.fr',
    text2: 'Ou prenez un rendez-vous téléphonique avec l‘un de nos experts,',
    button: 'ici',
  },
};

export {
  questionText,
};
