import React, { useEffect, useState } from 'react';
import { addYears, eachYearOfInterval } from 'date-fns';
import {
  BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export const RichLineGraphNextTenYears = ({
  year,
  income,
  outcome,
  monthlyLoanBudget,
  outcomeValues,
  interestRate,
  equityContribution,
  totalBudget,
  notaryFees,
  inflationValue,
  propertyPrice,
  renovationCost,
  lsiFees,
  inflationPrice,
}: any) => {
  const YEARS_AMOUNT = 9;
  const [labels, setLabels] = useState<any>([]);
  const [cashFlowData, setCashFlowData] = useState<any>([]);

  const createArrayYears = (date: Date) => {
    const arrayYears = eachYearOfInterval({
      start: new Date(date),
      end: addYears(new Date(date), YEARS_AMOUNT),
    }).map((elem: Date, index) => `Année ${index + 1}`);
    setLabels(arrayYears);
  };

  const createArrayCashFlow = () => {
    const inflation = 1 + Number(inflationValue);
    const inflationPriceformatted = 1 + Number(inflationPrice) || 1.03;
    console.log({ inflationPriceformatted, inflationPrice });
    const cashflow: number[] = [];
    const monthlyCap: number[] = [];
    const totalEnrichment: number[] = [];
    const cumulatedYearlyValues: number[] = [];
    const estimatedSellingPrice: number[] = [];
    let cumulatedYearlyCap = 0;
    let cumulatedYearly = 0;
    labels.forEach((_label: number, index: number) => {
      if (index === 0) {
        const calc = monthlyLoanBudget - (((totalBudget - equityContribution) * interestRate) / 12);
        monthlyCap.push(calc);
        cumulatedYearlyCap = (calc * 12);
        cumulatedYearlyValues.push(cumulatedYearlyCap);
        const c = (income - outcome) * 12;
        cashflow.push(c);
        cumulatedYearly = c;
        const estimatedSellingPriceValue = propertyPrice + renovationCost + lsiFees;
        const valuable = estimatedSellingPriceValue - totalBudget;
        estimatedSellingPrice.push(estimatedSellingPriceValue);
        totalEnrichment.push(Number((cumulatedYearly + cumulatedYearlyCap + valuable).toFixed(2)));
      } else {
        const v = (totalBudget - equityContribution) - cumulatedYearlyCap;
        const calc = monthlyLoanBudget - ((v * interestRate) / 12);
        cumulatedYearlyCap += (calc * 12);
        monthlyCap.push(calc);
        cumulatedYearlyValues.push(cumulatedYearlyCap);
        const outcomeValueTmp = outcomeValues.monthlyLoanBudgetTmp + (outcomeValues?.monthly_real_estate_agency_fees * (inflation ** index)) + outcomeValues?.monthly_insurance_fees + (outcomeValues?.monthly_property_tax * (inflation ** index)) + (outcomeValues?.monthly_landlord_fees * (inflation ** index));
        cumulatedYearly += ((income * (inflation ** index)) - outcomeValueTmp) * 12;
        cashflow.push(cumulatedYearly);
        const estimatedSellingPriceValue = estimatedSellingPrice[estimatedSellingPrice.length - 1] * inflationPriceformatted;
        const valuable = estimatedSellingPriceValue - totalBudget;
        estimatedSellingPrice.push(estimatedSellingPriceValue);
        totalEnrichment.push(Number((cumulatedYearly + cumulatedYearlyCap + valuable).toFixed(2)));
      }
    });
    setCashFlowData(totalEnrichment);
  };

  useEffect(() => {
    if (!year) return;
    createArrayYears(year);
    createArrayCashFlow();
  }, [year, income, outcome, monthlyLoanBudget, interestRate]);

  const chartData = {
    labels,
    datasets: [
      {
        id: 1,
        label: 'Enrichissement',
        data: cashFlowData,
        backgroundColor: ['#B9C7B7'],
      }],
  };

  return (
    <Bar
      datasetIdKey="id-RichLineGraphNextTenYears"
      data={chartData}
    />
  );
};
