import { Box, Typography } from '@mui/material';
import React from 'react';
import { commonText } from '../../common.text';
import stars from '../../../assets/common/footer/stars-light.svg';
import google from '../../../assets/common/footer/google-light.svg';
import trustpilot from '../../../assets/common/footer/trustpilot-light.svg';

function RateLink() {
  return (
    <Box display="flex">
      <Box
        component="a"
        target="_blank"
        sx={{
          textDecoration: 'none', color: 'inherit',
        }}
        href={commonText.footer.googleLink}
      >
        <Box
          display="flex"
          marginRight={2}
          alignItems="center"
          sx={{
            cursor: 'pointer',
          }}
        >
          <Box
            component="img"
            src={stars}
            alt="google"
            width={70}
          />
          <Typography
            color="text.secondary"
            variant="body2"
            fontWeight="700"
            marginX={1}
          >
            sur
          </Typography>
          <Box
            component="img"
            src={google}
            alt="google"
            width={55}
          />
        </Box>
      </Box>

      <Box
        component="a"
        target="_blank"
        sx={{
          textDecoration: 'none', color: 'inherit',
        }}
        href={commonText.footer.trustpilotLink}
      >
        <Box
          display="flex"
          alignItems="center"
          sx={{
            cursor: 'pointer',
          }}
        >
          <Typography
            color="text.secondary"
            fontWeight="500"
            variant="body2"
            whiteSpace="nowrap"
            marginRight={1}
          >
            4.4/5 sur
          </Typography>
          <Box
            component="img"
            src={trustpilot}
            alt="trustpilot"
            width={80}
          />
        </Box>
      </Box>
    </Box>
  );
}

export {
  RateLink,
};
