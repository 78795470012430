import { useUpdateUserFromTypeformMutation } from '../../api';
import { useGetAccessToken } from '../auth/get-access-token.hook';

const useUpdateUserFromTypeform = () => {
  const { headerWithAuth } = useGetAccessToken();

  const { mutate } = useUpdateUserFromTypeformMutation(
    headerWithAuth,
    {
      onSuccess: () => {
      },
      onError: (err: any) => {
        console.log('error during UpdateUserFromTypeformMutation', err);
      },
      onSettled: () => {
      },
    },
  );

  return mutate;
};

export {
  useUpdateUserFromTypeform,
};
