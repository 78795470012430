import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { ProfessionalIncomeContent } from '../components/professional-income/professionalincomecontent';

function ProfessionalIncome({ queryInfo }: any) {
  const {
    // @ts-ignore
    item, index, indexAccordion, setIndexAccordion, indexMax, lastYear,
  } = useOutletContext();

  return (
    <ProfessionalIncomeContent
      key={item?._id}
      lastYear={lastYear}
      data={item}
      index={index}
      queryInfo={queryInfo}
      indexMax={indexMax}
      to="/form/other-income"
      indexAccordion={indexAccordion}
      setIndexAccordion={setIndexAccordion}
    />
  );
}

export {
  ProfessionalIncome,
};
