import { useFirstCalendlyMeetingBookedMutation } from '../../api';
import { useGetAccessToken } from '../auth/get-access-token.hook';

const useFirstCalendlyMeetingBooked = (options: any) => {
  const { headerWithAuth } = useGetAccessToken();
  const { mutate } = useFirstCalendlyMeetingBookedMutation(headerWithAuth, options);

  return mutate;
};

export {
  useFirstCalendlyMeetingBooked,
};
