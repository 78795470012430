import React from 'react';
import { Box, Typography } from '@mui/material';
import { BudgetContainer } from '../components/budget/budget-container';
import { AllowedOffer, AllowedYousignStatus } from '../../api';
import { euro } from '../../common/inputs/format/euro.format';
import { ContainerPreviewProperty } from '../containers/container-preview-property';
import { Item } from '../components/item';
import { BottomSection } from '../components/bottom-section';
import { LayoutPage } from '../../common/components/layout-page';
import { offersOptions } from '../../data/data';
import { OffersContainer } from '../components/offers/offers-container';
import { MessageContainer } from '../../common/components/message-container';

function Project({ queryInfo }: any) {
  const data = queryInfo?.data?.getProjectById?.finance;
  const yousignStatus = queryInfo?.data?.getProjectById?.config?.yousign_status;

  return (
    <LayoutPage character>
      <ContainerPreviewProperty queryInfoById={queryInfo} />
      {data?.monthly_loan_max
      && data?.total_max_budget
      && queryInfo?.data?.getProjectById?.offer
        ? (
          <Box>
            {(yousignStatus !== AllowedYousignStatus?.FINISHED && !queryInfo?.data?.getProjectById?.matched)
              && (
                <Box>
                  <Item
                    defaultExpanded
                    summary={{
                      label: 'Votre budget maximal est de',
                      value: euro.format(data?.total_max_budget),
                      rightText: 'Voir en détails',
                    }}
                    details={(
                      <BudgetContainer
                        queryInfo={queryInfo}
                        data={data}
                      />
                    )}
                  />
                  <Item
                    defaultExpanded
                    iconLabel
                    summary={{
                      label: queryInfo?.data?.getProjectById?.offer === AllowedOffer.INELIGIBLE ? '' : 'Vous êtes éligible à l’offre',
                      value: offersOptions.find((f) => f.value === queryInfo?.data?.getProjectById?.offer)?.label,
                      rightText: queryInfo?.data?.getProjectById?.offer === AllowedOffer.INELIGIBLE ? '' : 'Découvrir l’offre',
                    }}
                    details={(
                      <OffersContainer
                        offers={queryInfo?.data?.getProjectById?.offer}
                      />
                    )}
                  />
                </Box>
              )}
          </Box>
        ) : (
          <MessageContainer>
            <Typography
              fontWeight="500"
              letterSpacing="0.1rem"
            >
              ⚠ Renseignez vos informations personnelles et financières afin de découvrir votre profil investisseur.
            </Typography>
          </MessageContainer>
        )}
      <BottomSection />
      <Box height={90} />
    </LayoutPage>
  );
}

export {
  Project,
};
