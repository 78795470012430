import React from 'react';
import {
  Autocomplete, Box, Grid, styled, TextField, TextFieldProps, Typography,
} from '@mui/material';
import { Controller } from 'react-hook-form';

function AutocompleteCustom({
  control, name, label, tab, multiple = false, rules = null, id,
}: any) {
  function checkValue(data: any) {
    if (tab.some((f: any) => f.value === data)) {
      return tab.find((f: any) => f.value === data);
    }
    if (!tab.some((f: any) => f.value === data)) {
      return data;
    }
    if (multiple) {
      return data || [''];
    }
    return '';
  }

  return (
    <Grid item minHeight={80}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState }) => (
          <Box>
            <Autocomplete
              {...field}
              id={id}
              options={tab}
              value={checkValue(field.value)}
              isOptionEqualToValue={(option, value) => option.label === value}
              onBlur={field.onBlur}
              multiple={multiple}
              onChange={(e: any, value: any) => {
                field.onChange(value);
              }}
              renderInput={
              (params: JSX.IntrinsicAttributes & TextFieldProps) => (
                <CssAutocomplete
                  {...params}
                  fullWidth
                  color="secondary"
                  label={label}
                  size="small"
                  variant="standard"
                />
              )
            }
            />
            {fieldState?.error?.message && <Typography variant="caption" fontWeight="bold" color="error">{fieldState.error.message}</Typography>}
          </Box>
        )}
      />
    </Grid>
  );
}

const CssAutocomplete = styled(TextField)(({ theme }) => ({
  label: {
    fontWeight: '400',
    color: theme.palette.text.primary,
  },
}));

export {
  AutocompleteCustom,
};
