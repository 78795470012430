import {
  Box, Grid,
} from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { getTime, isValid } from 'date-fns';
import { useLocation, useNavigate } from 'react-router-dom';
import { AutocompleteCustom } from '../../../common/inputs/autocompletecustom';
import { CalendarCustom } from '../../../common/inputs/calendarcustom';
import { TextFieldCustom } from '../../../common/inputs/textfield';
import { GridContainerFormInput, SectionTitle, SubmitButtonForm } from '../containerform';
import {
  countryOptions, genderOptions, booleanOptions, weddingPlanOptions,
} from '../../../data/data';
import { useUpdatePersonalInfo } from '../../../hooks/mutation/update-personal-info-mutation.hook';

interface IFormInput {
  gender: string
  last_name: string
  first_name: string
  place_of_birth: string
  nationality: string
  birthdate: string
  is_married: boolean | string
  wedding_plan: string
  number_of_children: number
}

function PersonalInformationContent({
  data, index, queryInfo, indexMax, to, indexAccordion, setIndexAccordion,
}: { data: any, index: number, queryInfo: any, indexMax: number, to: string, indexAccordion: any, setIndexAccordion: any }) {
  const navigate = useNavigate();
  const { search } = useLocation();
  const {
    control, reset, handleSubmit, watch,
  } = useForm<IFormInput>({
    defaultValues: useMemo(() => {
      return {
        ...data?.personal_info,
        birthdate: new Date(data?.personal_info?.birthdate),
        is_married: booleanOptions.find((f) => f.value === data?.personal_info?.is_married)?.label,
      };
    }, [data]),
  });

  useEffect(() => {
    reset({
      ...data?.personal_info,
      birthdate: new Date(data?.personal_info?.birthdate),
      is_married: booleanOptions.find((f) => f.value === data?.personal_info?.is_married),
    });
  }, [queryInfo.isSuccess]);

  const { enqueueSnackbar } = useSnackbar();

  const mutate = useUpdatePersonalInfo({
    onSuccess: () => {
      queryInfo.refetch();
      setIndexAccordion(indexAccordion + 1);
      return index === indexMax - 1 ? navigate(to + search) : null;
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  function capitalize(str: any) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const btnLabel = index === indexMax - 1 ? "Valider et passer à l'étape suivante" : 'Enregistrer';

  const onSubmit = (form: any): any => {
    const formValid: any = {
      personalInformations: {
        gender: form?.gender?.value,
        last_name: form?.last_name.toLowerCase().split(' ').map(capitalize).join(' '),
        first_name: form?.first_name.toLowerCase().split(' ').map(capitalize).join(' '),
        place_of_birth: form?.place_of_birth,
        nationality: form?.nationality?.value,
        birthdate: form?.birthdate && getTime(form?.birthdate),
        is_married: form?.is_married?.value,
        wedding_plan: form?.wedding_plan?.value,
        number_of_children: Number(form?.number_of_children),
      },
      customerId: data?._id,
    };
    mutate({ ...formValid });
    // @ts-ignore
    analytics.track('User Clicked On Call To Action', {
      object: 'User',
      action: 'Clicked On Call To Action',
      click_url: to,
      click_text: btnLabel,
      location: 'form-update-personal-information',
      category: 'submit',
    });
  };

  return (
    <Box
      component="form"
      id="personal-information"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box sx={{
        paddingX: { xs: 0, md: 7 },
      }}
      >
        <Box>
          <SectionTitle text="Etat civil" />
          <GridContainerFormInput>
            <Grid item xs={1}>
              <AutocompleteCustom
                rules={{ required: 'Veuillez renseigner votre genre' }}
                tab={genderOptions}
                control={control}
                name="gender"
                label="Genre *"
              />
              <TextFieldCustom
                rules={{ required: 'Veuillez renseigner votre nom', minLength: 1 }}
                control={control}
                name="last_name"
                label="Nom *"
              />
              <TextFieldCustom
                rules={{ required: 'Veuillez renseigner votre prénom', minLength: 1 }}
                control={control}
                name="first_name"
                label="Prénom *"
              />
            </Grid>
            <Grid item xs={1}>
              <CalendarCustom
                rules={{
                  validate: (value: string) => isValid(new Date(value)) && getTime(new Date(value)) >= getTime(new Date('01/01/1900')) && getTime(new Date(value)) <= getTime(new Date()),
                  required: 'Veuillez renseigner votre date de naissance',
                }}
                control={control}
                name="birthdate"
                minDate={new Date('01/01/1900')}
                maxDate={new Date()}
                label="Date de naissance *"
              />
              <TextFieldCustom
                rules={{ required: 'Veuillez renseigner votre lieu de naissance', minLength: 1 }}
                control={control}
                name="place_of_birth"
                label="Lieu de naissance *"
              />
              <AutocompleteCustom
                rules={{ required: 'Veuillez renseigner votre nationalité' }}
                tab={countryOptions}
                control={control}
                name="nationality"
                label="Nationalité *"
              />
            </Grid>
          </GridContainerFormInput>
        </Box>
        <Box>
          <SectionTitle text="Livret de famille" />
          <GridContainerFormInput>
            <Grid item xs={1}>
              <AutocompleteCustom
                tab={booleanOptions}
                control={control}
                name="is_married"
                label="Etes-vous marié ou pacsé ?"
              />
              <Box
                // @ts-ignore
                display={watch('is_married') === true || watch('is_married')?.value === true ? 'inherit' : 'none'}
              >
                <AutocompleteCustom
                  tab={weddingPlanOptions}
                  control={control}
                  name="wedding_plan"
                  label="Si oui sous quel régime"
                />
              </Box>
            </Grid>
            <Grid item xs={1}>
              <TextFieldCustom
                type="number"
                control={control}
                name="number_of_children"
                label="Nombre d'enfants"
              />
            </Grid>
          </GridContainerFormInput>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              justifyContent: {
                xs: 'center',
                md: 'flex-end',
              },
            }}
          >
            <SubmitButtonForm index={index} indexMax={indexMax} label={btnLabel} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export {
  PersonalInformationContent,
};
