export const LOCAL_STORAGE_KEY = 'precreation-users-params';

// -----------------------------------------------------------------------------

const getStoredTypeform = () => {
  const params = localStorage.getItem(LOCAL_STORAGE_KEY);
  return params ? JSON.parse(params) : null;
};

// -----------------------------------------------------------------------------

const clearLocalStorage = () => {
  console.log('clearLocalStorage', LOCAL_STORAGE_KEY);
  localStorage.removeItem(LOCAL_STORAGE_KEY);
};

// -----------------------------------------------------------------------------

export {
  getStoredTypeform,
  clearLocalStorage,
};
