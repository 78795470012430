import React from 'react';
import { Box, Typography } from '@mui/material';
import appartement from '../../../assets/property/appartement.png';
import surface from '../../../assets/property/surface.png';
import coloc from '../../../assets/property/coloc.png';
import boussole from '../../../assets/property/boussole.png';
import escalier from '../../../assets/property/escalier.png';
import { flatTypeOptions, orientationOptions } from '../../../data/data';
import { AllowedOrientation } from '../../../api';

function ActractivePoint({ data }: any) {
  const tab = [{
    title: flatTypeOptions?.find((f) => f.value === data?.data?.getMatchingById?.property?.description?.flat_type)?.label,
    icon: appartement,
    display: data?.data?.getMatchingById?.property?.description?.flat_type,
  }, {
    title: `${data?.data?.getMatchingById?.property?.description?.surface}m2`,
    icon: surface,
    display: data?.data?.getMatchingById?.property?.description?.surface,
  }, {
    title: data?.data?.getMatchingById?.property?.description?.property_story === 1 ? '1er étage' : `${data?.data?.getMatchingById?.property?.description?.property_story}ème étage`,
    icon: escalier,
    display: data?.data?.getMatchingById?.property?.description?.property_story,
  }, {
    title: data?.data?.getMatchingById?.property?.general_information?.shared_flat_available ? 'Colocation possible' : null,
    icon: coloc,
    display: data?.data?.getMatchingById?.property?.general_information?.shared_flat_available,
  }, {
    title: orientationOptions?.find?.((f) => f?.value === data?.data?.getMatchingById?.property?.description?.orientation)?.label,
    icon: boussole,
    display: data?.data?.getMatchingById?.property?.description?.orientation && (data?.data?.getMatchingById?.property?.description?.orientation === AllowedOrientation.SOUTH || data?.data?.getMatchingById?.property?.description?.orientation === AllowedOrientation.SOUTH_EAST || data?.data?.getMatchingById?.property?.description?.orientation === AllowedOrientation.SOUTH_WEST),
  }];

  return (
    <Box>
      <Box
        width="100%"
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        flexWrap="wrap"
      >
        {tab?.filter((f) => f.display)?.slice(0, 5)?.map((e: any) => (
          <DisplayValue
            key={e?.title}
            title={e?.title}
            icon={e?.icon}
          />
        ))}
      </Box>
    </Box>
  );
}

function DisplayValue({ title, value, icon }: any) {
  return (
    <Box
      display={value === 0 || value === false ? 'none' : 'flex'}
      marginY={1}
      alignItems="center"
      sx={{
        marginX: { xs: 1, sm: 3 },
      }}
    >
      <Box marginRight={1}>
        <Box
          component="img"
          src={icon}
          sx={{
            objectFit: 'cover', display: 'block', width: 'auto', height: '25px',
          }}
        />
      </Box>
      <Typography variant="body2">{title}</Typography>
    </Box>
  );
}

export {
  ActractivePoint,
};
