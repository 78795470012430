import React from 'react';
import { Box, Button, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { TextFieldCustom } from '../../../common/inputs/textfield';
import { CurrencyTextFieldCustom } from '../../../common/inputs/currencytextfield';
import { SectionTitle } from '../containerform';

function FieldArrayOthers({ lastYear }: any) {
  const {
    control,
  } = useFormContext<any>();
  const { fields, append, remove } = useFieldArray<any>({
    control,
    name: 'others',
  });

  const defaultValue = {
    label: null,
    amount: 0,
  };

  return (
    <Box>
      <SectionTitle
        text="Autres revenus"
        subText={`⚠ Indiquez le montant de vos autres sources de revenu (pensions, allocations, etc.) sur ${lastYear}`}
      />
      {fields.map((item, indexField) => (
        <Box
          key={item?.id}
          display="flex"
          flexWrap="wrap"
          sx={{ marginBottom: { xs: 3, md: 0 } }}
        >
          <Box
            sx={{ width: { xs: '100%', md: 200 }, marginRight: { xs: 0, md: 2 } }}
          >
            <TextFieldCustom
              variant="outlined"
              size="small"
              control={control}
              label="Type de revenu"
              name={`others[${indexField}].label`}
            />
          </Box>
          <Box
            sx={{ width: { xs: '100%', md: 200 }, marginRight: { xs: 0, md: 2 } }}
          >
            <CurrencyTextFieldCustom
              control={control}
              size="small"
              variant="outlined"
              name={`others[${indexField}].amount`}
              label="Montant"
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => remove(indexField)}
            sx={{
              padding: 0,
              marginTop: -3,
              minWidth: 0,
              width: '100%',
              height: 40,
              color: 'text.secondary',
              display: {
                xs: 'inherit',
                md: 'none',
              },
            }}
          >
            <ClearIcon />
          </Button>
          <IconButton
            sx={{
              height: 40,
              width: 40,
              display: {
                xs: 'none',
                md: 'inherit',
              },
            }}
            onClick={() => remove(indexField)}
            color="error"
          >
            <RemoveCircleIcon />
          </IconButton>
        </Box>
      ))}
      <Box marginBottom={3} display="flex">
        <Button
          type="button"
          sx={{
            cursor: 'pointer',
            textTransform: 'none',
            textDecoration: 'underline',
            transition: 'all 0.3s',
            fontSize: '16px',
            width: 'fit-content',
            color: (theme) => theme.palette.primary.main,
            ':hover': {
              opacity: 0.8,
            },
          }}
          startIcon={<AddCircleIcon />}
          onClick={() => append(defaultValue)}
        >
          Ajouter une ligne
        </Button>
      </Box>
    </Box>
  );
}

export {
  FieldArrayOthers,
};
