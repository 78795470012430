import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box, Button, Grid, LinearProgress, Typography,
} from '@mui/material';
import { QueryCache } from '@tanstack/react-query';
import { useFieldArray, useForm } from 'react-hook-form';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getTime, isValid } from 'date-fns';
import { useSnackbar } from 'notistack';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { AutocompleteCustom } from '../../../common/inputs/autocompletecustom';
import { CalendarCustom } from '../../../common/inputs/calendarcustom';
import { TextFieldCustom } from '../../../common/inputs/textfield';
import {
  CreateProjectMutationVariables,
} from '../../../api';
import { GridContainerFormInput, SectionTitle } from '../containerform';
import {
  countryOptions, genderOptions, booleanOptions, weddingPlanOptions,
} from '../../../data/data';
import { useCreateProject } from '../../../hooks/mutation/create-project-mutation.hook';

interface IFormInput {
  gender: string
  last_name: string
  first_name: string
  place_of_birth: string
  nationality: string
  birthdate: string
  is_married: boolean
  wedding_plan: string
  number_of_children: number
}

function CreateProjectContent() {
  const [numberInvestor, setNumberInvestor] = useState(0);
  const [loader, setLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const queryCache = new QueryCache();

  const mutate = useCreateProject({
    onMutate: () => {
      setLoader(true);
    },
    onSuccess: () => {
      setTimeout(() => {
        queryCache.clear();
        window.location.pathname = '/';
        setLoader(false);
      }, 1000);
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const {
    control, handleSubmit, watch,
  } = useForm<ICustomers>();

  const { fields, append, remove } = useFieldArray<any>({
    control,
    name: 'customers',
  });

  const watchAllFields = watch(); // when pass nothing as argument, you are watching everything

  type ICustomers = {
    customers: IFormInput[]
  }

  function capitalize(str: any) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const onSubmit = (form: ICustomers, data: CreateProjectMutationVariables): void => {
    const formValid = {
      customers: form?.customers?.map((e: any) => ({
        personal_info: {
          gender: e?.gender?.value,
          last_name: e?.last_name.toLowerCase().split(' ').map(capitalize).join(' '),
          first_name: e?.first_name.toLowerCase().split(' ').map(capitalize).join(' '),
          place_of_birth: e?.place_of_birth,
          nationality: e?.nationality?.value,
          birthdate: getTime(e?.birthdate),
          is_married: e?.is_married?.value,
          wedding_plan: e?.wedding_plan?.value,
          number_of_children: Number(e?.number_of_children),
        },
      }
      )),
    };
    mutate({ data: formValid });
    const userId = localStorage.getItem('userId') || null;
    // @ts-ignore
    analytics.track({
      event: 'User Project Created',
      userId,
      properties: {
        object: 'User',
        action: 'Project Created',
        data: formValid,
      },
    });
    // @ts-ignore
    analytics.track('User Clicked On Call To Action', {
      object: 'User',
      action: 'Clicked On Call To Action',
      click_url: '/form/professional-situation',
      click_text: btnLabel,
      location: 'form-create-project',
      category: 'submit',
    });
  };

  function displayGoodNumberInvestorLabel() {
    switch (numberInvestor) {
      case (0): {
        return 'Combien d‘investisseur(s) êtes-vous ?';
      }
      case (1): {
        return 'Vous investissez seul';
      }
      case (2): {
        return 'Vous investissez à deux';
      }
      case (3): {
        return 'Vous investissez à trois';
      }
      case (4): {
        return 'Vous investissez à quatre';
      }
      case (5): {
        return 'Vous investissez à cinq';
      }
      default: {
        return `Vous êtes ${numberInvestor}`;
      }
    }
  }

  function addInvestor() {
    const defaultValue = {
      birthdate: null,
      first_name: null,
      gender: null,
      is_married: null,
      last_name: null,
      nationality: null,
      number_of_children: 0,
      place_of_birth: null,
      wedding_plan: null,
    };
    append(defaultValue);
    setNumberInvestor(numberInvestor + 1);
  }

  function removeInvestor() {
    if (numberInvestor > 0) {
      remove(fields.length - 1);
      setNumberInvestor(numberInvestor - 1);
    }
  }

  const title = 'Vos Informations personnelles';
  const btnLabel = "Valider et passer à l'étape suivante";

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      paddingTop={5}
      marginTop="100px"
      paddingBottom={10}
      sx={{ width: { xs: '95%', md: '80%' } }}
    >
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
      >
        <Box
          paddingX={1}
          display={loader ? 'none' : 'flex'}
          sx={{ justifyContent: { xs: 'center', md: 'inherit' } }}
        >
          <Typography
            textTransform="uppercase"
            variant="h5"
            marginBottom={1}
            fontWeight="700"
            marginRight={1}
            letterSpacing={{ xs: 'inherit', md: '0.1rem' }}
          >
            {title}
          </Typography>
        </Box>
      </Box>
      <Box width="100%" marginY={2}>
        {loader
          ? (
            <Box display="flex" justifyContent="center" paddingTop={30} flexDirection="column" alignItems="center">
              <Box width="fit-content" display="flex" alignItems="center" flexDirection="column">
                <Typography
                  textTransform="uppercase"
                  variant="h6"
                  fontWeight="300"
                  letterSpacing="0.1rem"
                >
                  Chargement ...
                </Typography>
                <Box width="100%">
                  <LinearProgress sx={{ height: '2px' }} variant="indeterminate" color="secondary" />
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              component="form"
              id="create-project"
              // @ts-ignore
              onSubmit={handleSubmit(onSubmit)}
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                width="100%"
                sx={{
                  bgcolor: (theme) => theme.palette.primary.light,
                  borderRadius: '0px 10px 10px 10px !important',
                }}
              >
                <Box
                  sx={{ padding: { xs: 2, md: 7 } }}
                >
                  <SectionTitle text={displayGoodNumberInvestorLabel()} />
                  <Box
                    display="flex"
                    sx={{
                      justifyContent: {
                        xs: 'center',
                        md: 'flex-start',
                      },
                    }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      marginY={1}
                      width={{ xs: '80%', md: 250 }}
                    >
                      <ButtonCustom
                        disabled={numberInvestor === 0}
                        onClick={() => {
                          removeInvestor();
                        }}
                        renderIcon={() => <RemoveIcon color="secondary" />}
                      />
                      <Typography variant="h5" color="text.primary" fontWeight="400">{numberInvestor}</Typography>
                      <ButtonCustom
                        onClick={() => {
                          addInvestor();
                        }}
                        disabled={numberInvestor === 5}
                        renderIcon={() => <AddIcon color="secondary" />}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box width="100%" marginY={3}>
                {fields.map((item, index) => (
                  <Accordion
                    key={item + index.toString()}
                    defaultExpanded={index === 0}
                    sx={{
                      marginBottom: 1,
                      width: '100%',
                      display: 'flex',
                      bgcolor: (theme) => theme.palette.primary.light,
                      borderRadius: '0px 10px 10px 10px !important',
                      paddingX: 2,
                      paddingY: 2,
                      flexDirection: 'column',
                      boxSizing: 'border-box',
                      boxShadow: 'none',
                      ':before': {
                        content: 'none',
                      },
                    }}
                  >
                    <AccordionSummary
                      sx={{
                        paddingRight: {
                          xs: 2,
                          md: 7,
                        },
                      }}
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <Box
                        display="flex"
                        sx={{
                          flexDirection: {
                            xs: 'column',
                            md: 'row',
                          },
                        }}
                      >
                        <Typography
                          variant="h5"
                          color="text.primary"
                          letterSpacing="0.1rem"
                          fontWeight="600"
                          sx={{ paddingLeft: { xs: 0, md: 3 } }}
                        >
                          {`Investisseur n° ${index + 1}`}
                        </Typography>
                        <Typography
                          variant="h5"
                          color="primary"
                          fontWeight="400"
                          sx={{ paddingLeft: { xs: 0, md: 3 } }}
                        >
                          {`${watchAllFields.customers[index]?.first_name ?? ''} ${watchAllFields.customers[index]?.last_name ?? ''}`}
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box
                        key={item + index.toString()}
                        sx={{ paddingX: { xs: 0, md: 7 } }}
                      >
                        <Box>
                          <SectionTitle text="Etat civil" />
                          <GridContainerFormInput>
                            <Grid item xs={1}>
                              <AutocompleteCustom
                                rules={{ required: 'Veuillez renseigner votre genre' }}
                                tab={genderOptions}
                                control={control}
                                name={`customers[${index}].gender`}
                                label="Genre *"
                              />
                              <TextFieldCustom
                                rules={{ required: 'Veuillez renseigner votre prénom', minLength: 1 }}
                                control={control}
                                name={`customers[${index}].first_name`}
                                label="Prénom *"
                              />
                              <TextFieldCustom
                                rules={{ required: 'Veuillez renseigner votre nom', minLength: 1 }}
                                control={control}
                                name={`customers[${index}].last_name`}
                                label="Nom *"
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <CalendarCustom
                                rules={{
                                  validate: (value: string) => isValid(new Date(value)) && getTime(new Date(value)) >= getTime(new Date('01/01/1900')) && getTime(new Date(value)) <= getTime(new Date()),
                                  required: 'Veuillez renseigner votre date de naissance',
                                }}
                                control={control}
                                minDate={new Date('01/01/1900')}
                                maxDate={new Date()}
                                name={`customers[${index}].birthdate`}
                                label="Date de naissance *"
                              />
                              <TextFieldCustom
                                rules={{ required: 'Veuillez renseigner votre lieu de naissance', minLength: 1 }}
                                control={control}
                                name={`customers[${index}].place_of_birth`}
                                label="Lieu de naissance *"
                              />
                              <AutocompleteCustom
                                rules={{ required: 'Veuillez renseigner votre nationalité' }}
                                tab={countryOptions}
                                control={control}
                                name={`customers[${index}].nationality`}
                                label="Nationalité *"
                              />
                            </Grid>
                          </GridContainerFormInput>
                        </Box>
                        <Box>
                          <SectionTitle text="Livret de famille" />
                          <GridContainerFormInput>
                            <Grid item xs={1}>
                              <AutocompleteCustom
                                tab={booleanOptions}
                                control={control}
                                name={`customers[${index}].is_married`}
                                label="Etes-vous marié ou pacsé ?"
                              />
                              <TextFieldCustom
                                type="number"
                                control={control}
                                name={`customers[${index}].number_of_children`}
                                label="Nombre d'enfants"
                              />
                            </Grid>
                            <Grid item xs={1}>
                              {
                                // @ts-ignore
                                watchAllFields.customers[index]?.is_married?.value === true
                                && (
                                  <AutocompleteCustom
                                    tab={weddingPlanOptions}
                                    control={control}
                                    name={`customers[${index}].wedding_plan`}
                                    label="Si oui sous quel régime"
                                  />
                                )
                              }
                            </Grid>
                          </GridContainerFormInput>
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Box>
              <Box padding={2}>
                <Button
                  variant="contained"
                  sx={{
                    textTransform: 'none',
                    letterSpacing: { xs: 'inherit', md: '0.1rem' },
                  }}
                  type="submit"
                  onClick={() => {
                    document.documentElement.scrollTo({
                      top: 0,
                    });
                  }}
                  color="secondary"
                  disabled={numberInvestor < 1}
                  endIcon={<ArrowForwardIosIcon />}
                >
                  {btnLabel}
                </Button>
              </Box>
            </Box>
          )}
      </Box>
    </Box>
  );
}

function ButtonCustom({
  renderIcon: Icon, onClick, disabled,
}: any) {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      variant="outlined"
      color="secondary"
      sx={{
        width: 50,
        height: 50,
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Icon />
    </Button>
  );
}

export {
  CreateProjectContent,
};
