import { Box, Typography } from '@mui/material';
import { Pie } from 'react-chartjs-2';
import React from 'react';
import {
  ArcElement, Chart as ChartJS, Legend, Tooltip,
} from 'chart.js';
import { euro } from '../../../common/inputs/format/euro.format';
import { useDeviceSize } from '../../../hooks/utils/device-size.hook';

ChartJS.register(ArcElement, Tooltip, Legend);

function GlobalBudget({ data }: any) {
  const { ipadSize } = useDeviceSize();

  const simulationColor = {
    pie1: '#91A7BA',
    pie2: '#DBEBD8',
    pie3: '#D8E3EB',
    pie4: '#F7F1E3',
    pie5: '#EBD8E5',
  };

  const datasets = {
    labels: [
      `Prix FAI ${euro.format(data?.data?.getMatchingById?.property?.finance?.expected_price)}`,
      `Travaux ${euro.format(data?.data?.getMatchingById?.property?.renovation?.renovation_cost)}`,
      `Meubles ${euro.format(data?.data?.getMatchingById?.property?.renovation?.furniture_cost)}`,
      `Frais notaire ${euro.format(data?.data?.getMatchingById?.property?.finance?.notary_fees)}`,
      `Frais LSI ${euro.format(data?.data?.getMatchingById?.property?.finance?.lsi_fees)}`],
    total: data?.data?.getPropertiesById?.finance?.total_budget,
    datasets: [{
      data: [
        data?.data?.getMatchingById?.property?.finance?.expected_price,
        data?.data?.getMatchingById?.property?.renovation?.renovation_cost,
        data?.data?.getMatchingById?.property?.renovation?.furniture_cost,
        data?.data?.getMatchingById?.property?.finance?.notary_fees,
        data?.data?.getMatchingById?.property?.finance?.lsi_fees,
      ],
      backgroundColor: [
        simulationColor.pie1,
        simulationColor.pie2,
        simulationColor.pie3,
        simulationColor.pie4,
        simulationColor.pie5],
    }],
  };

  return (
    <Box>
      <Typography
        fontWeight="400"
        marginTop={4}
        variant="h6"
        gutterBottom
        color="text.primary"
        sx={{
          textAlign: { xs: 'center', md: 'left' },
        }}
      >
        Budget global
      </Typography>
      <Typography
        fontWeight="200"
        gutterBottom
        sx={{
          textAlign: { xs: 'center', md: 'left' },
        }}
      >
        La répartition des dépenses liées à votre projet immobilier
      </Typography>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: { xs: '500px', sm: '300px' },
          padding: { xs: 1, sm: 3 },
        }}
      >
        <Box
          height="100%"
          sx={{
            width: { xs: '100%', sm: '60%' },
          }}
        >
          <Pie
            data={datasets}
            options={{
              maintainAspectRatio: false,
              responsive: true,
              plugins: {
                tooltip: {
                  enabled: false,
                },
                legend: {
                  display: true,
                  position: ipadSize ? 'right' : 'bottom',
                  fullSize: true,
                  labels: {
                    usePointStyle: true,
                    padding: ipadSize ? 25 : 20,
                    font: {
                      size: 16,
                    },
                  },
                },
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export {
  GlobalBudget,
};
