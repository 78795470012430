import { Box } from '@mui/material';
import React from 'react';
import { RateLink } from '../../common/layout/components/rate-link';

function FooterRateLinkForMobile() {
  return (
    <Box
      display={{ xs: 'flex', md: 'none' }}
      position="fixed"
      bgcolor="secondary.main"
      width="100%"
      height="auto"
      bottom={0}
      paddingY={1}
      zIndex={100}
      justifyContent="center"
      alignItems="center"
    >
      <RateLink />
    </Box>
  );
}

export {
  FooterRateLinkForMobile,
};
