import React from 'react';
import { Box, Typography } from '@mui/material';
import { LinkWithQuery } from '../../../utils/components/link-with-query-params';
import { euro } from '../../../common/inputs/format/euro.format';
import { useGlobalConfig } from '../../../hooks/query/global-config.hook';

function BudgetContainer({ data } :any) {
  const { getGlobalConfig } = useGlobalConfig();

  const menu = [{
    label: 'Apport\nrecommandé',
    value: euro.format(data?.equity_contribution),
    id: 0,
  }, {
    label: 'Durée du\ncrédit',
    value: getGlobalConfig?.isSuccess ? `${getGlobalConfig?.data?.number_of_years_loan} ans` : '',
    id: 1,
  }, {
    label: 'Emprunt\nmaximal',
    value: euro.format(data?.loan_max_value),
    id: 2,
  }, {
    label: 'Mensualité\nde crédit max.',
    value: `${euro.format(data?.monthly_loan_max)} \n( sur ${getGlobalConfig?.data?.number_of_years_loan} ans)`,
    id: 3,
  }];

  return (
    <Box display="flex" justifyContent="center" alignItems="center" width="100%" flexDirection="column">
      <Box width="100%" display="flex" justifyContent="space-between" flexWrap={{ xs: 'wrap', sm: 'inherit' }}>
        {menu?.map((e) => (
          <>
            <Box
              key={e?.id}
              display={{ xs: 'flex', sm: 'none' }}
              flexDirection="column"
              width="50%"
              height={120}
              justifyContent="space-between"
            >
              <Typography
                height="50%"
                borderTop={e?.id === 0 || e?.id === 1 ? 'none' : '1px solid black'}
                width="100%"
                borderRight={e?.id === 3 || e?.id === 1 ? 'none' : '0.5px solid black'}
                borderLeft={e?.id === 0 || e?.id === 2 ? 'none' : '0.5px solid black'}
                variant="body2"
                fontWeight="bold"
                whiteSpace="pre"
                letterSpacing="0.1rem"
                textAlign="center"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {e?.label}
              </Typography>
              <Typography
                height="50%"
                width="100%"
                variant="body2"
                display="flex"
                borderRight={e?.id === 3 || e?.id === 1 ? 'none' : '0.5px solid black'}
                borderLeft={e?.id === 0 || e?.id === 2 ? 'none' : '0.5px solid black'}
                alignItems="center"
                justifyContent="center"
                fontWeight="500"
                whiteSpace="pre"
                letterSpacing="0.1rem"
                textAlign="center"
              >
                {e?.value}
              </Typography>
            </Box>
            <Box
              key={e?.id}
              display={{ xs: 'none', sm: 'flex' }}
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              width="100%"
              borderRight={e?.id === 3 ? 'none' : '0.5px solid black'}
              borderLeft={e?.id === 0 ? 'none' : '0.5px solid black'}
            >
              <Box height="50px" display="flex" alignItems="center" justifyContent="center" paddingX={1}>
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  letterSpacing="0.1rem"
                  textAlign="center"
                >
                  {e?.label}
                </Typography>
              </Box>
              <Box width="100%" height="1px" bgcolor="secondary.main" />
              <Box height="50px" display="flex" alignItems="center" justifyContent="center" paddingX={1}>
                <Typography
                  variant="body2"
                  fontWeight="500"
                  letterSpacing="0.1rem"
                  textAlign="center"
                >
                  {e?.value}
                </Typography>
              </Box>
            </Box>
          </>
        ))}
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography
          textAlign="center"
          color="text.primary"
          fontWeight="500"
          variant="body2"
          letterSpacing="0.1rem"
          sx={{
            paddingTop: 5,
          }}
        >
          Modifiez
          {' '}
          <LinkWithQuery to="/form/personal-information" textDecoration>ici</LinkWithQuery>
          {' '}
          vos informations personnelles
        </Typography>
      </Box>
    </Box>
  );
}

export {
  BudgetContainer,
};
