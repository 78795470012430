import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import {
  green, red,
} from '@mui/material/colors';
import { frFR } from '@mui/material/locale';

// eslint-disable-next-line import/no-mutable-exports
let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0, // xs
      sm: 600, // ipadSize
      md: 900, // desktopSize
      lg: 1200, // bigDesktopSize
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: '#91A7BA',
      light: '#F3F6F9',
    },
    secondary: {
      main: '#000000',
    },
    text: {
      primary: '#000000',
      secondary: '#ffffff',
    },
    success: {
      main: green[600],
      light: '#DBEBD8',
      dark: '#B9C7B7',
    },
    error: {
      main: red[500],
      light: '#F88078',
    },
  },
  shape: {
    borderRadius: 5,
  },
  typography: {
    fontFamily: [
      'Satoshi-Variable',
      'Satoshi-VariableItalic',
      'Satoshi-Light',
      'Satoshi-LightItalic',
      'Satoshi-Regular',
      'Satoshi-Italic',
      'Satoshi-Medium',
      'Satoshi-MediumItalic',
      'Satoshi-Bold',
      'Satoshi-BoldItalic',
      'Satoshi-Black',
      'Satoshi-BlackItalic',
      'Poppins',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
}, frFR);

theme = responsiveFontSizes(theme);

export {
  theme,
};
