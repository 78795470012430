import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export function usePageViews() {
  const location = useLocation();
  const [previous, setPrevious] = useState<string | null>(null);
  const userId = localStorage.getItem('userId') || null;
  useEffect(() => {
    if (userId && `${location.pathname}${location.search}` !== previous) {
      console.log(`${location.pathname}${location.search}`, previous);
      setPrevious(`${location.pathname}${location.search}`);
      // @ts-ignore
      analytics.page(userId ? { userId } : {});
    }
  }, [`${location.pathname}${location.search}`]);
}
