import { useGetMatchingByIdQuery } from '../../api';
import { useGetAccessToken } from '../auth/get-access-token.hook';

const useGetMatchingById = (matchingId: string | null) => {
  const { accessToken, headerWithAuth } = useGetAccessToken();
  const queryInfo = useGetMatchingByIdQuery<any>(headerWithAuth, {
    matchingId: matchingId || '',
  }, { enabled: !!accessToken && !!matchingId });

  return queryInfo;
};

export {
  useGetMatchingById,
};
