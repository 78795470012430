import React from 'react';
import { Box } from '@mui/material';
import { Hero } from '../components/hero';
import { ReferralContent } from '../components/referral';
import { Timeline } from '../components/timeline';
import { Faq } from '../components/faq';
import { referralText } from '../referral.text';

function Referral({ userQuery } :any) {
  const code = userQuery?.data?.findUserLoggedIn?.referralCode || null;
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      paddingTop="70px"
      width="100%"
    >
      <Hero text={referralText.hero} code={code} />
      <Timeline text={referralText.timeline} />
      <ReferralContent text={referralText.referral} />
      <Faq text={referralText.faq} />
    </Box>
  );
}

export {
  Referral,
};
