import React, { useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// @ts-ignore
import parse from 'autosuggest-highlight/parse';
// @ts-ignore
import throttle from 'lodash/throttle';
import { Controller } from 'react-hook-form';
import { styled } from '@mui/material';

// This key was created specifically for the demo in mui.com.
// You need to create a new one for your application.
const GOOGLE_MAPS_API_KEY = 'AIzaSyD7mZCiA6--ihPLjIgegOsXVRSaiNC9eYw';

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null, details: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}

interface StructuredFormatting {
  // eslint-disable-next-line camelcase
  main_text: string;
  // eslint-disable-next-line camelcase
  secondary_text: string;
  // eslint-disable-next-line camelcase
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}

interface PlaceType {
  description: string;
  // eslint-disable-next-line camelcase
  structured_formatting: StructuredFormatting;
}

export default function AutocompleteAddress({
  control, label, name, required = false,
}: any) {
  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
  const loaded = React.useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    }
    loaded.current = true;
  }

  const fetch = useMemo(
    () => throttle(
      (
        request: { input: string },
        callback: (results?: readonly PlaceType[]) => void,
      ) => {
        (autocompleteService.current as any).getPlacePredictions(
          request,
          callback,
        );
      },
      200,
    ),
    [],
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.details && (window as any).google) {
      autocompleteService.details = new (
        window as any
      ).google.maps.places.PlacesService(document.createElement('div'));
    }

    if (!autocompleteService.current || !autocompleteService.details) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      // @ts-ignore
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Grid item height={80}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <Box>
              <Autocomplete
                {...field}
                id="google-map-demo"
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                filterOptions={(x) => x}
                options={options}
                autoComplete
                disableClearable
                includeInputInList
                filterSelectedOptions
                value={field.value ? field?.value?.address_line_1 : null}
                onChange={(event: any, newValue: PlaceType | null) => {
                // @ts-ignore
                  autocompleteService.details.getDetails({ placeId: newValue.place_id }, (result, status) => {
                    field.onChange({
                      address_line_1: result.formatted_address || '',
                      country: result?.address_components?.find((r: any) => r.types.includes('country'))?.long_name || '',
                      locality: result?.address_components?.find((r: any) => r.types.includes('locality'))?.long_name || '',
                      zipcode: result?.address_components?.find((r: any) => r.types.includes('postal_code'))?.long_name || '',
                    });
                  });
                  setValue(newValue);
                  setOptions(newValue ? [newValue, ...options] : options);
                }}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                  <CssAutocomplete
                    {...params}
                    fullWidth
                    color="secondary"
                    label={label}
                    required={required}
                    size="small"
                    variant="standard"
                  />
                )}
                renderOption={(props, option) => {
                  const matches = option.structured_formatting.main_text_matched_substrings;
                  const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match: any) => [match.offset, match.offset + match.length]),
                  );
                  return (
                    <li {...props}>
                      <Grid container alignItems="center">
                        <Grid item>
                          <Box
                            component={LocationOnIcon}
                            sx={{ color: 'text.primary', mr: 2 }}
                          />
                        </Grid>
                        <Grid item xs>
                          {parts.map((part: { highlight: any; text: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }, index: React.Key | null | undefined) => (
                            <span
                              /* eslint-disable-next-line react/no-array-index-key */
                              key={index}
                              style={{
                                fontWeight: part.highlight ? 700 : 400,
                              }}
                            >
                              {part.text}
                            </span>
                          ))}
                          <Typography variant="body2" color="text.primary">
                            {option.structured_formatting.secondary_text}
                          </Typography>
                        </Grid>
                      </Grid>
                    </li>
                  );
                }}
              />
            </Box>
          );
        }}
      />
    </Grid>
  );
}

const CssAutocomplete = styled(TextField)(({ theme }) => ({
  label: {
    fontWeight: '400',
    color: theme.palette.text.primary,
  },
}));
