import {
  Box, Button, Modal, Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { AllowedMatchingState } from '../../api';
import { useUpdateMatchingState } from '../../hooks/mutation/update-matching-state-mutation.hook';

function ButtonTop({ queryInfo }: any) {
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<any>({
    title: null,
    action: {
      state: null,
    },
  });

  return (
    <Box width="100%" display="flex" alignItems="center" justifyContent="space-around" marginBottom={4}>
      <ButtonContainer
        action={() => {
          setOpenModal(true);
          setModalData({
            title: 'Êtes-vous sûr de vouloir décliner ce projet immobilier ?',
            action: {
              state: AllowedMatchingState.REFUSED,
            },
          });
        }}
        colorButton="#EBD8E5"
        title="Décliner"
        Icon={CloseIcon}
      />
      <ButtonContainer
        action={() => {
          setOpenModal(true);
          setModalData({
            title: 'Êtes-vous sûr de vouloir faire une offre pour ce bien ?',
            action: {
              state: AllowedMatchingState.ACCEPTED,
            },
          });
        }}
        title="Je m'engage"
        colorButton="#DBEBD8"
        Icon={CheckIcon}
      />
      <ModalComponent
        openModal={openModal}
        setOpenModal={setOpenModal}
        data={modalData}
        setModalData={setModalData}
        queryInfo={queryInfo}
      />
    </Box>
  );
}

function ButtonContainer({
  title, colorButton, action, Icon,
}: any) {
  return (
    <Box
      onClick={action}
      paddingY={1}
      borderRadius="5px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        marginX: { xs: 1, sm: 3 },
        paddingX: { xs: 2, sm: 4 },
        background: colorButton,
        border: 'solid 1px transparent',
        cursor: 'pointer',
        transition: 'background 0.3s',
        ':hover': {
          background: 'white',
          border: 'solid 1px',
          borderColor: colorButton,
        },
      }}
    >
      <Icon fontSize="small" />
      <Typography marginLeft={2} fontWeight="300" variant="body1">{title}</Typography>
    </Box>
  );
}

function ModalComponent({
  openModal, setOpenModal, data, setModalData, queryInfo,
}: any) {
  const [searchParams] = useSearchParams();
  const { search } = useLocation();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const mutate = useUpdateMatchingState({
    onSuccess: async () => {
      queryInfo.refetch();
      setOpenModal(false);
      setModalData({
        title: null,
        action: {
          state: null,
        },
      });
      return data?.action?.state === AllowedMatchingState.REFUSED ? navigate(`/project${search}`) : null;
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  return (
    <Modal
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      open={openModal}
    >
      <Box
        width={{ xs: '90%', sm: '100%' }}
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <Box
          bgcolor="text.secondary"
          borderRadius="5px"
          marginBottom={2}
          display="flex"
          padding={3}
          justifyContent="space-between"
          alignItems="center"
          position="relative"
          flexDirection="column"
          sx={{
            width: { xs: '90%', md: '500px' },
          }}
        >
          <CloseIcon
            sx={{
              position: 'absolute', top: 5, right: 5, cursor: 'pointer',
            }}
            onClick={() => {
              setModalData({
                title: null,
                action: {
                  state: null,
                },
              });
              setOpenModal(false);
            }}
          />
          <Typography marginY={5} textAlign="center" gutterBottom fontWeight={600}>{data?.title}</Typography>
          <Box display="flex" justifyContent="space-around" alignItems="center" width="100%">
            <Button
              color="error"
              variant="contained"
              sx={{
                textTransform: 'inherit',
                fontWeight: 'bold',
              }}
              onClick={() => {
                setModalData({
                  title: null,
                  action: {
                    state: null,
                  },
                });
                setOpenModal(false);
              }}
            >
              Annuler
            </Button>
            <Button
              color="primary"
              variant="contained"
              sx={{
                textTransform: 'inherit',
                fontWeight: 'bold',
                color: (theme) => theme.palette.background.paper,
              }}
              onClick={() => {
                mutate({
                  data: {
                    state: data?.action?.state,
                  },
                  // @ts-ignore
                  matchingId: searchParams.get('matchingId'),
                });
                // @ts-ignore
                analytics.track(data?.action?.state === AllowedMatchingState.Refused ? 'User Declined Property Matched' : 'User Approved Property Matched', {
                  object: 'User',
                  action: data?.action?.state === AllowedMatchingState.REFUSED ? 'Declined Property Matched' : 'Approved Property Matched',
                  click_text: data?.action?.state === AllowedMatchingState.REFUSED ? 'Décliner' : "Je m'engage",
                  location: 'project-property',
                  category: 'button',
                  property_id: queryInfo?.data?.getMatchingById?.property?._id,
                  zip_code: queryInfo?.data?.getMatchingById?.property?.localisation?.street_address?.zipcode,
                });
              }}
            >
              Confirmer
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export {
  ButtonTop,
};
