import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { TaxesContent } from '../components/taxes/taxescontent';

function Taxes({ queryInfo }: any) {
  const {
    // @ts-ignore
    item, index, indexAccordion, setIndexAccordion, indexMax, lastYear,
  } = useOutletContext();

  return (
    <TaxesContent
      key={item?._id}
      data={item}
      index={index}
      lastYear={lastYear}
      queryInfo={queryInfo}
      indexMax={indexMax}
      to="/project"
      indexAccordion={indexAccordion}
      setIndexAccordion={setIndexAccordion}
    />
  );
}

export {
  Taxes,
};
