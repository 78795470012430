import { Outlet, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { AccordionContainer, ContainerForm } from '../components/containerform';
import { LoaderForm } from '../components/loader-form';
import { MaxWidthContainer } from '../../utils/components/max-width-container';

function Form({ queryInfo }: any) {
  const [indexAccordion, setIndexAccordion] = useState<number>(0);
  const location = useLocation();
  const argsInUrl = location.pathname?.split('/')[2];
  const lastYear: any = Number(format(new Date(), 'yyyy')) - 1;
  const indexMax = queryInfo?.data?.getProjectById?.customers?.length;

  useEffect(() => {
    setIndexAccordion(0);
  }, [location.pathname]);

  const chooseTitleForContainerForm = (param: any) => {
    switch (param) {
      case 'personal-information':
        return 'Informations personnelles';
      case 'professional-situation':
        return 'Situation professionelle';
      case 'contact':
        return 'Contact';
      case 'professional-income':
        return 'Revenus professionnels';
      case 'other-income':
        return 'Revenus additionnels';
      case 'saving':
        return 'Épargne';
      case 'cost':
        return 'Charges';
      case 'taxes':
        return 'Impôts';
      default:
        return '';
    }
  };

  if (argsInUrl === 'create-project') {
    return (
      <Outlet />
    );
  }

  return (
    <MaxWidthContainer>
      {queryInfo.isSuccess
        ? (
          <ContainerForm title={chooseTitleForContainerForm(argsInUrl)}>
            {queryInfo?.data?.getProjectById?.customers?.map((item: any, index: number) => (
              <AccordionContainer
                key={item?._id}
                data={item}
                index={index}
                indexAccordion={indexAccordion}
                setIndexAccordion={setIndexAccordion}
              >
                <Outlet
                  context={{
                    item,
                    index,
                    indexAccordion,
                    setIndexAccordion,
                    indexMax,
                    lastYear,
                  }}
                />
              </AccordionContainer>
            ))}
          </ContainerForm>
        ) : (
          <LoaderForm />
        )}
    </MaxWidthContainer>
  );
}

export {
  Form,
};
