import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { SavingContent } from '../components/saving/savingcontent';

function Saving({ queryInfo }: any) {
  const {
    // @ts-ignore
    item, index, indexAccordion, setIndexAccordion, indexMax,
  } = useOutletContext();

  return (
    <SavingContent
      key={item?._id}
      data={item}
      index={index}
      queryInfo={queryInfo}
      indexMax={indexMax}
      to="/form/cost"
      indexAccordion={indexAccordion}
      setIndexAccordion={setIndexAccordion}
    />
  );
}

export {
  Saving,
};
