import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { OtherIncomeContent } from '../components/other-income/otherincomecontent';

function OtherIncome({ queryInfo }: any) {
  const {
    // @ts-ignore
    item, index, indexAccordion, setIndexAccordion, indexMax, lastYear,
  } = useOutletContext();
  return (
    <OtherIncomeContent
      key={item?._id}
      lastYear={lastYear}
      data={item}
      index={index}
      queryInfo={queryInfo}
      indexMax={indexMax}
      to="/form/saving"
      indexAccordion={indexAccordion}
      setIndexAccordion={setIndexAccordion}
    />
  );
}

export {
  OtherIncome,
};
