import { Box, Typography } from '@mui/material';
import React from 'react';
import { MaxWidthContainer } from '../../utils/components/max-width-container';
import { FooterRateLinkForMobile } from '../../schedule/components/footer-mobile';
import souheil from '../../assets/schedule/souheil.png';

function LayoutPage({ children, mobileFooter = false, character = false }: any) {
  return (
    <MaxWidthContainer>
      <Box
        width="100%"
        display="flex"
        position="relative"
        flexDirection="column"
        alignItems="center"
      >
        {mobileFooter && <FooterRateLinkForMobile />}
        <Box
          paddingTop={{ xs: 15, md: 16 }}
          display="flex"
          flexDirection="column"
          width={{ xs: '95%', md: '80%' }}
        >
          {character
            && (
              <Box display="flex" alignItems="center" marginBottom={1}>
                <Box
                  component="img"
                  width="54px"
                  height="100%"
                  sx={{
                    objectFit: 'contain',
                  }}
                  src={souheil}
                />
                <Box display="flex" flexDirection="column" marginLeft="13px" fontSize="16px">
                  <Typography
                    variant="body1"
                    fontWeight="700"
                    letterSpacing={{ sx: 'inherit', md: '0.1rem' }}
                    lineHeight="18px"
                  >
                    Souheil
                  </Typography>
                  <Typography fontWeight="400" letterSpacing={{ sx: 'inherit', md: '0.1rem' }} variant="body2">
                    Votre conseiller investissement
                  </Typography>
                </Box>
              </Box>
            )}
          {children}
        </Box>
      </Box>
    </MaxWidthContainer>
  );
}

export {
  LayoutPage,
};
