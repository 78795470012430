import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { CostContent } from '../components/cost/costcontent';

function Cost({ queryInfo }: any) {
  const {
    // @ts-ignore
    item, index, indexAccordion, setIndexAccordion, indexMax, lastYear,
  } = useOutletContext();

  return (
    <CostContent
      key={item?._id}
      data={item}
      index={index}
      lastYear={lastYear}
      queryInfo={queryInfo}
      indexMax={indexMax}
      to="/form/taxes"
      indexAccordion={indexAccordion}
      setIndexAccordion={setIndexAccordion}
    />
  );
}

export {
  Cost,
};
