import React from 'react';
import { alpha, Box, Typography } from '@mui/material';
import { MaxWidthContainer } from '../../utils/components/max-width-container';
import cup from '../../assets/referral/cup.svg';
import check from '../../assets/referral/check.svg';

interface Object {
  value: string
  label: string
}
interface Props {
  text: {
    title: string
    dot1: Object
    dot2: Object
    dot3: Object
    dot4: Object
  }
}
function Timeline({ text }: Props) {
  return (
    <Box width="100%" paddingY={{ xs: 5, md: 8 }} bgcolor="secondary.main" position="relative">
      <MaxWidthContainer>
        <Box width={{ xs: '95%', md: '80%' }} display="flex" flexDirection="column" alignItems="center">
          <Typography
            variant="h4"
            width={{ xs: '80%', sm: '100%' }}
            textAlign="center"
            color="text.secondary"
            letterSpacing="0.1rem"
            fontWeight="700"
            marginBottom={12}
          >
            {text.title}
          </Typography>
          <Box marginBottom={8} width={{ xs: '90%', md: 'inherit' }} alignItems="center" justifyContent="center" display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
            <TheBall value={text.dot1.value} label={text.dot1.label} variant="one" />
            <TheBall value={text.dot2.value} label={text.dot2.label} variant="one" />
            <TheBall value={text.dot3.value} label={text.dot3.label} variant="two" darkBar />
            <TheBall value={text.dot4.value} label={text.dot4.label} variant="three" disabledLine dark />
          </Box>
        </Box>
      </MaxWidthContainer>
      <Box
        component="img"
        src={cup}
        position="absolute"
        bottom="calc(76px / -2)"
        left="50%"
        height={{ xs: '60px', sm: '76px' }}
        width={{ xs: '60px', sm: '76px' }}
        sx={{
          objectFit: 'cover',
          transform: 'translateX(-50%)',
        }}
      />
    </Box>
  );
}

interface BallProps {
  value: string
  label: string
  variant: string
  disabledLine?: boolean
  dark?: boolean
  darkBar?: boolean
}

function TheBall({
  disabledLine, variant, dark, darkBar, value, label,
}: BallProps) {
  const spacing = '230px';
  const mobileSpacing = '130px';
  const widthBall = '50px';

  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'row', md: 'column' }}
      justifyContent={{ xs: 'flex-start', md: 'center' }}
      alignItems={{ xs: 'flex-start', md: 'center' }}
      minWidth={{ xs: '100%', md: spacing }}
      minHeight={{ xs: mobileSpacing, md: 'inherit' }}
    >
      <Box
        position="relative"
        minWidth={{ xs: '50px', md: widthBall }}
        width={{ xs: '50px', md: widthBall }}
        height={{ xs: '50px', md: widthBall }}
        bgcolor={variant === 'one' ? 'success.light' : 'secondary.main'}
        borderRadius="110px"
        alignItems="center"
        display="flex"
        justifyContent="center"
        border="6px solid"
        borderColor={variant === 'two' ? '#D8E3EB' : dark ? (theme) => alpha(theme.palette.success.light, 0.25) : 'success.light'}
      >
        <Box
          display={disabledLine ? 'none' : { xs: 'inherit', md: 'inherit' }}
          position="absolute"
          left={{ xs: '50%', md: '100%' }}
          top={{ xs: '100%', md: '50%' }}
          width={{ xs: '5px', md: `calc(${spacing} - ${widthBall})` }}
          height={{ xs: `calc(${mobileSpacing} - ${widthBall})`, md: '5px' }}
          bgcolor={darkBar ? (theme) => alpha(theme.palette.success.light, 0.25) : 'success.light'}
          sx={{ transform: { xs: 'translateX(-50%)', md: 'translateY(-50%)' } }}
        />
        {variant === 'one'
          && (
            <Box
              component="img"
              src={check}
              width={`calc(${widthBall} / 2)`}
              sx={{
                objectFit: 'contain',
              }}
            />
          )}
        {variant === 'two'
          && (
            <Box
              width={`calc(${widthBall} / 2)`}
              height={`calc(${widthBall} / 2)`}
              borderRadius="38px"
              bgcolor="#D8E3EB"
            />
          )}
      </Box>
      <Box
        width={{ xs: '100%', md: '100%' }}
        display="flex"
        alignItems={{ xs: 'flex-start', md: 'center' }}
        justifyContent={{ xs: 'flex-start', md: 'center' }}
        flexDirection="column"
        marginTop={{ xs: 0, md: 3 }}
        marginLeft={{ xs: 10, md: 0 }}
      >
        <Typography
          color={variant === 'two' ? '#D8E3EB' : dark ? (theme) => alpha(theme.palette.success.light, 0.25) : 'success.light'}
          fontWeight={700}
          letterSpacing="0.1rem"
          textAlign="center"
          variant="h4"
          marginBottom={1}
        >
          {value}
        </Typography>
        <Typography
          color={variant === 'two' ? '#D8E3EB' : dark ? (theme) => alpha(theme.palette.success.light, 0.25) : 'success.light'}
          fontWeight={400}
          letterSpacing="0.1rem"
          whiteSpace={{ xs: 'inherit', md: 'pre' }}
          textAlign={{ xs: 'left', md: 'center' }}
          variant="body1"
        >
          {label}
        </Typography>
      </Box>
    </Box>
  );
}

export {
  Timeline,
};
