import { useLocation } from 'react-router-dom';

const useGetScreenHint = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  return searchParams.get('screen_hint') || 'login';
};

const useGetTypeFormResult = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  return searchParams.get('offre') || '0';
};

export {
  useGetScreenHint,
  useGetTypeFormResult,
};
