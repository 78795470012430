import React, { useEffect, useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
import { CurrencyTextFieldCustom } from '../../../common/inputs/currencytextfield';
import { GridContainerFormInput, SectionTitle, SubmitButtonForm } from '../containerform';
import { AllowedEmploymentContract } from '../../../api';
import { useUpdateProject } from '../../../hooks/mutation/update-project-mutation.hook';

interface IFormInput {
  amount_wages: string
}

function ProfessionalIncomeContent({
  lastYear, data, index, queryInfo, indexMax, to, indexAccordion, setIndexAccordion,
}: { lastYear: number, data: any, index: number, queryInfo: any, indexMax: number, to: string, indexAccordion: any, setIndexAccordion: any }) {
  const {
    control, reset, handleSubmit,
  } = useForm<IFormInput>({
    defaultValues: useMemo(() => {
      return data?.personal_finance?.income;
    }, [data]),
  });

  const navigate = useNavigate();
  const { search } = useLocation();

  useEffect(() => {
    reset(data);
  }, [data, queryInfo.isSuccess, reset]);

  const { enqueueSnackbar } = useSnackbar();

  const mutate = useUpdateProject({
    onSuccess: () => {
      queryInfo.refetch();
      setIndexAccordion(indexAccordion + 1);
      return index === indexMax - 1 ? navigate(to + search) : null;
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const btnLabel = index === indexMax - 1 ? "Valider et passer à l'étape suivante" : 'Enregistrer';

  const onSubmit = (form: any) => {
    const formValid: any = {
      data: {
        customers: [{
          customerId: data?._id,
          income: {
            amount_wages: Number(form?.amount_wages),
          },
        }],
      },
      projectId: queryInfo?.data?.getProjectById?._id,
    };
    mutate({ ...formValid });
    // @ts-ignore
    analytics.track('User Clicked On Call To Action', {
      object: 'User',
      action: 'Clicked On Call To Action',
      click_url: to,
      click_text: btnLabel,
      location: 'form-update-professional-income',
      category: 'submit',
    });
  };

  return (
    <Box
      component="form"
      id="professional-income"
      onSubmit={handleSubmit(onSubmit)}
    >
      {data.personal_info.employment_contract
        ? (
          <Box
            sx={{ paddingX: { xs: 0, md: 7 } }}
          >
            {data.personal_info.employment_contract === AllowedEmploymentContract.CDI
              ? (
                <Box>
                  <SectionTitle
                    text="Votre salaire mensuel net après impôt sur le revenu"
                    subText="⚠ Si votre salaire varie, vous pouvez en indiquer la moyenne sur les trois derniers mois"
                  />
                  <GridContainerFormInput>
                    <Grid item xs={1}>
                      <CurrencyTextFieldCustom
                        control={control}
                        name="amount_wages"
                        label="Salaire mensuel net"
                      />
                    </Grid>
                  </GridContainerFormInput>
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{
                      justifyContent: {
                        xs: 'center',
                        md: 'flex-end',
                      },
                    }}
                  >
                    <SubmitButtonForm index={index} indexMax={indexMax} label={btnLabel} />
                  </Box>
                </Box>
              ) : (
                <Box>
                  <SectionTitle text={`Vos revenus nets annuels ${lastYear} après impôt sur le revenu`} />
                  <GridContainerFormInput>
                    <Grid item xs={1}>
                      <CurrencyTextFieldCustom
                        control={control}
                        name="amount_wages"
                        label="Revenus annuels nets"
                      />
                    </Grid>
                  </GridContainerFormInput>
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{
                      justifyContent: {
                        xs: 'center',
                        md: 'flex-end',
                      },
                    }}
                  >
                    <SubmitButtonForm index={index} indexMax={indexMax} label={btnLabel} />
                  </Box>
                </Box>
              )}
          </Box>
        ) : (
          <Box
            sx={{ paddingX: { xs: 0, md: 7 } }}
          >
            <SectionTitle
              text="Vos revenus"
              subText="⚠ Veuillez dans un premier temps détailler votre situation professionnelle"
            />
          </Box>
        )}
    </Box>
  );
}

export {
  ProfessionalIncomeContent,
};
