import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import logoLsi from '../../../../assets/common/logo_lsi_blanc.svg';
import fb from '../../../../assets/common/footer/fb.svg';
import insta from '../../../../assets/common/footer/insta.svg';
import linkedin from '../../../../assets/common/footer/linkedin.svg';
import { commonText } from '../../../common.text';
import { RateLink } from '../rate-link';

function Networks() {
  const navigate = useNavigate();
  return (
    <Box
      marginBottom={{ xs: 4, sm: 'inherit' }}
      width={{ xs: '100%', sm: '40%' }}
      height="100%"
    >
      <Box
        display="flex"
        height="100%"
        flexDirection="column"
        justifyContent="space-between"
        onClick={() => navigate('/')}
        sx={{
          cursor: 'pointer',
          marginLeft: {
            xs: 3,
            lg: 1,
          },
        }}
      >
        <Box
          component="img"
          sx={{
            objectFit: 'contain',
            width: '120px',
          }}
          src={logoLsi}
        />
        <SocialNetworks />
        <RateLink />
      </Box>
    </Box>
  );
}

function SocialNetworks() {
  return (
    <Box display="flex">
      <Network
        link={commonText.footer.fbLink}
        logo={fb}
        alt="facebook"
      />
      <Network
        link={commonText.footer.instaLink}
        logo={insta}
        alt="Instagram"
      />
      <Network
        link={commonText.footer.linkedinLink}
        logo={linkedin}
        alt="LinkedIn"
      />
    </Box>
  );
}

function Network({ link, logo, alt }: any) {
  return (
    <Box
      component="a"
      target="_blank"
      sx={{
        textDecoration: 'none', color: 'inherit',
      }}
      href={link}
    >
      <Box
        component="img"
        src={logo}
        alt={alt}
        sx={{
          width: '17px',
          marginRight: '10px',
        }}
      />
    </Box>
  );
}

export {
  Networks,
};
