import { useGetProjectByIdQuery } from '../../api';
import { useGetAccessToken } from '../auth/get-access-token.hook';

const useGetProjectById = (projectId: string | null) => {
  const { accessToken, headerWithAuth } = useGetAccessToken();
  const queryInfo = useGetProjectByIdQuery(
    headerWithAuth,
    {
      projectId: projectId || '',
    },
    { enabled: !!accessToken && !!projectId },
  );

  return queryInfo;
};

export { useGetProjectById };
