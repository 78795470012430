const commonText = {
  footer: {
    fbLink: 'https://www.facebook.com/Lessecretsdelimmo',
    instaLink: 'https://www.instagram.com/lessecretsdelimmo.fr/',
    linkedinLink: 'https://www.linkedin.com/company/les-secrets-de-l\'immo/',
    googleLink: 'https://www.google.com/search?rlz=1C5CHFA_enFR983FR983&sxsrf=ALiCzsYbxqAfI5k8ncT_lMuuFRDS1lYA_g:1667922358532&q=les+secrets+de+l%27immo+google+avis&spell=1&sa=X&ved=2ahUKEwikwcX99p77AhUQyYUKHbOrBogQBSgAegQIBhAB&biw=1920&bih=969&dpr=1',
    trustpilotLink: 'https://fr.trustpilot.com/review/lessecretsdelimmo.fr',
  },
};

export {
  commonText,
};
