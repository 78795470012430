import React from 'react';
import { Box, Typography } from '@mui/material';
import { ModeleUnsigned } from './step/unsigned';
import { AllowedMatchingState, AllowedYousignStatus } from '../../../../../api';
import { ModeleSent } from './step/sent';

function SectionYousign({ queryInfoById, data }: any) {
  const yousignStatus = queryInfoById?.data?.getProjectById?.config?.yousign_status;

  if ((!yousignStatus
    || yousignStatus === AllowedYousignStatus.DRAFT
    || yousignStatus === AllowedYousignStatus.REFUSED
    || yousignStatus === AllowedYousignStatus.EXPIRED)
    && data?.state !== AllowedMatchingState.REFUSED) {
    return (
      <ModeleUnsigned queryInfoById={queryInfoById} />
    );
  }
  if (yousignStatus === AllowedYousignStatus.ACTIVE && data?.state !== AllowedMatchingState.REFUSED) {
    return (
      <ModeleSent />
    );
  }
  if (yousignStatus === AllowedYousignStatus?.FINISHED || data?.state === AllowedMatchingState.REFUSED) {
    return (
      <Box>
        <Typography
          variant="h5"
          fontWeight="400"
          textAlign={{ xs: 'center', sm: 'left' }}
          letterSpacing={{ xs: 'inherit', sm: '0.1rem' }}
          display={data?.state === AllowedMatchingState.REFUSED ? 'inherit' : 'none'}
          sx={{
            marginTop: { xs: 2, sm: 0 },
            marginBottom: { xs: 2, sm: 1 },
          }}
        >
          Votre proposition de bien
        </Typography>
        <Typography
          variant="body1"
          textAlign={{ xs: 'center', sm: 'left' }}
          letterSpacing={{ xs: 'inherit', md: '0.1rem' }}
          fontWeight="700"
          display={data?.state === AllowedMatchingState.REFUSED ? 'inherit' : 'none'}
        >
          {data?.summary?.title}
        </Typography>
      </Box>
    );
  }
  return <Box />;
}

export {
  SectionYousign,
};

/* const chooseYousignStep = (query: any) => {
    if (!query) {
      return {
        step: 'SEARCH',
        text: 'Lancez les recherches',
      };
    }
    if (query === AllowedYousignStatus.Active) {
      return {
        step: 'SENT',
        text: 'Contrat en attente de signature',
      };
    }
    if (query === AllowedYousignStatus.Draft) {
      return {
        step: 'SEARCH',
        text: 'Brouillon',
      };
    }
    if (query === AllowedYousignStatus.Expired) {
      return {
        step: 'SEARCH',
        text: 'Délais de signature expiré, veuillez signer à nouveau',
      };
    }
    if (query === AllowedYousignStatus.Finished) {
      return {
        step: 'SIGNED',
        text: 'Contrat signé !',
      };
    }
    if (query === AllowedYousignStatus.Refused) {
      return {
        step: 'SEARCH',
        text: 'Signature refusée, veuillez signer à nouveau',
      };
    }
    return {
      step: 'SEARCH',
      text: 'Lancez les recherches',
    };
  }; */
