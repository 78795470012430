import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { DesktopHeader } from './desktopheader';
import { MobileHeader } from './mobileheader';
import { useGetProjectsByCurrentUser } from '../../../../hooks/query/get-projects-by-current-user-query.hook';

function Header({
  disabled,
  bottomShadow,
  openMenu,
  setOpenMenu,
  menu,
  logout,
  queryUser,
  openMenuMobile,
  setOpenMenuMobile,
}: any) {
  const [options, setOptions] = useState<{ label: string, value: string }[] | any>(null);
  const [selectedValue, setSelectedValue] = useState<{ label: string, value: string } | any>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const queryInfo = useGetProjectsByCurrentUser();

  useEffect(() => {
    const projectId = searchParams.get('projectId');
    if (queryInfo?.data?.getProjectsByCurrentUser && queryInfo?.data?.getProjectsByCurrentUser.length > 0) {
      const optionsFormatted = queryInfo?.data?.getProjectsByCurrentUser.map((e: any) => ({ value: e._id, label: e.name }));
      if (optionsFormatted.length !== 0) {
        setOptions(optionsFormatted);
        if (projectId) {
          const option = optionsFormatted.find((e: any) => e.value === projectId);
          if (option) {
            setSelectedValue(option);
          }
        }
      }
    }
  }, [queryInfo?.data, queryInfo.isSuccess, searchParams, setSearchParams]);

  useEffect(() => {
    if (location.pathname !== '/project/property') {
      searchParams.delete('matchingId');
    }
  }, [location.pathname, searchParams]);

  const logoutWithTracking = async () => {
    const userId = localStorage.getItem('userId') || null;
    // @ts-ignore
    analytics.track({
      event: 'User Signed Out',
      userId,
      properties: {
        object: 'User',
        action: 'Signed Out',
      },
    });
    // @ts-ignore
    analytics.reset();
    await localStorage.removeItem('userId');
    await logout();
  };

  return (
    <>
      <DesktopHeader
        menu={menu}
        bottomShadow={bottomShadow}
        disabled={disabled}
        queryInfo={queryInfo}
        queryUser={queryUser}
        options={options}
        selectedValue={selectedValue}
        setSearchParams={setSearchParams}
        logout={logoutWithTracking}
        setSelectedValue={setSelectedValue}
        openMenu={openMenu}
        setOpenMenu={setOpenMenu}
      />
      <MobileHeader
        menu={menu}
        bottomShadow={bottomShadow}
        disabled={disabled}
        queryInfo={queryInfo}
        queryUser={queryUser}
        options={options}
        selectedValue={selectedValue}
        setSearchParams={setSearchParams}
        logout={logoutWithTracking}
        setSelectedValue={setSelectedValue}
        openMenu={openMenuMobile}
        setOpenMenu={setOpenMenuMobile}
      />
    </>
  );
}

export {
  Header,
};
