import React from 'react';
import { Box, Typography } from '@mui/material';
import chambre from '../../../assets/property/arrangement/chambre.png';
import salon from '../../../assets/property/arrangement/salon.png';
import salle_a_manger from '../../../assets/property/arrangement/salle_a_manger.png';
import salle_de_bain from '../../../assets/property/arrangement/salle_de_bain.png';
import salle_eau from '../../../assets/property/arrangement/salle_eau.png';
import cuisine from '../../../assets/property/arrangement/cusine.png';
import wc from '../../../assets/property/arrangement/wc.png';
import buanderie from '../../../assets/property/arrangement/buanderie.png';
import bureau from '../../../assets/property/arrangement/bureau.png';
import { DisplayValue } from '.';

function Floors({ data }: any) {
  return (
    <Box>
      <Typography
        marginTop={3}
        variant="h6"
        fontWeight="400"
        color="secondary"
        sx={{
          textAlign: { xs: 'center', sm: 'left' },
        }}
      >
        Aménagement actuel
      </Typography>
      <Typography
        fontWeight="200"
        gutterBottom
        sx={{
          textAlign: { xs: 'center', sm: 'left' },
        }}
      >
        Les différentes pièces qui composent le bien
      </Typography>
      {data?.data?.getMatchingById?.property?.arrangement?.floors?.map((item: any, i: any) => (
        <Box key={item?.id + i.toString()} width="100%" display="flex" flexDirection="column">
          <Box marginTop={2} marginBottom={1} marginLeft={1}>
            <Typography variant="h6">{i === 0 ? '' : `Étage n°${i}`}</Typography>
          </Box>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            flexWrap="wrap"
            sx={{
              justifyContent: { xs: 'center', sm: 'flex-start' },
            }}
          >
            <DisplayValue title="Chambre" value={item?.bedroom_number} icon={chambre} />
            <DisplayValue title="Salon" value={item?.living_room_number} icon={salon} />
            <DisplayValue title="Salle à manger" value={item?.dining_room_number} icon={salle_a_manger} />
            <DisplayValue title="Salle de bain" value={item?.bathroom_number} icon={salle_de_bain} />
            <DisplayValue title="Salle d’eau" value={item?.shower_room_number} icon={salle_eau} />
            <DisplayValue title="Cuisine" value={item?.kitchen_number} icon={cuisine} />
            <DisplayValue title="WC" value={item?.water_closet_number} icon={wc} />
            <DisplayValue title="Buanderie" value={item?.laundry_room_number} icon={buanderie} />
            <DisplayValue title="Bureau" value={item?.office_number} icon={bureau} />
          </Box>
        </Box>
      ))}
    </Box>
  );
}

export {
  Floors,
};
