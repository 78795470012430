import { Box, Typography } from '@mui/material';
import React from 'react';
import {
  Chart as ChartJS, ArcElement, Tooltip, Legend,
} from 'chart.js';
import { PropertyLayout } from '../display-property';
import { GlobalBudget } from './global-budget';
import { PricePerM2 } from './price-per-m2';
import { Funding } from './funding';
import { useGlobalConfig } from '../../../hooks/query/global-config.hook';

ChartJS.register(ArcElement, Tooltip, Legend);

function FinancialInformations({ data, color }: any) {
  const { getGlobalConfig } = useGlobalConfig();
  return (
    <PropertyLayout>
      <Typography marginBottom={4} variant="h5" fontWeight="600">Informations financières</Typography>
      <Box
        display="flex"
        justifyContent="space-around"
        sx={{
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        {data?.financialInformations?.dataCase?.map((e: any, i: any) => (
          <Box
            key={e?.value + i?.toString()}
            borderRadius="30px"
            bgcolor={color?.lightGold}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              paddingY: { xs: 1, md: 2 },
              width: { xs: '100%', md: '30%' },
              marginY: { xs: 1, md: 0 },
            }}
          >
            <Box display="flex" alignItems="center" justifyContent="center" marginRight={3}>
              <Box
                component="img"
                src={e?.icon}
                sx={{
                  objectFit: 'contain',
                  display: 'block',
                  width: { xs: '20px', md: '30px' },
                  marginRight: '15px',
                }}
              />
              <Typography variant="h6">{e?.title}</Typography>
            </Box>
            <Typography variant="h6" color="primary">{e?.value}</Typography>
          </Box>
        ))}
      </Box>
      <GlobalBudget data={data} />
      {data?.data?.getMatchingById?.property?.finance?.district_price_per_m2
      && data?.data?.getMatchingById?.property?.finance?.district_price_per_m2 !== 0
      && data?.data?.getMatchingById?.property?.finance?.district_price_per_m2 >= data?.data?.getMatchingById?.property?.finance?.expected_price_per_m2
        ? (
          <PricePerM2 data={data} color={color} />
        ) : null}
      { getGlobalConfig?.isSuccess
        && <Funding data={data} color={color} getGlobalConfig={getGlobalConfig} />}
      {/* <ResalePotential color={color} /> */}
    </PropertyLayout>
  );
}

export {
  FinancialInformations,
};
