import React, { useEffect, useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
import { GridContainerFormInput, SectionTitle, SubmitButtonForm } from '../containerform';
import { CurrencyTextFieldCustom } from '../../../common/inputs/currencytextfield';
import { useUpdateProject } from '../../../hooks/mutation/update-project-mutation.hook';

interface IFormInput {
  amount_taxe: string
  amount_housing_taxe: string
  amount_renting_taxe: string
}

function TaxesContent({
  index, data, lastYear, queryInfo, indexMax, to, indexAccordion, setIndexAccordion,
}: any) {
  const navigate = useNavigate();
  const { search } = useLocation();

  const {
    control, reset, handleSubmit,
  } = useForm<IFormInput>({
    defaultValues: useMemo(() => {
      return data?.personal_finance?.taxes;
    }, [data]),
  });

  useEffect(() => {
    reset(data);
  }, [queryInfo.isSuccess]);

  const { enqueueSnackbar } = useSnackbar();

  const mutate = useUpdateProject({
    onSuccess: () => {
      // eslint-disable-next-line react/prop-types
      queryInfo.refetch();
      setIndexAccordion(indexAccordion + 1);
      return index === indexMax - 1 ? navigate(to + search) : null;
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const btnLabel = index === indexMax - 1 ? "Valider et passer à l'étape suivante" : 'Enregistrer';

  const onSubmit = (form: any) => {
    const formValid: any = {
      data: {
        customers: [{
          customerId: data?._id,
          taxes: {
            amount_taxe: Number(form?.amount_taxe),
            amount_housing_taxe: Number(form?.amount_housing_taxe),
            amount_renting_taxe: Number(form?.amount_renting_taxe),
          },
        }],
      },
      projectId: queryInfo?.data?.getProjectById?._id,
    };
    mutate({ ...formValid });
    // @ts-ignore
    analytics.track('User Clicked On Call To Action', {
      object: 'User',
      action: 'Clicked On Call To Action',
      click_url: to,
      click_text: btnLabel,
      location: 'form-update-taxes',
      category: 'submit',
    });
  };

  return (
    <Box
      component="form"
      id="taxes"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box sx={{ paddingX: { xs: 0, md: 7 } }}>
        <Box>
          <SectionTitle
            text={`Vos impôts sur ${lastYear}`}
            subText="⚠ Si vous investissez avec votre
              conjoint(e), vous pouvez mutualiser vos impôts à cette étape et ne pas les détailler dans la
              partie de votre conjoint(e)."
          />
          <GridContainerFormInput>
            <Grid item xs={1}>
              <CurrencyTextFieldCustom
                control={control}
                name="amount_taxe"
                label="Votre dernier impôt sur le revenu"
              />
              <CurrencyTextFieldCustom
                control={control}
                name="amount_housing_taxe"
                label="Votre taxe foncière"
              />
            </Grid>
            <Grid item xs={1}>
              <CurrencyTextFieldCustom
                control={control}
                name="amount_renting_taxe"
                label="Votre taxe d’habitation"
              />
            </Grid>
          </GridContainerFormInput>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              justifyContent: {
                xs: 'center',
                md: 'flex-end',
              },
            }}
          >
            <SubmitButtonForm index={index} indexMax={indexMax} label={btnLabel} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export {
  TaxesContent,
};
