import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useLocation, useNavigate } from 'react-router-dom';
import { InlineWidget } from 'react-calendly';
import { useDeviceSize } from '../../hooks/utils/device-size.hook';
import { LayoutPage } from '../../common/components/layout-page';
import { MessageContainer } from '../../common/components/message-container';
import { questionText } from '../question.text';

function ContactUs() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { desktopSize } = useDeviceSize();
  const [calendlyOpen, setOpenCalendly] = useState(false);
  const [loader, setLoader] = useState(false);

  function OpenCalendlyWithTimeOut() {
    setTimeout(() => {
      setOpenCalendly(true);
      setLoader(false);
    }, 1000);
  }

  return (
    <LayoutPage character>
      <Box marginY={2} display="flex" alignItems="center">
        <ArrowBackIosNewIcon sx={{ width: '15px', marginRight: 1 }} />
        <Typography
          onClick={() => navigate(`/question${search}`)}
          sx={{
            textDecoration: 'underline',
            cursor: 'pointer',

          }}
        >
          {questionText?.contactUs?.buttonBefore}
        </Typography>
      </Box>
      <MessageContainer>
        <Typography
          fontWeight="500"
          letterSpacing={{ xs: 'inherit', sm: '0.1rem' }}
        >
          {questionText?.contactUs?.text1}
          <Box
            component="a"
            marginLeft={0.8}
            sx={{
              textDecoration: 'underline',
              color: 'inherit',
            }}
            href={`mailto:${questionText?.contactUs?.email}`}
          >
            {questionText?.contactUs?.email}
          </Box>
        </Typography>
      </MessageContainer>
      <MessageContainer>
        <Box display="flex" alignItems="center">
          <Typography
            fontWeight="500"
            letterSpacing={{ xs: 'inherit', sm: '0.1rem' }}
          >
            {questionText?.contactUs?.text2}
          </Typography>
          <Typography
            fontWeight="500"
            marginLeft={0.8}
            sx={{
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            letterSpacing={{ xs: 'inherit', sm: '0.1rem' }}
            onClick={() => {
              setLoader(true);
              OpenCalendlyWithTimeOut();
            }}
          >
            {questionText?.contactUs?.button}
          </Typography>
        </Box>
      </MessageContainer>
      {loader && <Typography marginLeft={3} variant="h6">...</Typography>}
      <MessageContainer display={calendlyOpen ? 'flex' : 'none'}>
        <InlineWidget
          styles={{
            width: desktopSize ? '60%' : '100%',
            height: desktopSize ? '700px' : '1200px',
          }}
          url="https://calendly.com/lessecretsdelimmo/15min"
          pageSettings={{
            hideEventTypeDetails: true,
            hideLandingPageDetails: true,
          }}
        />
      </MessageContainer>
      <Box height={100} />
    </LayoutPage>
  );
}

export { ContactUs };
