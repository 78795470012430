import React from 'react';
import {
  alpha, Autocomplete, Box, Paper, styled, TextField, TextFieldProps, Typography,
} from '@mui/material';
import keyLsi from '../../../../assets/common/key-lsi.svg';
import disco from '../../../../assets/common/disco.svg';
import { LinkWithQuery } from '../../../../utils/components/link-with-query-params';
import crossIcon from '../../../../assets/common/cross.svg';

function Menu({
  queryInfo,
  options,
  queryUser,
  disabled,
  selectedValue,
  setSelectedValue,
  setSearchParams,
  menu,
  firstArgsInUrl,
  logout,
  setOpenMenu,
  openMenu,
}: any) {
  return (
    <Box
      width={openMenu ? '100vw' : 0}
      height={{ xs: '100dvh', md: '100vh' }}
      position="fixed"
      top={0}
      right={0}
      display="flex"
      alignItems="flex-start"
      justifyContent="flex-end"
      bgcolor={(theme) => alpha(theme.palette.secondary.main, 0.80)}
    >
      <Box
        width={{ xs: '100%', md: '320px' }}
        minWidth={{ xs: '100%', md: '320px' }}
        marginRight={openMenu ? 'inherit' : '-320px'}
        height={{ xs: '100dvh', md: '100vh' }}
        bgcolor="background.default"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        borderLeft="1px solid"
        borderColor={(theme) => alpha(theme.palette.secondary.main, 1)}
        sx={{
          transition: 'margin-right 0.3s',
        }}
      >
        <Box>
          {openMenu
            && (
              <Box
                width="calc(100% - 320px)"
                height="100%"
                position="fixed"
                top={0}
                left={0}
                onClick={() => setOpenMenu(false)}
              />
            )}
          <Hero
            setOpenMenu={setOpenMenu}
            openMenu={openMenu}
          />
          <Box height="70px" bgcolor="secondary.main" />
          <ChooseProject
            queryInfo={queryInfo}
            options={options}
            disabled={disabled}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            setSearchParams={setSearchParams}
          />
          <MyProject
            menu={menu}
            disabled={disabled}
            firstArgsInUrl={firstArgsInUrl}
            setOpenMenu={setOpenMenu}
          />
        </Box>
        <Box>
          <Bottom
            menu={menu}
            queryUser={queryUser}
            firstArgsInUrl={firstArgsInUrl}
            setOpenMenu={setOpenMenu}
            logout={logout}
          />
        </Box>
      </Box>

    </Box>
  );
}

function Hero({ setOpenMenu, openMenu }: any) {
  return (
    <Box
      width={{ xs: '100%', md: '320px' }}
      minWidth={{ xs: '100%', md: '320px' }}
      position="absolute"
      top={0}
      right={0}
      height="70px"
      marginRight={0}
      bgcolor="secondary.main"
      display={openMenu ? 'flex' : 'none'}
      alignItems="center"
      justifyContent="space-between"
      boxSizing="border-box"
    >
      <Box display="flex" alignItems="center" justifyContent="center" width="20%">
        <Box component="img" src={keyLsi} width="34px" sx={{ objectFit: 'contain' }} />
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center" width="60%">
        <Typography letterSpacing="0.1rem" fontWeight={700} color="text.secondary">MENU</Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center" width="20%">
        <Box
          width="20%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            display={{ xs: 'inherit', md: 'none' }}
            component="img"
            src={crossIcon}
            width="23px"
            height="19px"
            onClick={() => setOpenMenu(false)}
          />
        </Box>
      </Box>
    </Box>
  );
}

function ChooseProject({
  queryInfo,
  options,
  disabled,
  selectedValue,
  setSelectedValue,
  setSearchParams,
}: any) {
  return (
    <Box width="100%">
      {queryInfo && queryInfo.data?.getProjectsByCurrentUser.length !== 0 && !disabled && options && (
        <>
          <Box bgcolor="success.dark" paddingY={0.7} paddingX={2} boxSizing="border-box">
            <Typography letterSpacing="0.1rem" fontWeight={700} variant="caption">Choisir mon projet</Typography>
          </Box>
          <Box bgcolor="success.light" paddingY={2} paddingX={2} boxSizing="border-box">
            <Autocomplete
              fullWidth
              disablePortal
              disableClearable
              // eslint-disable-next-line react/no-unstable-nested-components
              PaperComponent={({ children }) => (
                <Paper sx={{ bgcolor: 'secondary.main', color: 'text.secondary' }}>
                  <Typography component="ul" fontWeight="400">
                    {children}
                  </Typography>
                </Paper>
              )}
              options={options}
              value={selectedValue}
              onChange={(e, value) => {
                setSelectedValue(value);
                setSearchParams({ projectId: value?.value });
              }}
              renderInput={
                (params: JSX.IntrinsicAttributes & TextFieldProps) => (
                  <CssAutocomplete
                    {...params}
                    fullWidth
                    size="small"
                    variant="standard"
                  />
                )
              }
            />
          </Box>
        </>
      )}
    </Box>
  );
}

function MyProject({
  menu, disabled, firstArgsInUrl, setOpenMenu,
}: any) {
  return (
    <Box
      width="100%"
      bgcolor="background.default"
    >
      <Box bgcolor="#D8E3EB" paddingY={0.7} paddingX={2} boxSizing="border-box">
        <Typography letterSpacing="0.1rem" fontWeight={700} variant="caption">Mon projet</Typography>
      </Box>
      <Box bgcolor="background.default" paddingY={2} borderBottom="2px solid #D8E3EB">
        {menu.map((e: any) => {
          return (
            <Box key={e?.value}>
              {e?.variant === false
                && (
                  <Box key={e?.value}>
                    {e.page === firstArgsInUrl ? (
                      <Box paddingY={2} display="flex" alignItems="center" paddingX={2} boxSizing="border-box">
                        <Box component="img" src={e?.icon} width="20px" sx={{ objectFit: 'contain' }} marginRight={3} />
                        <Typography
                          fontWeight="bold"
                          letterSpacing="0.1rem"
                        >
                          {e.value}
                        </Typography>
                      </Box>
                    ) : (
                      <LinkWithQuery
                        to={`/${e.url}`}
                        disabled={disabled}
                        onClick={() => {
                          setOpenMenu(false);
                          // @ts-ignore
                          analytics.track('User Clicked On Call To Action', {
                            object: 'User',
                            action: 'Clicked On Call To Action',
                            click_url: `/${e.url}`,
                            click_text: e?.value,
                            location: 'top-menu-navigation',
                            category: 'link',
                          });
                        }}
                      >
                        <Box
                          paddingY={2}
                          paddingX={2}
                          display="flex"
                          alignItems="center"
                          boxSizing="border-box"
                          sx={{
                            opacity: disabled ? 0.2 : 'inherit',
                            '#text': {
                              transition: 'font-weight 0.3s',
                            },
                            '#img': {
                              transition: 'width 0.3s',
                            },
                            ':hover': {
                              '#text': {
                                fontWeight: 'bold',
                              },
                              '#img': {
                                width: '22px',
                              },
                            },
                          }}
                        >
                          <Box
                            id="img"
                            component="img"
                            src={e?.icon}
                            width="20px"
                            sx={{ objectFit: 'contain' }}
                            marginRight={3}
                          />
                          <Typography
                            id="text"
                            color="text.primary"
                            fontWeight={500}
                            letterSpacing="0.1rem"
                          >
                            {e.value}
                          </Typography>
                        </Box>
                      </LinkWithQuery>
                    )}
                  </Box>
                )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

function Bottom({
  menu, firstArgsInUrl, setOpenMenu, logout, queryUser,
}: any) {
  const item = menu[menu.length - 1];

  return (
    <Box width="100%">
      <Box bgcolor="secondary.main">
        {(queryUser?.data?.findUserLoggedIn?.referralProgramAvailable || queryUser?.data?.findUserLoggedIn?.isCustomerAlready)
          && (
            <Box key={item?.value}>
              {item.page === firstArgsInUrl ? (
                <Box paddingY={3} display="flex" alignItems="center" paddingX={2} boxSizing="border-box">
                  <Box component="img" src={item?.icon} width="20px" sx={{ objectFit: 'contain' }} marginRight={3} />
                  <Typography
                    color="text.secondary"
                    fontWeight="bold"
                    letterSpacing="0.1rem"
                  >
                    {item.value}
                  </Typography>
                </Box>
              ) : (
                <LinkWithQuery
                  to={`/${item.url}`}
                  onClick={() => {
                    setOpenMenu(false);
                    // @ts-ignore
                    analytics.track('User Clicked On Call To Action', {
                      object: 'User',
                      action: 'Clicked On Call To Action',
                      click_url: `/${item.url}`,
                      click_text: item?.value,
                      location: 'top-menu-navigation',
                      category: 'link',
                    });
                  }}
                >
                  <Box
                    paddingY={3}
                    paddingX={2}
                    alignItems="center"
                    display="flex"
                    boxSizing="border-box"
                    sx={{
                      '#text': {
                        transition: 'font-weight 0.3s',
                      },
                      '#img': {
                        transition: 'width 0.3s',
                      },
                      ':hover': {
                        '#text': {
                          fontWeight: 'bold',
                        },
                        '#img': {
                          width: '22px',
                        },
                      },
                    }}
                  >
                    <Box
                      id="img"
                      component="img"
                      src={item?.icon}
                      width="20px"
                      sx={{ objectFit: 'contain' }}
                      marginRight={3}
                    />
                    <Typography
                      id="text"
                      color="text.secondary"
                      fontWeight={500}
                      letterSpacing="0.1rem"
                    >
                      {item.value}
                    </Typography>
                  </Box>
                </LinkWithQuery>
              )}
            </Box>
          )}
      </Box>
      <Box
        paddingY={3}
        paddingX={2}
        alignItems="center"
        display="flex"
        boxSizing="border-box"
        sx={{
          cursor: 'pointer',
          '#text': {
            transition: 'font-weight 0.3s',
          },
          '#img': {
            transition: 'width 0.3s',
          },
          ':hover': {
            '#text': {
              fontWeight: 'bold',
            },
            '#img': {
              width: '22px',
            },
          },
        }}
        bgcolor="#FFC8C8"
        onClick={() => logout()}
      >
        <Box component="img" id="img" src={disco} width="20px" sx={{ objectFit: 'contain' }} marginRight={3} />
        <Typography id="text" color="text.primary" fontWeight={500} letterSpacing="0.1rem">Se déconnecter</Typography>
      </Box>
    </Box>
  );
}

function ButtonToggle({ mode, setMode }: any) {
  return (
    <Box
      onClick={() => setMode(!mode)}
      zIndex={1000}
      display="flex"
      position="relative"
      width={mode ? '23px' : '19px'}
      height={mode ? '19px' : '15px'}
      flexDirection="column"
      justifyContent={mode ? 'center' : 'space-between'}
      sx={{
        cursor: 'pointer',
        transition: 'width 0.3s',
      }}
    >
      <Box
        width="100%"
        height="1px"
        bgcolor="white"
        position={mode ? 'absolute' : 'inherit'}
        sx={{
          transform: mode ? 'rotate(45deg)' : 'inherit',
          transition: 'transform 0.3s',
        }}
      />
      <Box
        display={mode ? 'none' : 'inherit'}
        width="100%"
        height="1px"
        bgcolor="white"
      />
      <Box
        width="100%"
        height="1px"
        position={mode ? 'absolute' : 'inherit'}
        bgcolor="white"
        sx={{
          transform: mode ? 'rotate(-45deg)' : 'inherit',
          transition: 'transform 0.3s',
        }}
      />
    </Box>
  );
}

const CssAutocomplete = styled(TextField)(({ theme }) => ({
  input: {
    fontWeight: '700',
  },
  '.css-pgbj5g-MuiInputBase-root-MuiInput-root:before': {
    content: 'none',
  },
}));

export {
  Menu,
  ButtonToggle,
};
