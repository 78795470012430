import TimerRoundedIcon from '@mui/icons-material/TimerRounded';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { SectionYousign } from './yousign/section-yousign';
import { AllowedMatchingState } from '../../../../api';

function PropertySummary({
  data, state, queryInfoById,
}: any) {
  const chooseIcon = (param: any) => {
    switch (param) {
      case AllowedMatchingState.PENDING: {
        return (<TimerRoundedIcon fontSize="small" sx={{ fill: (theme: any) => theme.palette.text.secondary }} />);
      }
      case AllowedMatchingState.ACCEPTED: {
        return (<DoneIcon fontSize="small" sx={{ fill: (theme: any) => theme.palette.text.secondary }} />);
      }
      case AllowedMatchingState.REFUSED: {
        return (<CloseIcon fontSize="small" sx={{ fill: (theme: any) => theme.palette.text.secondary }} />);
      }
      default: {
        return (<TimerRoundedIcon fontSize="small" sx={{ fill: (theme: any) => theme.palette.text.secondary }} />);
      }
    }
  };

  const chooseTitle = (param: any) => {
    switch (param) {
      case AllowedMatchingState.PENDING: {
        return 'Réponse attendue sous 24h';
      }
      case AllowedMatchingState.ACCEPTED: {
        return 'Accepté';
      }
      case AllowedMatchingState.REFUSED: {
        return 'Décliné';
      }
      default: {
        return '';
      }
    }
  };

  const chooseColor = (param: any) => {
    switch (param) {
      case AllowedMatchingState.PENDING: {
        return (theme: any) => theme?.palette?.warning.main;
      }
      case AllowedMatchingState.ACCEPTED: {
        return (theme: any) => theme?.palette?.success.main;
      }
      case AllowedMatchingState.REFUSED: {
        return (theme: any) => theme?.palette?.error.main;
      }
      default: {
        return (theme: any) => theme?.palette?.primary?.main;
      }
    }
  };

  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="space-between"
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        sx={{
          width: { xs: '100%', md: state === AllowedMatchingState.REFUSED ? '70%' : '100%' },
        }}
      >
        <Box
          flexDirection="column"
          sx={{
            display: { xs: 'flex', md: state === AllowedMatchingState.REFUSED ? 'none' : 'flex' },
          }}
        >
          <Box display="flex" alignItems="center" flexDirection={{ xs: 'column', md: 'row' }}>
            <Box
              padding={0.3}
              bgcolor={chooseColor(data?.state)}
              borderRadius={100}
              marginRight={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {chooseIcon(data?.state)}
            </Box>
            <Typography
              variant="body2"
              letterSpacing="0.1rem"
              color={chooseColor(data?.state)}
              fontWeight="bold"
              marginRight={1}
            >
              {data?.state === AllowedMatchingState.PENDING ? 'URGENT' : chooseTitle(data?.state)}
            </Typography>
            {data?.state === AllowedMatchingState.PENDING
              && (
                <Typography
                  variant="body2"
                  letterSpacing="0.1rem"
                  textTransform="uppercase"
                  color={chooseColor(data?.state)}
                >
                  {chooseTitle(data?.state)}
                </Typography>
              )}
          </Box>
        </Box>
        <SectionYousign
          data={data}
          queryInfoById={queryInfoById}
          offer={data?.offer}
        />
      </Box>
      <Box
        flexDirection="column"
        alignItems="center"
        width="20%"
        sx={{
          display: { xs: 'none', md: state === AllowedMatchingState.REFUSED ? 'flex' : 'none' },
        }}
      >
        <Typography
          color={chooseColor(data?.state)}
          fontWeight="400"
          textAlign="center"
          marginBottom={1}
        >
          {data?.state === AllowedMatchingState.PENDING ? 'URGENT' : chooseTitle(data?.state)}
        </Typography>
        <Box
          borderRadius={100}
          bgcolor={chooseColor(data?.state)}
          padding={0.5}
          display="flex"
          marginY={data?.state === AllowedMatchingState.PENDING ? 1 : 0}
          alignItems="center"
          justifyContent="center"
        >
          {chooseIcon(state)}
        </Box>
        {data?.state === AllowedMatchingState.PENDING
          && (
            <Typography
              variant="body2"
              color={chooseColor(data?.state)}
              fontWeight="400"
              textAlign="center"
            >
              {chooseTitle(data?.state)}
            </Typography>
          )}
      </Box>
    </Box>
  );
}

export {
  PropertySummary,
};
