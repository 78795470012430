import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { MaxWidthContainer } from '../../../../utils/components/max-width-container';
import logoLsi from '../../../../assets/common/logo_lsi_blanc.svg';
import { ButtonToggle, Menu } from './menu';

function MobileHeader({
  menu,
  disabled,
  queryInfo,
  options,
  selectedValue,
  setSearchParams,
  logout,
  setSelectedValue,
  bottomShadow,
  openMenu,
  setOpenMenu,
  queryUser,
}: any) {
  const location = useLocation();
  const navigate = useNavigate();
  const firstArgsInUrl = location.pathname?.split('/')[1];

  return (
    <Box>
      <Box
        top={0}
        width="100%"
        height="70px"
        position="fixed"
        zIndex={19000}
        bgcolor="secondary.main"
        sx={{
          display: { xs: openMenu ? 'none' : 'inherit', md: 'none' },
          boxShadow: bottomShadow && !openMenu ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : 'none',
        }}
      >
        <MaxWidthContainer>
          <Box
            width="100%"
            height="70px"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexDirection="row"
          >
            <Box
              paddingLeft={3}
              width={{ xs: '25%', sm: '15%' }}
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              onClick={() => navigate('/')}
            >
              <Box
                component="img"
                sx={{
                  objectFit: 'cover',
                  width: '100%',
                }}
                src={logoLsi}
              />
            </Box>
            <Box width="20%" display="flex" alignItems="center" justifyContent="center">
              <ButtonToggle mode={openMenu} setMode={setOpenMenu} />
            </Box>
          </Box>
        </MaxWidthContainer>
      </Box>
      <Menu
        openMenu={openMenu}
        queryUser={queryUser}
        setOpenMenu={setOpenMenu}
        queryInfo={queryInfo}
        options={options}
        disabled={disabled}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        setSearchParams={setSearchParams}
        menu={menu}
        firstArgsInUrl={firstArgsInUrl}
        logout={logout}
      />
    </Box>
  );
}

export {
  MobileHeader,
};
