import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import { Link, useSearchParams } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { AllowedYousignStatus } from '../../../api';
import imgDemo from '../../../assets/projects/image-demo.jpg';

function PropertyContent({ data, queryInfoById }: any) {
  const [searchParams] = useSearchParams();
  const yousignStatus = queryInfoById?.data?.getProjectById?.config?.yousign_status;

  return (
    <Box display="flex" justifyContent="center" alignItems="center" width="100%" flexDirection="column">
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
      >
        <Box
          width="100%"
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row' }}
          marginTop={5}
          height={{ xs: 'auto', md: '280px' }}
        >
          <Box width={{ xs: '100%', sm: '50%' }} marginBottom={{ xs: 3, md: 'inherit' }} display="flex" flexDirection="column" justifyContent="space-between">
            <Typography
              marginBottom={{ xs: 3, md: 'inherit' }}
              textTransform="uppercase"
              fontWeight="bold"
              variant="h5"
              textAlign={{ xs: 'center', sm: 'inherit' }}
              letterSpacing={{ xs: 'inherit', sm: '0.1rem' }}
            >
              Votre proposition de bien
            </Typography>
            {(yousignStatus === AllowedYousignStatus?.FINISHED) && (
              <>
                <Typography fontWeight={500} marginY={1} textAlign={{ xs: 'center', sm: 'left' }}>
                  {data?.summary?.title}
                </Typography>
                <Typography marginBottom={1} width={{ xs: '100%', sm: '90%' }} variant="body2" textAlign={{ xs: 'center', sm: 'left' }}>
                  {data?.address}
                </Typography>
              </>
            )}
            <Box width="100%">
              {data?.dataCase?.map((e: any) => (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent={{ xs: 'center', sm: 'flex-start' }}
                  key={e?.title}
                  marginBottom={yousignStatus === AllowedYousignStatus?.FINISHED ? 1 : 2}
                >
                  <Typography marginRight={2} fontWeight={500}>{e.title}</Typography>
                  <Typography variant="h6" fontWeight={500}>{e?.value}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
          <Box
            width={{ xs: '100%', sm: '50%' }}
            display="flex"
            height="100%"
            justifyContent="center"
          >
            <Box
              bgcolor="divider"
              height="100%"
              width="100%"
              display="flex"
              borderRadius="10px"
            >
              {
                data?.image
                  ? (
                    <Box
                      component="img"
                      src={yousignStatus === AllowedYousignStatus?.FINISHED ? data?.image : imgDemo}
                      sx={{
                        filter: yousignStatus === AllowedYousignStatus?.FINISHED ? 'inherit' : 'blur(5px)',
                        objectFit: 'cover',
                        height: '100%',
                        width: '100%',
                      }}
                    />
                  ) : (
                    <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                      <NoPhotographyIcon />
                    </Box>
                  )
              }
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          marginTop={4}
          justifyContent="center"
        >
          {yousignStatus === AllowedYousignStatus?.FINISHED
            && (
              <Box
                component={Link}
                width="100%"
                sx={{
                  textDecoration: 'none',
                }}
                to={`property?projectId=${searchParams.get('projectId')}&matchingId=${data?.matchingId}`}
                onClick={() => {
                  // @ts-ignore
                  analytics.track('User Clicked On Call To Action', {
                    object: 'User',
                    action: 'Clicked On Call To Action',
                    click_url: '/project/property',
                    click_text: 'Voir le projet',
                    location: 'investor-project',
                    category: 'button',
                  });
                }}
              >
                <Button
                  variant="outlined"
                  fullWidth
                  endIcon={<ArrowForwardIosIcon />}
                  color="secondary"
                  sx={{
                    borderRadius: '5px',
                  }}
                >
                  <Typography
                    fontWeight={500}
                    variant="h6"
                    letterSpacing="0.1rem"
                  >
                    Voir le projet
                  </Typography>
                </Button>
              </Box>
            )}
        </Box>
      </Box>
    </Box>
  );
}

export {
  PropertyContent,
};
