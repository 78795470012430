import { format, isValid } from 'date-fns';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { scheduleText } from '../schedule.text';
import { MessageContainer } from '../../common/components/message-container';

function CalendlyTaken({ queryInfo }: any) {
  const displayStartTime = () => {
    const calendlyEvent = queryInfo?.data?.findUserLoggedIn?.calendlyEvents;
    const lastElem = calendlyEvent[calendlyEvent?.length - 1];

    if (isValid(new Date(lastElem?.start_time))) {
      return `${scheduleText.calendlyTaken.message1[0]} ${format(new Date(lastElem?.start_time), 'dd/MM/yyyy HH:mm')} ${scheduleText.calendlyTaken.message1[1]}`;
    }
    return scheduleText?.calendlyTaken?.messageWihoutDate;
  };

  return (
    <>
      <MessageContainer>
        <Typography
          letterSpacing={{ sx: 'inherit', md: '0.1rem' }}
          lineHeight="22px"
          fontWeight={500}
          color="text.primary"
          marginBottom={2}
        >
          {displayStartTime()}
        </Typography>
        <Typography
          letterSpacing={{ sx: 'inherit', md: '0.1rem' }}
          lineHeight="22px"
          fontWeight={500}
          color="text.primary"
          marginBottom={2}
        >
          {scheduleText.calendlyTaken.message2[0]}
        </Typography>
        <Typography
          letterSpacing={{ sx: 'inherit', md: '0.1rem' }}
          lineHeight="22px"
          fontWeight={500}
          color="text.primary"
        >
          {scheduleText.calendlyTaken.message2[1]}
          <Box
            component="a"
            marginLeft={1}
            color="text.primary"
            href="mailto:souheil@lessecretsdelimmo.fr"
            sx={{
              textDecoration: 'underline',
            }}
          >
            {scheduleText.calendlyTaken.mail}
          </Box>
        </Typography>
      </MessageContainer>
      <MessageContainer>
        <Typography
          letterSpacing={{ sx: 'inherit', md: '0.1rem' }}
          lineHeight="22px"
          fontWeight={500}
          color="text.primary"
        >
          {scheduleText.calendlyTaken.message3}
        </Typography>
      </MessageContainer>
      <MessageContainer>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
          {scheduleText?.pictures.map((e) => (
            <PictureContainer key={e?.text} img={e?.img} text={e?.text} link={e?.link} />
          ))}
        </Box>
      </MessageContainer>
    </>
  );
}

function PictureContainer({ img, text, link }: any) {
  return (
    <Box
      component="a"
      href={link}
      target="_blank"
      marginRight={{ xs: 'inherit', md: 3 }}
      marginBottom={{ xs: 3, md: 'inherit' }}
      borderRadius="5px"
      bgcolor="background.default"
      width={{ xs: '100%', md: '244px' }}
      height="276px"
      sx={{ textDecoration: 'none', color: 'inherit' }}
    >
      <Box
        component="img"
        borderRadius="5px 5px 0 0"
        src={img}
        width="100%"
        height="244px"
        sx={{ objectFit: 'cover' }}
      />
      <Box width="100%" display="flex" alignItems="center" justifyContent="center">
        <Typography fontWeight="700" letterSpacing="0.1rem">{text}</Typography>
      </Box>
    </Box>
  );
}

export {
  CalendlyTaken,
};
