import React, { useState } from 'react';
import { Box } from '@mui/material';
// @ts-ignore
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

function Carousel({ data }: any) {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const documentTypeImg = data?.top?.image;

  const images = documentTypeImg?.map((e: any) => ({
    original: e.url,
    thumbnail: e.url,
    mimeType: e?.mimeType || null,
    renderThumbInner: (item: any) => (
      <Box
        width="auto"
        height="70px"
        minHeight="70px"
        sx={{ cursor: 'pointer' }}
      >
        {item?.mimeType && item.mimeType.includes('video') ? (
          <Box
            component="video"
            height="70px"
            width="100%"
            muted
            controls={false}
            bgcolor="divider"
          >
            <source
              src={e.url}
              type={e.mimeType === 'video/quicktime' ? 'video/mp4' : e.mimeType}
            />
          </Box>
        ) : (
          <Box
            bgcolor="divider"
            component="img"
            width="100%"
            height="100%"
            sx={{ objectFit: 'contain' }}
            src={e.url}
            alt="property img"
          />
        )}
      </Box>
    ),
    renderItem: (item: any) => (
      <Box
        width="100%"
        height={isFullScreen ? { xs: '90dvh', sm: '90vh' } : { xs: '220px', sm: '330px' }}
        bgcolor="divider"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          overflowX: isFullScreen ? 'hidden' : 'inherit',
          zIndex: isFullScreen ? 200000 : 'inherit',
        }}
      >
        {item?.mimeType && item.mimeType.includes('video')
          ? (
            <Box
              component="video"
              controls
              height="100%"
              width="100%"
            >
              <source
                src={e.url}
                type={e.mimeType === 'video/quicktime' ? 'video/mp4' : e.mimeType}
              />
            </Box>
          ) : (
            <Box
              component="img"
              width="100%"
              height="100%"
              sx={{ objectFit: 'contain', display: 'block' }}
              src={e.url}
              alt="property img"
            />
          )}
      </Box>
    ),
  }));
  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'column-reverse', sm: 'row' }}
      sx={{
        overflowX: isFullScreen ? 'hidden' : 'inherit',
        zIndex: isFullScreen ? 200000 : 'inherit',
        bgcolor: isFullScreen ? 'black' : 'inherit',
        height: isFullScreen ? { xs: '100dvh', md: '100vh' } : 'inherit',
      }}
    >
      {data?.isSuccess && !data?.isLoading && documentTypeImg?.length > 0
        && (
          <Box
            width="100%"
            marginBottom={!isFullScreen ? { xs: 4, sm: 0 } : 0}
            sx={{
              '.image-gallery-left-nav': {
                padding: '0 !important',
                left: '10px',
                height: 'fit-content !important',
              },
              '.image-gallery-right-nav': {
                padding: '0 !important',
                right: '10px',
                height: 'fit-content !important',
              },
              '.image-gallery.fullscreen-modal': {
                zIndex: 200000,
              },
              '.image-gallery-fullscreen-button': {
                padding: '0 !important',
                top: '20px',
                right: '20px',
                height: 'fit-content !important',
              },
              '.image-gallery-left-nav .image-gallery-svg': {
                width: isFullScreen ? 'inherit' : '20px',
                height: isFullScreen ? '50px' : '-moz-fit-content !important',
              },
              '.image-gallery-right-nav .image-gallery-svg': {
                width: isFullScreen ? 'inherit' : '20px',
                height: isFullScreen ? '50px' : '-moz-fit-content !important',
              },
              '.image-gallery-fullscreen-button .image-gallery-svg': {
                width: isFullScreen ? 'inherit' : '20px',
                height: isFullScreen ? '30px' : '-moz-fit-content !important',
              },
            }}
          >
            <ImageGallery
              items={images}
              thumbnailPosition="bottom"
              showPlayButton={false}
              onScreenChange={() => setIsFullScreen(!isFullScreen)}
            />
          </Box>
        )}
    </Box>
  );
}

export {
  Carousel,
};
