import React, { useEffect } from 'react';
import {
  Route, Routes, useLocation, useSearchParams,
} from 'react-router-dom';
import {
  Box, Button, Container, LinearProgress, Typography,
} from '@mui/material';
import { PersonalInformation } from '../../form/pages/personalinformation';
import { CreateProject } from '../../form/pages/createproject';
import { ProfessionalSituation } from '../../form/pages/professionalsituation';
import { ProfessionalIncome } from '../../form/pages/professionaiIncome';
import { OtherIncome } from '../../form/pages/otherincome';
import { Saving } from '../../form/pages/saving';
import { Cost } from '../../form/pages/cost';
import { Taxes } from '../../form/pages/taxes';
import { Schedule } from '../../schedule/pages';
import { Layout } from '../../common/layout';
import { Project } from '../../project/pages';
import { Documents } from '../../documents/page';
import { Question } from '../../question/pages';
import { Contact } from '../../form/pages/contact';
import { ContactUs } from '../../question/pages/contactus';
import { useFindUserLoggedIn } from '../../hooks/query/find-user-logged-in-query.hook';
import { useUpdateUserFromTypeform } from '../../hooks/mutation/user-update-from-typeform.hook';
import { getStoredTypeform } from './typeform-recording';
import { Property } from '../../property/pages';
import { useGetProjectsByCurrentUser } from '../../hooks/query/get-projects-by-current-user-query.hook';
import { useGetProjectById } from '../../hooks/query/get-project-by-id-query.hook';
import { Form } from '../../form/pages/form';
import { Main } from '../../main/pages/main';
import { Referral } from '../../referral/page';
import logo from '../../assets/common/key-lsi-dark.svg';

function AuthenticatedApp({ logout }: any) {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const userQuery = useFindUserLoggedIn();
  const updateUserFromTypeform = useUpdateUserFromTypeform();
  const queryInfoForAvailableProjects = useGetProjectsByCurrentUser();
  const queryInfo = useGetProjectById(searchParams.get('projectId'));
  const firstArgsInUrl = location?.pathname?.split('/')[1];
  const isCreateProjectPage = location?.pathname?.split('/')[2] === 'create-project';

  useEffect(() => {
    const storedTypeform = getStoredTypeform();
    if (!storedTypeform) {
      return;
    }
    const shouldRecord = userQuery?.data?.findUserLoggedIn?.typeformInfo?.recorded === false;
    if (shouldRecord) {
      updateUserFromTypeform({ typeformInfo: storedTypeform });
    }
  }, [userQuery, updateUserFromTypeform]);

  return (
    <>
      {queryInfoForAvailableProjects.isSuccess
        ? (
          <Layout disabled={!queryInfo.data} disabledNav={firstArgsInUrl !== 'form' || isCreateProjectPage}>
            <Routes>
              <Route
                path=""
                element={<Main queryInfoForAvailableProjects={queryInfoForAvailableProjects} />}
              />
              <Route path="schedule" element={<Schedule />} />
              <Route path="project" element={<Project queryInfo={queryInfo} />} />
              <Route path="project">
                <Route path="property" element={<Property />} />
              </Route>
              <Route path="documents" element={<Documents />} />
              <Route path="question" element={<Question />} />
              <Route path="referral" element={<Referral userQuery={userQuery} />} />
              <Route path="contact-us" element={<ContactUs />} />
              <Route path="form" element={(<Form queryInfo={queryInfo} />)}>
                <Route path="create-project" element={<CreateProject />} />
                <Route path="personal-information" element={(<PersonalInformation queryInfo={queryInfo} />)} />
                <Route path="professional-situation" element={(<ProfessionalSituation queryInfo={queryInfo} />)} />
                <Route path="contact" element={(<Contact queryInfo={queryInfo} />)} />
                <Route path="professional-income" element={<ProfessionalIncome queryInfo={queryInfo} />} />
                <Route path="other-income" element={<OtherIncome queryInfo={queryInfo} />} />
                <Route path="saving" element={<Saving queryInfo={queryInfo} />} />
                <Route path="cost" element={<Cost queryInfo={queryInfo} />} />
                <Route path="taxes" element={<Taxes queryInfo={queryInfo} />} />
              </Route>
              <Route
                path="*"
                element={<Main queryInfo={queryInfo} queryInfoForAvailableProjects={queryInfoForAvailableProjects} />}
              />
            </Routes>
          </Layout>
        ) : (
          <IsNotSuccess
            queryInfoForAvailableProjects={queryInfoForAvailableProjects}
            logout={logout}
          />
        )}
    </>
  );
}

function IsNotSuccess({ queryInfoForAvailableProjects, logout }: any) {
  return (
    <Container
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '90vh',
        width: '100%',
      }}
    >
      <Box
        height="170px"
        width="fit-content"
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Box component="img" src={logo} width="100%" height="40px" sx={{ objectFit: 'contain' }} marginBottom={3} />
        <Typography
          textTransform="uppercase"
          variant="h6"
          fontWeight="300"
          letterSpacing="0.1rem"
        >
          Chargement ...
        </Typography>
        {!queryInfoForAvailableProjects?.error
          && (
            <Box width="100%">
              <LinearProgress sx={{ height: '2px' }} variant="indeterminate" color="secondary" />
            </Box>
          )}
        {queryInfoForAvailableProjects?.error && (
          <Typography variant="body1" fontWeight="600" paddingTop={5} color="error">
            {`erreur : ${queryInfoForAvailableProjects?.error}`}
          </Typography>
        )}
        <Button
          color="secondary"
          fullWidth
          sx={{
            marginTop: 3,
            letterSpacing: { xs: 'inherit', sm: '0.1rem' },
          }}
          onClick={() => logout()}
        >
          Se déconnecter
        </Button>
      </Box>
    </Container>
  );
}

export {
  AuthenticatedApp,
};
