import React from 'react';
import {
  Box, Button, Grid, Tooltip, Typography,
} from '@mui/material';
import TimerRoundedIcon from '@mui/icons-material/TimerRounded';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoIcon from '@mui/icons-material/Info';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import location from '../../assets/property/location.png';
import { LinkWithQuery } from '../../utils/components/link-with-query-params';
import { Carousel } from './carousel';
import { AllowedMatchingState } from '../../api';
import { PropertyLayout } from './display-property';

function PropertyTop({ data, color }: any) {
  const chooseIcon = (param: any) => {
    switch (param) {
      case AllowedMatchingState.PENDING: {
        return (<TimerRoundedIcon fontSize="small" sx={{ fill: (theme: any) => theme.palette.text.secondary }} />);
      }
      case AllowedMatchingState.ACCEPTED: {
        return (<DoneIcon fontSize="small" sx={{ fill: (theme: any) => theme.palette.text.secondary }} />);
      }
      case AllowedMatchingState.REFUSED: {
        return (<CloseIcon fontSize="small" sx={{ fill: (theme: any) => theme.palette.text.secondary }} />);
      }
      default: {
        return (<TimerRoundedIcon fontSize="small" sx={{ fill: (theme: any) => theme.palette.text.secondary }} />);
      }
    }
  };

  const chooseTitle = (param: any) => {
    switch (param) {
      case AllowedMatchingState.PENDING: {
        return 'Réponse attendue sous 24h';
      }
      case AllowedMatchingState.ACCEPTED: {
        return 'Accepté';
      }
      case AllowedMatchingState.REFUSED: {
        return 'Décliné';
      }
      default: {
        return data?.top?.urgency;
      }
    }
  };

  const chooseColor = (param: any) => {
    switch (param) {
      case AllowedMatchingState.PENDING: {
        return (theme: any) => theme?.palette?.warning.main;
      }
      case AllowedMatchingState.ACCEPTED: {
        return (theme: any) => theme?.palette?.success.main;
      }
      case AllowedMatchingState.REFUSED: {
        return (theme: any) => theme?.palette?.error.main;
      }
      default: {
        return data?.top?.urgencyColor;
      }
    }
  };

  return (
    <Box>
      <Box width="100%" display="flex" justifyContent="space-between" alignItems="center" paddingY={1}>
        <LinkWithQuery to="/project">
          <Tooltip title="Précédent">
            <Button sx={{ textTransform: 'none' }} startIcon={<ArrowBackIcon />}>Précédent</Button>
          </Tooltip>
        </LinkWithQuery>
      </Box>
      <PropertyLayout>
        <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" width="100%" marginBottom={4}>
          <Typography
            gutterBottom
            variant="h5"
            fontWeight="700"
            textAlign="center"
          >
            {data?.top?.title}
          </Typography>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box
              component="img"
              src={location}
              sx={{
                objectFit: 'contain',
                display: { xs: 'none', md: 'block' },
                width: '40px',
              }}
            />
            <Typography
              fontWeight="400"
              variant="h6"
              textAlign="center"
            >
              {data?.top?.address}
            </Typography>
          </Box>
          <Box
            display="flex"
            marginTop={1}
            alignItems="center"
            justifyContent="center"
          >
            <Box
              padding={0.5}
              marginRight={1}
              bgcolor={chooseColor(data?.state)}
              borderRadius={100}
              display={{ xs: 'none', md: 'flex' }}
              alignItems="center"
              justifyContent="center"
            >
              {chooseIcon(data?.state)}
            </Box>
            <Typography fontWeight={400} color={chooseColor(data?.state)} textAlign="center">
              {chooseTitle(data?.state)}
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          width="100%"
          justifyContent={{ xs: 'center', md: 'space-between' }}
          flexDirection={{ xs: 'column', md: 'row' }}
        >
          <Box
            width={{ xs: '100%', md: '45%' }}
            marginBottom={{ xs: 3, md: 0 }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              width={{ xs: '100%', md: '95%' }}
            >
              {
                data?.top?.image?.length > 0
                  ? (
                    <Carousel data={data} />
                  ) : (
                    <Box width="100%" height={{ xs: '220px', sm: '330px' }} bgcolor="divider" display="flex" alignItems="center" justifyContent="center">
                      <NoPhotographyIcon />
                    </Box>
                  )
              }
            </Box>
          </Box>
          <Box
            width={{ xs: '100%', md: '55%' }}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Box
              width={{ xs: '100%', md: '95%' }}
              display="flex"
              flexWrap="wrap"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Box height="100%" display="flex" flexDirection="column" justifyContent="center">
                <Typography height="10%" variant="h6">Synthèse</Typography>
                <Box height="90%" display="flex" flexDirection="column" justifyContent="space-around">
                  {data?.top?.dataCase?.map((e: any, i: any) => (
                    <Grid
                      container
                      columns={10}
                      key={e?.value + i.toString()}
                      display="flex"
                      justifyContent="space-around"
                      marginTop={2}
                      alignItems="center"
                    >
                      <Grid item md={1.5} xs={10} marginBottom={{ xs: 1, md: 0 }}>
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
                          <Typography marginRight={1} variant="body1" fontWeight="bold">{e?.title}</Typography>
                          <Tooltip title={e?.tooltip} sx={{ display: { xs: 'none', md: 'inherit' } }}>
                            <InfoIcon color="primary" fontSize="small" />
                          </Tooltip>
                          <Typography sx={{ display: { xs: 'inherit', md: 'none' } }} variant="caption">{`* ${e?.tooltip}`}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={10} md={3} marginBottom={{ xs: 1, md: 0 }}>
                        <Box
                          borderRadius={30}
                          bgcolor={color?.lightGold}
                          paddingX={2}
                          paddingY={0.5}
                          display="flex"
                          justifyContent="center"
                        >
                          <Typography variant="h6" fontWeight={400}>{e?.value}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={10} md={3} marginBottom={{ xs: 1, md: 0 }}>
                        <Box
                          borderRadius={30}
                          bgcolor={e?.secondValue ? color?.green : (theme) => theme.palette.text.secondary}
                          paddingX={2}
                          paddingY={0.5}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Typography variant="body2" fontWeight={500} textAlign="center">
                            {e?.secondValue}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </PropertyLayout>
    </Box>
  );
}

export {
  PropertyTop,
};
