import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import plumbing from '../../../assets/property/renovation/plomberie.png';
import plastering from '../../../assets/property/renovation/platrerie.png';
import walls from '../../../assets/property/renovation/walls.png';
import furnitureOnDemand from '../../../assets/property/renovation/furniture_on_demand.png';
import elevation from '../../../assets/property/renovation/elevation.png';
import electricity from '../../../assets/property/renovation/electricity.png';
import woodWork from '../../../assets/property/renovation/wood_work.png';
import floors from '../../../assets/property/renovation/floors.png';
import roofing from '../../../assets/property/renovation/toiture.png';
import facilities from '../../../assets/property/renovation/facilities.png';

function BuildingTrade({ data }: any) {
  const professionNeeded = data?.data?.getMatchingById?.property?.renovation?.professionNeeded;

  const formatTheData = [{
    key: '0',
    title: 'Plomberie',
    value: professionNeeded?.plumbing,
    icon: plumbing,
  }, {
    key: '1',
    title: 'Travaux d‘isolation',
    value: professionNeeded?.plastering,
    icon: plastering,
  }, {
    key: '2',
    title: 'Revêtements muraux',
    value: professionNeeded?.walls,
    icon: walls,
  }, {
    key: '3',
    title: 'Mobilier sur mesure',
    value: professionNeeded?.furnitureOnDemand,
    icon: furnitureOnDemand,
  }, {
    key: '4',
    title: 'Extension / surélévation',
    value: professionNeeded?.elevation,
    icon: elevation,
  }, {
    key: '5',
    title: 'Électricité',
    value: professionNeeded?.electricity,
    icon: electricity,
  }, {
    key: '6',
    title: 'Menuiseries extérieures',
    value: professionNeeded?.woodWork,
    icon: woodWork,
  }, {
    key: '7',
    title: 'Revêtements de sol',
    value: professionNeeded?.floors,
    icon: floors,
  }, {
    key: '8',
    title: 'Toiture',
    value: professionNeeded?.roofing,
    icon: roofing,
  }, {
    key: '9',
    title: 'Mobilier et équipements',
    value: professionNeeded?.facilities,
    icon: facilities,
  }];

  return (
    <Box
      width="100%"
      display="flex"
      marginTop={4}
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        container
        columns={{ xs: 2, md: 5 }}
      >
        {formatTheData.map((e) => (
          <Grid item xs={1}>
            <DisplayValue
              key={e?.value}
              title={e?.title}
              value={e?.value}
              icon={e?.icon}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

function DisplayValue({
  title, value, icon,
}: any) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginY={1}
      sx={{ opacity: value ? 1 : 0.3 }}
    >
      <Box marginBottom={1}>
        <Box
          component="img"
          src={icon}
          sx={{
            objectFit: 'contain', display: 'block', width: '100%', height: '60px',
          }}
        />
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography variant="body1" color="primary" textAlign="center">
          {title}
        </Typography>
      </Box>
    </Box>
  );
}

export {
  BuildingTrade,
};
