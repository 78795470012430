import React from 'react';
import { Box, Grid } from '@mui/material';
import { GridContainerFormInput, SectionTitle } from '../containerform';
import { AutocompleteCustom } from '../../../common/inputs/autocompletecustom';
import { customerSourceOptions } from '../../../data/data';
import { AllowedCustomerSource } from '../../../api';

function CustomerSource({
  businessProvidersOptions,
  queryInfoAllBusinessProviders,
  watch,
  control,
}: any) {
  return (
    <Box>
      <SectionTitle text="Où avez-vous entendu parler de nous ?" />
      <GridContainerFormInput>
        <Grid
          item
          // @ts-ignore
          xs={watch('customer_source')?.value === AllowedCustomerSource.BUSINESS_PROVIDER ? 1 : 2}
        >
          <AutocompleteCustom
            tab={customerSourceOptions}
            control={control}
            name="customer_source"
            label="Facebook, Google, ect..."
          />
        </Grid>
        {
          // @ts-ignore
          watch('customer_source')?.value === AllowedCustomerSource.BUSINESS_PROVIDER
          && queryInfoAllBusinessProviders.isSuccess
          && queryInfoAllBusinessProviders?.data?.findAllBusinessProviders?.length > 0
          && (
            <Grid item xs={1}>
              <AutocompleteCustom
                tab={queryInfoAllBusinessProviders.isLoading ? [] : businessProvidersOptions}
                control={control}
                name="business_provider"
                label="Sélectionner A.F."
              />
            </Grid>
          )
        }
      </GridContainerFormInput>
    </Box>
  );
}

export {
  CustomerSource,
};
