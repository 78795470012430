import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { PersonalInformationContent } from '../components/personal-information/personalinformationcontent';

function PersonalInformation({ queryInfo }: any) {
  const {
    // @ts-ignore
    item, index, indexAccordion, setIndexAccordion, indexMax,
  } = useOutletContext();
  return (
    <PersonalInformationContent
      key={item?._id}
      data={item}
      index={index}
      queryInfo={queryInfo}
      indexMax={indexMax}
      to="/form/professional-situation"
      indexAccordion={indexAccordion}
      setIndexAccordion={setIndexAccordion}
    />
  );
}

export {
  PersonalInformation,
};
