import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Box, Grid, styled, TextField, Typography,
} from '@mui/material';

function TextFieldCustom({
  control, name, label, type = 'text', rules = null, size = 'medium', variant = 'standard',
}: any) {
  return (
    <Grid item height={80}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({
          field, fieldState,
        }) => {
          return (
            <Box>
              <CssTextField
                {...field}
                type={type}
                label={label}
                placeholder="Écrire ici ..."
                size={size}
                value={type === 'number' ? field.value?.toString() || '' : field.value || ''}
                onChange={(e) => field.onChange(e.target.value)}
                onBlur={field.onBlur}
                inputProps={{
                  min: 0,
                  max: 10,
                }}
                fullWidth
                color="secondary"
                variant={variant}
              />
              {fieldState?.error?.message
              && <Typography variant="caption" fontWeight="bold" color="error">{fieldState.error.message}</Typography>}
            </Box>
          );
        }}
      />
    </Grid>
  );
}

const CssTextField = styled(TextField)(({ theme }) => ({
  label: {
    fontWeight: '400',
    color: theme.palette.text.primary,
  },
}));

export {
  TextFieldCustom,
};
