import React from 'react';
import {
  Box, Typography,
} from '@mui/material';
import { PropertyLayout } from '../display-property';
import { Floors } from './floors';
import { Facilities } from './facilities';
import { FutureLayout } from './future-layout';
import { ActractivePoint } from './actractive-point';

function GeneralInformations({ data, color }: any) {
  return (
    <PropertyLayout>
      <Typography marginBottom={4} variant="h5" fontWeight="600">Informations générales</Typography>
      <Typography
        variant="h6"
        fontWeight="400"
        color="text.primary"
        gutterBottom
        sx={{
          textAlign: { xs: 'center', sm: 'left' },
        }}
      >
        {data?.generalInformations?.propertyTitle}
      </Typography>
      <Typography
        fontWeight="400"
        gutterBottom
        sx={{
          textAlign: { sm: 'left', xs: 'center' },
          marginBottom: { xs: 3, sm: 0 },
        }}
      >
        {data?.top?.address}
      </Typography>
      <ActractivePoint data={data} />
      <Floors data={data} />
      <Facilities data={data} />
      <FutureLayout data={data} color={color} />
    </PropertyLayout>
  );
}

function DisplayValue({
  title, value, icon, column = false,
}: any) {
  const chooseValue = (param: any) => {
    if (param === true) {
      return '✓';
    }
    return param;
  };

  return (
    <Box
      display={value === 0 || value === false ? 'none' : 'flex'}
      flexDirection="column"
      alignItems="center"
      marginY={1}
      sx={{
        width: { xs: '100px', sm: '150px' },
      }}
    >
      <Box>
        <Box
          component="img"
          src={icon}
          sx={{
            objectFit: 'cover',
            display: 'block',
            width: { xs: '40px', sm: '50px' },
            height: '100%',
          }}
        />
      </Box>

      <Box display="flex" flexDirection={column ? 'column' : 'row'} alignItems="center">
        <Typography marginRight={1}>{title}</Typography>
        <Typography color="primary" variant={column ? 'body2' : 'body1'}>{chooseValue(value)}</Typography>
      </Box>
    </Box>
  );
}

export {
  DisplayValue,
  GeneralInformations,
};
