import { Box, Button, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SectionTitle } from '../containerform';
import { CurrencyTextFieldCustom } from '../../../common/inputs/currencytextfield';
import { CalendarCustom } from '../../../common/inputs/calendarcustom';

function FieldsArrayConsumerLoan() {
  const {
    control,
  } = useFormContext<any>();

  const { fields, append, remove } = useFieldArray<any>({
    control,
    name: 'consumer_loan',
  });

  const defaultValue = {
    monthly_amount: 0,
    loan_amount: 0,
    remaining_loan: 0,
    end_date: null,
  };

  return (
    <Box>
      <SectionTitle text="Prêt à la consommation" />
      {fields.map((item, indexField) => (
        <Box
          key={item?.id}
          display="flex"
          flexWrap="wrap"
          sx={{
            marginBottom: {
              xs: 3,
              md: 0,
            },
          }}
        >
          <Box
            sx={{ width: { xs: '100%', md: 200 }, marginRight: { xs: 0, md: 2 } }}
          >
            <CurrencyTextFieldCustom
              size="small"
              variant="outlined"
              control={control}
              name={`consumer_loan[${indexField}].monthly_amount`}
              label="Mensualité"
            />
          </Box>
          <Box
            sx={{ width: { xs: '100%', md: 200 }, marginRight: { xs: 0, md: 2 } }}
          >
            <CurrencyTextFieldCustom
              control={control}
              size="small"
              variant="outlined"
              name={`consumer_loan[${indexField}].loan_amount`}
              label="Capital emprunté"
            />
          </Box>
          <Box
            sx={{ width: { xs: '100%', md: 200 }, marginRight: { xs: 0, md: 2 } }}
          >
            <CurrencyTextFieldCustom
              control={control}
              size="small"
              variant="outlined"
              name={`consumer_loan[${indexField}].remaining_loan`}
              label="Capital restant dû"
            />
          </Box>
          <Box
            sx={{ width: { xs: '100%', md: 200 }, marginRight: { xs: 0, md: 2 } }}
          >
            <CalendarCustom
              control={control}
              size="small"
              variant="outlined"
              name={`consumer_loan[${indexField}].end_date`}
              label="Date de fin"
            />
          </Box>
          <Button
            sx={{
              padding: 0,
              minWidth: 0,
              marginTop: -3,
              width: '100%',
              height: 40,
              color: 'text.secondary',
              display: {
                xs: 'inherit',
                md: 'none',
              },
            }}
            variant="contained"
            color="primary"
            onClick={() => remove(indexField)}
          >
            <ClearIcon />
          </Button>
          <IconButton
            sx={{
              height: 40,
              width: 40,
              display: {
                xs: 'none',
                md: 'inherit',
              },
            }}
            onClick={() => remove(indexField)}
            color="error"
          >
            <RemoveCircleIcon />
          </IconButton>
        </Box>
      ))}
      <Box marginBottom={3} display="flex">
        <Button
          type="button"
          sx={{
            cursor: 'pointer',
            textTransform: 'none',
            textDecoration: 'underline',
            transition: 'all 0.3s',
            fontSize: '16px',
            color: (theme) => theme.palette.primary.main,
            width: 'fit-content',
            ':hover': {
              opacity: 0.8,
            },
          }}
          startIcon={<AddCircleIcon />}
          onClick={() => append(defaultValue)}
        >
          Ajouter une ligne
        </Button>
      </Box>
    </Box>
  );
}

export {
  FieldsArrayConsumerLoan,
};
