import { useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { AllowedOffer } from '../../api';

function Main({ queryInfoForAvailableProjects }: any) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const availableProjects = queryInfoForAvailableProjects?.data?.getProjectsByCurrentUser || [];
  const hasProjectIdInUrl = searchParams.get('projectId');

  const getCurrentProject = (projects: any) => {
    if (!projects || projects.length === 0) {
      return [];
    }
    return projects[projects.length - 1];
  };

  const currentProject = getCurrentProject(availableProjects);

  const checkIfLastProjectIsCompleted = (projects: any) => {
    if (projects.length === 0) return false;
    const { offer = '' } = getCurrentProject(projects);
    return offer === AllowedOffer.ENVOL || offer === AllowedOffer.ETINCELLE || offer === AllowedOffer.ETOILE;
  };

  const hasCompletedHisProject = checkIfLastProjectIsCompleted(queryInfoForAvailableProjects?.data?.getProjectsByCurrentUser);

  useEffect(() => {
    if (!availableProjects || availableProjects.length === 0) {
      navigate('/schedule');
    } else if (availableProjects && availableProjects.length > 0 && !hasCompletedHisProject && currentProject) {
      navigate(`/form/professional-situation?projectId=${currentProject._id}`);
    } else if (hasCompletedHisProject && !hasProjectIdInUrl) {
      navigate(`/project?projectId=${currentProject._id}`);
    } else {
      navigate('/schedule');
    }
  }, [availableProjects, currentProject, hasCompletedHisProject, hasProjectIdInUrl, navigate]);

  return (
    <Box />
  );
}

export {
  Main,
};
