import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';

function BottomSection() {
  const { search } = useLocation();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      paddingX={2}
      paddingY={5}
    >
      <Typography
        variant="body2"
        fontWeight="700"
        textAlign="center"
        letterSpacing={{ xs: 'inherit', sm: '0.1rem' }}
        lineHeight={2}
      >
        Une question supplémentaire ?
        <br />
        {' '}
        N’hésitez pas à demander à être rappelé par nos experts.
      </Typography>
      <Box width="fit-content">
        <Box
          component={Link}
          to={`/contact-us${search}`}
          sx={{
            textDecoration: 'none',
          }}
        >
          <Typography
            variant="body2"
            display="flex"
            lineHeight={4}
            alignItems="center"
            color="text.primary"
            sx={{
              fontWeight: 700,
              letterSpacing: '0.1rem',
              textDecoration: 'underline',

            }}
          >
            Prendre un rendez-vous
            <PhoneIcon sx={{ marginLeft: 1.5 }} fontSize="small" />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export {
  BottomSection,
};
