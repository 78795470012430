import {
  Box, Button, Typography,
} from '@mui/material';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useCreateContractMutation } from '../../../../../../api';
import { useGetAccessToken } from '../../../../../../hooks/auth/get-access-token.hook';

function ModeleUnsigned({ queryInfoById }: any) {
  const { headerWithAuth } = useGetAccessToken();
  const [searchParams] = useSearchParams();
  const check = queryInfoById?.data?.getProjectById?.customers.some((e: any) => (!!e?.personal_info?.email && (!!e.personal_info?.street_address?.address_line_1 || e.personal_info?.street_address?.address_line_1 === '') && !!e.personal_info?.phone));
  const { enqueueSnackbar } = useSnackbar();

  const { mutate, isLoading } = useCreateContractMutation(headerWithAuth, {
    onSuccess: () => {
      queryInfoById.refetch();
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const handleClick = () => {
    const projectId = searchParams.get('projectId');
    // @ts-ignore
    mutate({ projectId });
    const userId = localStorage.getItem('userId') || null;
    // @ts-ignore
    analytics.track({
      event: 'Contract Sent',
      userId,
      properties: {
        object: 'User',
        action: 'Contract Sent',
        project_id: projectId,
      },
    });
  };

  return (
    <Box width="100%" display="flex" justifyContent="center" marginTop={5}>
      <Box>
        <Typography
          textAlign={{ xs: 'justify', sm: 'center' }}
          fontWeight={500}
          paddingBottom={1}
        >
          Nous avons trouvé le bien parfait pour développer votre patrimoine immobilier. Afin de vous faciliter la tâche, nos chasseurs ont déjà négocié le prix du bien et nos architectes ont estimé le prix des travaux.
          <br />
          <br />
          Pour accéder au projet, veuillez signer en ligne notre mandat. Il vous engage uniquement à payer nos frais si vous décidez de travailler avec nous.
        </Typography>
        <Box
          width="100%"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          display="flex"
          paddingTop={4}
        >
          {isLoading
            ? (
              <Typography
                fontWeight={600}
                letterSpacing={{ xs: 'inherit', sm: '0.1rem' }}
                textAlign="center"
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="40px"
              >
                Nous préparons votre contrat ...
              </Typography>
            ) : (
              <Button
                color="secondary"
                variant="contained"
                disabled={!check}
                onClick={handleClick}
                sx={{
                  letterSpacing: { xs: 'inherit', sm: '0.1rem' },
                }}
              >
                Découvrir mon Projet
              </Button>
            )}
          {!check
            && (
              <Typography
                textAlign="center"
                fontWeight="bold"
                color="error"
                variant="body2"
                sx={{
                  marginTop: 2,
                  width: { xs: '90%', sm: '45%' },
                }}
              >
                Veuillez renseigner vos informations personnelles manquantes afin d‘accéder au projet (email, téléphone, adresse, etc.)
              </Typography>
            )}
        </Box>
      </Box>
    </Box>
  );
}

export {
  ModeleUnsigned,
};
