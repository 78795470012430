import React from 'react';
import { alpha, Box, Typography } from '@mui/material';
import keyLsi from '../../assets/common/key-lsi-dark.svg';

function Summary({ item, iconLabel = false }: any) {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      paddingY={0}
      width="100%"
    >
      <Box display="flex" alignItems={{ xs: 'flex-start', sm: 'center' }} flexDirection={{ xs: 'column', sm: 'row' }}>
        <Typography
          variant="body2"
          fontWeight="700"
          letterSpacing="0.1rem"
        >
          {item.label}
        </Typography>
        <Typography
          bgcolor={(theme) => alpha(theme.palette.secondary.main, 0.15)}
          marginLeft={{ xs: 0, sm: 1 }}
          fontWeight="700"
          letterSpacing="0.1rem"
          paddingY={{ xs: 0.5, sm: 1 }}
          paddingX={1.5}
          marginTop={{ xs: 1, sm: 0 }}
          borderRadius="10px"
          display="flex"
          alignItems="center"
        >
          {iconLabel && (
          <Box
            component="img"
            height="15px"
            src={keyLsi}
            sx={{
              objectFit: 'contain',
              marginRight: 2,
            }}
          />
          )}
          {item.value}
        </Typography>
      </Box>
      <Box
        justifyContent="flex-end"
        alignItems="center"
        sx={{
          display: { xs: 'none', sm: 'flex' },
        }}
      >
        <Typography
          variant="body2"
          fontWeight="700"
          letterSpacing="0.1rem"
        >
          {item.rightText}
        </Typography>
      </Box>
    </Box>
  );
}

export {
  Summary,
};
