import { useLocation } from 'react-router-dom';
import { LOCAL_STORAGE_KEY } from '../components/typeform-recording';

const useTypeformRecorder = () => {
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);

  const employeeIncome = searchParams.get('employee_income') || undefined;
  const employeesIncomes = searchParams.get('employees_incomes') || undefined;
  const freelanceIncome = searchParams.get('freelance_income') || undefined;
  const freelanceIncomes = searchParams.get('freelance_incomes') || undefined;
  const offer = searchParams.get('offre') || undefined;
  const groupSavings = searchParams.get('group_savings') || undefined;
  const savings = searchParams.get('savings') || undefined;
  const typeform_result = searchParams.get('typeform_result') || undefined;
  const budget = searchParams.get('budget') || undefined;
  const utmSource = searchParams.get('utm_source') || undefined;
  const referralCode = searchParams.get('referral_code') || undefined;
  const investor = searchParams.get('investor') || undefined;
  const employment = searchParams.get('employment') || undefined;
  const timing = searchParams.get('timing') || undefined;

  const params = {
    employeeIncome,
    employeesIncomes,
    freelanceIncome,
    freelanceIncomes,
    offer,
    groupSavings,
    savings,
    typeform_result,
    budget,
    utmSource,
    referralCode,
    investor,
    employment,
    timing,
  };

  const recordParamsToLocalStorage = () => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(params));
  };

  return recordParamsToLocalStorage;
};

export {
  useTypeformRecorder,
};
