import {
  Box, FormControl, FormControlLabel, Radio, RadioGroup, RadioProps, Typography,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import React from 'react';
import { SectionTitle } from '../../form/components/containerform';

function RadioCustom({
  title, name, tab, control, xsSize = 100, mdSize = 100, display,
}: any) {
  return (
    <Box paddingBottom={3} display={display ? 'inherit' : 'none'}>
      <SectionTitle text={title} />
      <Box
        width="100%"
        display="flex"
        sx={{
          justifyContent: {
            xs: 'center',
            md: 'flex-start',
          },
        }}
      >
        <FormControl>
          <Controller
            name={name}
            control={control}
            render={({ field }) => {
              return (
                <RadioGroup
                  {...field}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: {
                      xs: 'center',
                      md: 'flex-start',
                    },
                    width: '100%',
                  }}
                >
                  {
                    tab.map((e: any, i: number) => {
                      return (
                        <BpRadioCustom
                          key={e?.value + i.toString()}
                          xsSize={xsSize}
                          mdSize={mdSize}
                          label={e.label}
                          value={e.value}
                          renderIcon={e.renderIcon}
                        />
                      );
                    })
                  }
                </RadioGroup>
              );
            }}
          />
        </FormControl>
      </Box>
    </Box>
  );
}

function BpRadioCustom({
  label, value, renderIcon: Icon, xsSize, mdSize,
}: any, props: RadioProps) {
  return (
    <FormControlLabel
      value={value}
      sx={{
        color: 'text.primary',
        fontWeight: 'bold',
      }}
      labelPlacement="bottom"
      control={(
        <Radio
          sx={{
            color: 'secondary.main',
            '&:hover': {
              bgcolor: 'transparent',
            },
          }}
          disableRipple
          icon={<RadioUncheck xsSize={xsSize} mdSize={mdSize} label={label} renderIcon={() => <Icon />} />}
          checkedIcon={<RadioCheck xsSize={xsSize} mdSize={mdSize} label={label} renderIcon={() => <Icon />} />}
          {...props}
        />
      )}
      label=""
    />
  );
}

function RadioUncheck({
  label, renderIcon: Icon, xsSize, mdSize,
}: any) {
  return (
    <Box
      sx={{
        width: {
          xs: xsSize,
          md: mdSize,
        },
        height: 80,
        borderRadius: '10px',
        transition: 'all 0.3s',
        flexDirection: 'column',
        boxShadow: 'none',
        border: '1px solid',
        borderColor: 'secondary.main',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        'input:hover ~ &': {
          transform: 'scale(1.03)',
        },
        'input:disabled ~ &': {
          display: 'none',
        },
      }}
    >
      <Icon />
      <Typography sx={{ color: (theme) => theme.palette.text.primary }} variant="caption" textAlign="center" fontWeight="400">{label}</Typography>
    </Box>
  );
}

function RadioCheck({
  label, renderIcon: Icon, xsSize, mdSize,
}: any) {
  return (
    <Box
      sx={{
        width: {
          xs: xsSize,
          md: mdSize,
        },
        height: 80,
        flexDirection: 'column',
        transition: 'all 0.3s',
        borderRadius: '10px',
        boxShadow: 'none',
        border: '1px solid',
        borderColor: 'primary.main',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        'input:hover ~ &': {
          transform: 'scale(1.03)',
        },
        'input:disabled ~ &': {
          display: 'none',
        },
      }}
    >
      <Icon />
      <Typography variant="caption" textAlign="center" fontWeight="bold">{label}</Typography>
    </Box>
  );
}

export {
  RadioCustom,
};
