import {
  alpha, Box, Chip, Divider, Grid, Slider, Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import apport_white from '../../../assets/property/apport_white.png';
import duree_white from '../../../assets/property/duree_white.png';
import liasse from '../../../assets/property/liasse.png';
import icon_1 from '../../../assets/property/icon_1.png';
import icon_2 from '../../../assets/property/icon_2.png';
import icon_3 from '../../../assets/property/icon_3.png';
import icon_4 from '../../../assets/property/icon_4.png';
import icon_5 from '../../../assets/property/icon_5.png';
import icon_7 from '../../../assets/property/icon_7.png';
import liasse2 from '../../../assets/property/liasse2.png';
import { euro } from '../../../common/inputs/format/euro.format';
import { RichLineGraphNextTenYears } from './chart-global-enrichment';
import { LineGraphNextTenYears } from './cashflow-next-ten-years.table';

function Funding({ data, color, getGlobalConfig }: any) {
  const [firstSliderValue, setFirstSliderValue] = useState<any>(15);
  const dataFinance = data && data?.data?.getMatchingById?.property?.finance;
  const totalBudget = data && data?.data?.getMatchingById?.property?.finance?.total_budget;

  const [years, setYears] = useState<any>(getGlobalConfig?.data?.number_of_years_loan);
  const [loanDelay, setLoanDelay] = useState<any>(0);
  const [interestRateTmp, setInterestRateTmp] = useState<any>(getGlobalConfig?.data?.interest_rate * 100);
  const [monthlyLoanBudgetTmp, setMonthlyLoanBudgetTmp] = useState<any>(dataFinance && dataFinance?.property_cash_flow?.monthly_loan_amount ? dataFinance?.property_cash_flow?.monthly_loan_amount : 0);
  const [equityContributionTmp, setEquityContributionTmp] = useState<any>(dataFinance && totalBudget ? 15 * totalBudget / 100 : 0);
  const [monthlyOutcomeTmp, setMonthlyOutcomeTmp] = useState<any>(dataFinance && dataFinance?.property_cash_flow?.monthly_outcome ? dataFinance?.property_cash_flow?.monthly_outcome : 0);
  const [loanBudgetTmp, setLoanBudgetTmp] = useState<any>(totalBudget && equityContributionTmp ? totalBudget - equityContributionTmp : 0);
  const [budgetWithdelay, setBudgetWithDelay] = useState<any>(null);

  useEffect(() => {
    setEquityContributionTmp(firstSliderValue * totalBudget / 100);
  }, [firstSliderValue, totalBudget]);

  useEffect(() => {
    let monthlyOutcome = (monthlyLoanBudgetTmp + dataFinance?.property_cash_flow?.monthly_real_estate_agency_fees + dataFinance?.property_cash_flow?.monthly_insurance_fees + dataFinance?.property_cash_flow?.monthly_property_tax + dataFinance?.property_cash_flow?.monthly_landlord_fees);
    if (budgetWithdelay && loanDelay > 0) {
      monthlyOutcome = (budgetWithdelay + dataFinance?.property_cash_flow?.monthly_real_estate_agency_fees + dataFinance?.property_cash_flow?.monthly_insurance_fees + dataFinance?.property_cash_flow?.monthly_property_tax + dataFinance?.property_cash_flow?.monthly_landlord_fees);
    }
    setMonthlyOutcomeTmp(monthlyOutcome);
  }, [dataFinance, monthlyLoanBudgetTmp, budgetWithdelay, loanDelay]);

  useEffect(() => {
    setLoanBudgetTmp(totalBudget - equityContributionTmp);
    if (years === getGlobalConfig?.data?.number_of_years_loan_15 && getGlobalConfig?.data?.number_of_years_loan_15 === (interestRateTmp / 100)) {
      setInterestRateTmp(getGlobalConfig?.data?.interest_rate_15 * 100);
    }
    if (years === getGlobalConfig?.data?.number_of_years_loan_20 && getGlobalConfig?.data?.number_of_years_loan_20 === (interestRateTmp / 100)) {
      setInterestRateTmp(getGlobalConfig?.data?.interest_rate_20 * 100);
    }
    if (years === getGlobalConfig?.data?.number_of_years_loan && getGlobalConfig?.data?.number_of_years_loan === (interestRateTmp / 100)) {
      setInterestRateTmp(getGlobalConfig?.data?.interest_rate * 100);
    }
    setMonthlyLoanBudgetTmp(((interestRateTmp / 100) * loanBudgetTmp) / ((1 - (1 + (interestRateTmp / 100) / 12) ** (-12 * years)) * 12));
    if (loanDelay > 0) {
      const defaultMonthlyLoan = ((totalBudget - equityContributionTmp) * (interestRateTmp / 100)) / 12;
      setBudgetWithDelay(defaultMonthlyLoan);
    } else {
      setBudgetWithDelay(null);
    }
  }, [equityContributionTmp, interestRateTmp, loanBudgetTmp, totalBudget, years, loanDelay]);

  console.log({ interestRateTmp });
  return (
    <Box>
      <Divider sx={{ marginY: 5 }} />
      <Typography
        marginTop={4}
        variant="h6"
        gutterBottom
        color="text.primary"
        fontWeight="400"
        sx={{
          textAlign: { xs: 'center', md: 'left' },
        }}
      >
        Financement
      </Typography>
      <Typography
        fontWeight="200"
        gutterBottom
        sx={{
          textAlign: { xs: 'center', md: 'left' },
        }}
        mt={-1}
      >
        Ajustez l’apport, la durée du prêt et le taux d’intérêt pour faire varier la trésorerie
      </Typography>
      <Grid container spacing={12} rowSpacing={8}>
        <Grid item xs={12} sm={6}>
          <SliderComponent
            title="Apport"
            titleValue={euro.format(equityContributionTmp)}
            sliderValue={firstSliderValue}
            setSliderValue={setFirstSliderValue}
            icon={apport_white}
            min={0}
            max={100}
            step={1}
            displayValue
          />
          <SliderComponent
            title="Taux d'intérêt"
            titleValue={interestRateTmp ? `${(interestRateTmp).toFixed(2)}%` : `${0}%`}
            sliderValue={interestRateTmp}
            setSliderValue={setInterestRateTmp}
            icon={apport_white}
            min={0}
            max={6}
            step={0.1}
            displayValue
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SliderComponent
            title="Durée"
            titleValue={`${years} ans`}
            sliderValue={years}
            setSliderValue={setYears}
            icon={duree_white}
            min={15}
            max={25}
            step={5}
          />
          {/* <SliderComponent
            title="Différé de remboursement"
            titleValue={`${loanDelay} mois`}
            sliderValue={loanDelay}
            setSliderValue={setLoanDelay}
            icon={duree_white}
            valueLabelDisplay="auto"
            step={12}
            max={36}
            min={0}
      /> */}
        </Grid>
      </Grid>
      <Divider sx={{ marginY: 5 }} />
      <Typography
        marginTop={4}
        variant="h6"
        gutterBottom
        color="text.primary"
        fontWeight="400"
        sx={{
          textAlign: { xs: 'center', md: 'left' },
        }}
      >
        Trésorerie sur la première année
      </Typography>
      <Typography
        fontWeight="200"
        gutterBottom
        mt={-1}
        sx={{
          textAlign: { xs: 'center', md: 'left' },
        }}
      >
        Encaissements et décaissements mensuels
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          sx={{
            width: { xs: '100%', md: '50%' },
          }}
        >
          <LittleCircle
            icon={liasse}
            color={color?.green}
            value={data?.data?.getMatchingById?.property?.finance?.property_cash_flow?.monthly_income ? euro.format(data?.data?.getMatchingById?.property?.finance?.property_cash_flow?.monthly_income) : `${0} €`}
            colorValue={color?.darkGreen}
          />
          <Bar
            title="Loyer"
            value={data?.data?.getMatchingById?.property?.finance?.property_cash_flow?.monthly_income ? euro.format(data?.data?.getMatchingById?.property?.finance?.property_cash_flow?.monthly_income) : `${0} €`}
            color={color?.darkGreen}
            icon={icon_5}
          />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          sx={{
            width: { xs: '100%', md: '50%' },
          }}
        >
          <LittleCircle
            color={color?.pink}
            icon={liasse2}
            value={monthlyOutcomeTmp ? euro.format(monthlyOutcomeTmp) : `${0} €`}
            colorValue={color?.pink}
          />
          <Bar
            title="Mensualité emprunt"
            value={monthlyLoanBudgetTmp ? budgetWithdelay ? euro.format(budgetWithdelay) : euro.format(monthlyLoanBudgetTmp) : `${0} €`}
            color={color?.pink}
            icon={icon_2}
          />
          <Bar
            title="Taxe foncière mensualisée"
            value={data?.data?.getMatchingById?.property?.finance?.property_cash_flow?.monthly_property_tax ? euro.format(data?.data?.getMatchingById?.property?.finance?.property_cash_flow?.monthly_property_tax) : `${0} €`}
            color={color?.pink}
            icon={icon_1}
          />
          <Bar
            title="Charges de copropriété"
            value={data?.data?.getMatchingById?.property?.finance?.property_cash_flow?.monthly_landlord_fees ? euro.format(data?.data?.getMatchingById?.property?.finance?.property_cash_flow?.monthly_landlord_fees) : `${0} €`}
            color={color?.pink}
            icon={icon_7}
          />
          <Bar
            title="Assurances"
            value={data?.data?.getMatchingById?.property?.finance?.property_cash_flow?.monthly_insurance_fees ? euro.format(data?.data?.getMatchingById?.property?.finance?.property_cash_flow?.monthly_insurance_fees) : `${0} €`}
            color={color?.pink}
            icon={icon_3}
          />
          <Bar
            title="Gestion locative"
            value={data?.data?.getMatchingById?.property?.finance?.property_cash_flow?.monthly_real_estate_agency_fees ? euro.format(data?.data?.getMatchingById?.property?.finance?.property_cash_flow?.monthly_real_estate_agency_fees) : `${0} €`}
            color={color?.pink}
            icon={icon_4}
          />
        </Box>
      </Box>
      <Divider sx={{ marginY: 5 }} />
      <Typography
        marginTop={4}
        variant="h6"
        gutterBottom
        color="text.primary"
        fontWeight="400"
        sx={{
          textAlign: { xs: 'center', md: 'left' },
        }}
      >
        Trésorerie sur 10 ans
      </Typography>
      <Typography
        fontWeight="200"
        mt={-1}
        gutterBottom
        sx={{
          textAlign: { xs: 'center', md: 'left' },
        }}
      >
        Cashflow mensuel prévisionnel sur les 10 prochaines années
      </Typography>
      {data?.data?.getMatchingById?.createdAt && dataFinance?.property_cash_flow?.monthly_loan_amount ? (
        <LineGraphNextTenYears
          year={data?.data?.getMatchingById.createdAt}
          income={data?.data?.getMatchingById?.property?.finance?.property_cash_flow?.monthly_income}
          outcome={monthlyOutcomeTmp}
          interestRate={interestRateTmp}
          equityContribution={equityContributionTmp}
          totalBudget={totalBudget}
          outcomeValues={{
            monthlyLoanBudgetTmp,
            monthly_real_estate_agency_fees: dataFinance?.property_cash_flow?.monthly_real_estate_agency_fees,
            monthly_insurance_fees: dataFinance?.property_cash_flow?.monthly_insurance_fees,
            monthly_property_tax: dataFinance?.property_cash_flow?.monthly_property_tax,
            monthly_landlord_fees: dataFinance?.property_cash_flow?.monthly_landlord_fees,
          }}
          monthlyLoanBudget={monthlyLoanBudgetTmp}
          loanDelay={loanDelay}
          inflationValue={data?.data?.getMatchingById?.property?.finance?.inflation}
        />
      ) : null}
      <Divider sx={{ marginY: 5 }} />
      <Typography
        marginTop={4}
        variant="h6"
        gutterBottom
        color="text.primary"
        fontWeight="400"
        sx={{
          textAlign: { xs: 'center', md: 'left' },
        }}
      >
        Enrichissement global
      </Typography>
      <Typography
        fontWeight="200"
        mt={-1}
        gutterBottom
        sx={{
          textAlign: { xs: 'center', md: 'left' },
        }}
      >
        Votre enrichissement total, prenant en compte capitalisation, trésorerie et plus value, en cas de revente sur
        une année donnée.
      </Typography>
      <RichLineGraphNextTenYears
        year={data?.data?.getMatchingById.createdAt}
        totalBudget={totalBudget}
        income={data?.data?.getMatchingById?.property?.finance?.property_cash_flow?.monthly_income}
        outcome={(monthlyLoanBudgetTmp + dataFinance?.property_cash_flow?.monthly_real_estate_agency_fees + dataFinance?.property_cash_flow?.monthly_insurance_fees + dataFinance?.property_cash_flow?.monthly_property_tax + dataFinance?.property_cash_flow?.monthly_landlord_fees)}
        equityContribution={equityContributionTmp}
        interestRate={interestRateTmp / 100}
        monthlyLoanBudget={monthlyLoanBudgetTmp}
        propertyPrice={data?.data?.getMatchingById?.property?.finance?.expected_price}
        renovationCost={data?.data?.getMatchingById?.property?.renovation?.renovation_cost}
        lsiFees={data?.data?.getMatchingById?.property?.finance?.lsi_fees}
        outcomeValues={{
          monthlyLoanBudgetTmp,
          monthly_real_estate_agency_fees: dataFinance?.property_cash_flow?.monthly_real_estate_agency_fees,
          monthly_insurance_fees: dataFinance?.property_cash_flow?.monthly_insurance_fees,
          monthly_property_tax: dataFinance?.property_cash_flow?.monthly_property_tax,
          monthly_landlord_fees: dataFinance?.property_cash_flow?.monthly_landlord_fees,
        }}
        notaryFees={data?.data?.getMatchingById?.property?.finance?.notary_fees}
        inflationValue={data?.data?.getMatchingById?.property?.finance?.inflation}
        inflationPrice={data?.data?.getMatchingById?.property?.finance?.inflation_price}
      />
    </Box>
  );
}

function SliderComponent({
  sliderValue, setSliderValue, title, titleValue, icon, min, max, step, displayValue = false,
}: any) {
  return (
    <Box>
      <Grid
        container
        columns={2}
        display="flex"
        width="auto"
        height="40px"
        marginTop={4}
      >
        <Grid item xs={1} display="flex" alignItems="center">
          <Box
            width="35px"
            height="35px"
            borderRadius={30}
            bgcolor="text.primary"
            marginRight={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              component="img"
              src={icon}
              width="25px"
              sx={{ objectFit: 'cover', display: 'block' }}
            />
          </Box>
          <Typography fontWeight="400" variant="h6" marginRight={1}>
            {title}
          </Typography>
          {displayValue
            && (
              <Typography color="primary" fontWeight="400" variant="h6">
                {`${sliderValue}%`}
              </Typography>
            )}
        </Grid>
        <Grid item xs={1} display="flex" alignItems="center" justifyContent="flex-end">
          <Typography
            textAlign="right"
            variant="h6"
            color="primary"
          >
            {titleValue}
          </Typography>
        </Grid>
      </Grid>
      <Box height="60px">
        <Slider
          color="secondary"
          sx={{
            height: 5,
          }}
          valueLabelDisplay="auto"
          step={step}
          value={sliderValue}
          min={min}
          onChange={(e: any, value: any) => {
            setSliderValue(value);
          }}
          max={max}
        />
      </Box>
    </Box>
  );
}

function LittleCircle({
  color, icon, value, colorValue,
}: any) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      marginY={2}
      sx={{
        flexWrap: { xs: 'wrap', md: 'inherit' },
      }}
    >
      <Box
        width="60px"
        height="60px"
        borderRadius={100}
        bgcolor={color}
        display="flex"
        marginRight={1}
        alignItems="center"
        justifyContent="center"
      >
        <Box
          component="img"
          src={icon}
          width="50px"
          sx={{ objectFit: 'cover', display: 'block' }}
        />
      </Box>
      <Typography color={colorValue} fontWeight="bold">{value}</Typography>
    </Box>
  );
}

function Bar({
  title, value, color, icon, chip = false,
}: any) {
  return (
    <Box
      width="90%"
      paddingY={2}
      marginY={1}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        borderRadius: '5px',
        border: '0.3px solid',
        borderColor: alpha('#1a2836', 0.1),
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        sx={{
          paddingLeft: { xs: 1.5, md: 5 },
        }}
      >
        <Box
          width="40px"
          height="40px"
          borderColor="secondary.main"
          border="1px solid"
          borderRadius={100}
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            marginRight: { xs: 1, md: 2 },
          }}
        >
          <Box
            component="img"
            src={icon}
            width="30px"
            sx={{ objectFit: 'cover', display: 'block' }}
          />
        </Box>
        <Typography variant="body1">{title}</Typography>
      </Box>
      {chip && <Chip label="Optionnel" />}
      <Typography
        variant="h6"
        color={color}
        sx={{
          paddingRight: { xs: 1.5, md: 5 },
        }}
      >
        {value}
      </Typography>
    </Box>
  );
}

export {
  Funding,
};
