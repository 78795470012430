import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import percentage_white from '../../../assets/property/percentage_white.png';
import { euro } from '../../../common/inputs/format/euro.format';

function PricePerM2({ data, color }: any) {
  return (
    <Box>
      <Divider sx={{ marginY: 5 }} />
      <Typography
        variant="h6"
        marginTop={4}
        gutterBottom
        fontWeight="400"
        color="text.primary"
        sx={{
          textAlign: { xs: 'center', md: 'left' },
        }}
      >
        Prix/m2
      </Typography>
      <Typography
        fontWeight="200"
        marginBottom={3}
        gutterBottom
        sx={{
          textAlign: { xs: 'center', md: 'left' },
        }}
      >
        Comparez votre appartement avec
        les biens similaires du même quartier
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography
          variant="h6"
          marginRight={1}
          fontWeight="300"
          sx={{
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          Quartier
        </Typography>
        <Typography
          variant="h6"
          color="text.primary"
          fontWeight="400"
          sx={{
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          {euro.format(data?.data?.getMatchingById?.property?.finance?.district_price_per_m2)}
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-around"
        marginTop={4}
        sx={{
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
          height="100px"
          sx={{
            width: { xs: '100%', md: '50%' },
          }}
        >
          <Box display="flex" alignItems="center" height="40px" justifyContent="center">
            <Box paddingY={1} display="flex">
              <Typography
                marginRight={1}
                fontWeight="300"
                variant="h6"
                sx={{
                  textAlign: { xs: 'center', md: 'left' },
                }}
              >
                Prix/m2 avant travaux
              </Typography>
              <Typography
                color={color?.darkGreen}
                fontWeight="600"
                variant="h6"
                sx={{
                  textAlign: { xs: 'center', md: 'left' },
                }}
              >
                {data?.data?.getMatchingById?.property?.finance?.expected_price_per_m2 ? euro.format(data?.data?.getMatchingById?.property?.finance?.expected_price_per_m2) : ''}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" height="40px" justifyContent="center">
            <Box paddingY={1} display="flex">
              <Typography
                marginRight={1}
                fontWeight="300"
                variant="h6"
                sx={{
                  textAlign: { xs: 'center', md: 'left' },
                }}
              >
                Prix/m2 après travaux
              </Typography>
              <Typography
                color={color?.darkGreen}
                fontWeight="600"
                variant="h6"
                sx={{
                  textAlign: { xs: 'center', md: 'left' },
                }}
              >
                {data?.data?.getMatchingById?.property?.finance?.expected_price_per_m2_after_renovation ? euro.format(data?.data?.getMatchingById?.property?.finance?.expected_price_per_m2_after_renovation) : ''}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          bgcolor="primary.main"
          sx={{
            height: { xs: '1px', md: '130px' },
            width: { xs: '100%', md: '1px' },
            marginY: { xs: 3, md: 0 },
          }}
        />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          sx={{
            width: { xs: '100%', md: '50%' },
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            sx={{
              height: { xs: '130px', md: '100px' },
            }}
          >
            <Box height="40px" width="100%">
              <Box bgcolor={color?.green} paddingX={5} borderRadius={20} position="relative" paddingY={1}>
                <Box
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="25px"
                  height="25px"
                  bgcolor="text.primary"
                  borderRadius={30}
                  left="-5px"
                  top="-10px"
                >
                  <Box
                    component="img"
                    src={percentage_white}
                    width="25px"
                    sx={{ objectFit: 'cover' }}
                  />
                </Box>
                <Typography
                  variant="body1"
                  fontWeight="400"
                  textAlign="center"
                >
                  {data?.financialInformations?.calcBeforeRenovation}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export {
  PricePerM2,
};
