import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import { useDeviceSize } from '../../hooks/utils/device-size.hook';
import { useFirstCalendlyMeetingBooked } from '../../hooks/mutation/first-calendly-meeting-booked-mutation.hook';
import { getStoredTypeform } from '../../authentication/components/typeform-recording';

function CalendlyContainer({ queryInfo }: any) {
  const { desktopSize } = useDeviceSize();
  const [email, setEmail] = useState('');
  const [calendlyUrl, setCalendlyUrl] = useState('https://calendly.com/lessecretsdelimmo/15min');
  const [location, setLocation] = useState('');
  const navigate = useNavigate();
  const { search } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const storedTypeform = getStoredTypeform();

  useEffect(() => {
    const _email = queryInfo?.data?.findUserLoggedIn?.email;
    const _phone = queryInfo?.data?.findUserLoggedIn?.phoneNumber;

    if (_email) {
      setEmail(_email);
    }

    if (_phone) {
      setLocation(_phone);
    }
  }, [queryInfo, search, navigate]);

  const mutate = useFirstCalendlyMeetingBooked({
    onSuccess: () => {
      queryInfo.refetch();
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
    onSettled: () => {
    },
  });

  useCalendlyEventListener({
    onProfilePageViewed: () => {
    },
    onDateAndTimeSelected: (date) => {
    },
    onEventTypeViewed: () => {
    },
    onEventScheduled: (e) => {
      mutate({
        // @ts-ignore
        calendlyUrl: e?.data?.payload?.event?.uri,
      });
      const userId = localStorage.getItem('userId') || null;
      // @ts-ignore
      analytics.track({
        event: 'User Phone Call Scheduled',
        userId,
        properties: {
          object: 'User',
          action: 'Phone Call Scheduled',
        },
      });
    },
  });

  useEffect(() => {
    if (storedTypeform?.investor?.toLowerCase() === 'oui' || queryInfo?.data?.findUserLoggedIn?.typeformInfo?.investor?.toLowerCase() === 'oui') {
      return setCalendlyUrl('https://calendly.com/contact-lsi/15min');
    }
    return setCalendlyUrl('https://calendly.com/lessecretsdelimmo/15min');
  }, [storedTypeform, queryInfo]);

  return (
    <InlineWidget
      styles={{
        width: desktopSize ? '60%' : '100%',
        height: '600px',
      }}
      url={calendlyUrl}
      pageSettings={{
        hideEventTypeDetails: true,
        hideLandingPageDetails: true,
        hideGdprBanner: true,
      }}
      prefill={{
        email,
        location,
      }}
    />
  );
}

export {
  CalendlyContainer,
};
