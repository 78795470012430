import React, { useEffect, useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import CheckIcon from '@mui/icons-material/Check';
import ConstructionIcon from '@mui/icons-material/Construction';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { useLocation, useNavigate } from 'react-router-dom';
import { AutocompleteCustom } from '../../../common/inputs/autocompletecustom';
import { TextFieldCustom } from '../../../common/inputs/textfield';
import { RadioCustom } from '../../../common/inputs/radio';
import { GridContainerFormInput, SectionTitle, SubmitButtonForm } from '../containerform';
import { AllowedSeniority } from '../../../api';
import { bankOptions, employmentContractOptions } from '../../../data/data';
import { useUpdatePersonalInfo } from '../../../hooks/mutation/update-personal-info-mutation.hook';

interface IFormInput {
  job: string
  employer_name: string
  employment_contract: string
  seniority: string
  main_bank: string
  other_banks: []
}

function ProfessionalSituationContent({
  data, index, queryInfo, indexMax, to, indexAccordion, setIndexAccordion,
}: any) {
  const navigate = useNavigate();
  const { search } = useLocation();
  const {
    control, reset, handleSubmit, watch, getValues,
  } = useForm<IFormInput>({
    defaultValues: useMemo(() => {
      return {
        ...data?.personal_info,
        other_banks: data?.personal_info?.other_banks
          .map((bank: string) => bankOptions.find((elem) => elem.value === bank))
          || [],
      };
    }, [data]),
  });

  useEffect(() => {
    reset({
      ...data?.personal_info,
      other_banks: data?.personal_info?.other_banks
        .map((bank: string) => bankOptions.find((elem) => elem.value === bank))
        || [],
    });
  }, [queryInfo.isSuccess]);

  const { enqueueSnackbar } = useSnackbar();

  const mutate = useUpdatePersonalInfo({
    onSuccess: () => {
      // eslint-disable-next-line react/prop-types
      queryInfo.refetch();
      setIndexAccordion(indexAccordion + 1);
      return index === indexMax - 1 ? navigate(to + search) : null;
    },
    onError: (err: any) => {
      enqueueSnackbar(err.message);
    },
  });

  const btnLabel = index === indexMax - 1 ? "Valider et passer à l'étape suivante" : 'Enregistrer';

  const onSubmit = (form: any): any => {
    const formValid: any = {
      personalInformations: {
        job: form?.job,
        employer_name: form?.employer_name,
        employment_contract: form?.employment_contract?.value,
        seniority: form?.seniority,
        main_bank: form?.main_bank?.value,
        other_banks: form?.other_banks?.map((e: any) => e?.value),
      },
      customerId: data?._id,
    };
    mutate({ ...formValid });
    // @ts-ignore
    analytics.track('User Clicked On Call To Action', {
      object: 'User',
      action: 'Clicked On Call To Action',
      click_url: to,
      click_text: btnLabel,
      location: 'form-update-professional-situation',
      category: 'submit',
    });
  };

  return (
    <Box
      component="form"
      id="professional-situation"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box
        sx={{ paddingX: { xs: 0, md: 7 } }}
      >
        <Box>
          <GridContainerFormInput>
            <Grid item xs={1}>
              <TextFieldCustom
                control={control}
                name="job"
                label="Profession"
              />
              <AutocompleteCustom
                rules={{ required: 'Veuillez renseigner votre contrat de travail' }}
                tab={employmentContractOptions}
                control={control}
                name="employment_contract"
                label="Contrat de travail *"
              />
            </Grid>
            <Grid item xs={1}>
              <TextFieldCustom
                control={control}
                name="employer_name"
                label="Nom de votre employeur"
              />
            </Grid>
          </GridContainerFormInput>
        </Box>
        <RadioCustom
          xsSize={120}
          mdSize={200}
          title="Ancienneté"
          // @ts-ignore
          display={getValues('employment_contract')?.value === 'CDI' || getValues('employment_contract') === 'CDI'}
          name="seniority"
          tab={seniority}
          control={control}
        />
        <RadioCustom
          xsSize={120}
          mdSize={200}
          title="Ancienneté"
          // @ts-ignore
          display={watch('employment_contract')?.value === 'ENTREPRENEUR'
            || watch('employment_contract') === 'ENTREPRENEUR'
            // @ts-ignore
            || watch('employment_contract')?.value === 'INDEPENDENT'
            || watch('employment_contract') === 'INDEPENDENT'
            // @ts-ignore
            || watch('employment_contract')?.value === 'LIBERAL_PROFESSION'
            || watch('employment_contract') === 'LIBERAL_PROFESSION'}
          name="seniority"
          tab={seniority2}
          control={control}
        />
        <Box>
          <SectionTitle text="Votre banque personnelle" />
          <GridContainerFormInput>
            <Grid item xs={1}>
              <AutocompleteCustom
                tab={bankOptions}
                control={control}
                name="main_bank"
                label="Banque principale"
              />
            </Grid>
            <Grid item xs={1}>
              <AutocompleteCustom
                tab={bankOptions}
                control={control}
                name="other_banks"
                label="Autre banque ?"
                multiple
              />
            </Grid>
          </GridContainerFormInput>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              marginTop: {
                xs: 2,
                md: 'inherit',
              },
              justifyContent: {
                xs: 'center',
                md: 'flex-end',
              },
            }}
          >
            <SubmitButtonForm index={index} indexMax={indexMax} label={btnLabel} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const seniority = [{
  label: 'Période d\'essai',
  value: AllowedSeniority.TRIAL_PERIOD,
  renderIcon: () => <ConstructionIcon sx={PropsIcon} />,
},
{
  label: 'Hors période d\'essai',
  value: AllowedSeniority.OUT_OF_TRIAL_PERIOD,
  renderIcon: () => <CheckIcon sx={PropsIcon} />,
}];

const seniority2 = [{
  label: 'Moins de 3 ans',
  value: AllowedSeniority.UNDER_THREE_YEARS,
  renderIcon: () => <HorizontalRuleIcon sx={PropsIcon} />,
},
{
  label: 'Plus de trois ans',
  value: AllowedSeniority.MORE_THREE_YEARS,
  renderIcon: () => <AddIcon sx={PropsIcon} />,
}];

const PropsIcon = {
  color: 'secondary.main',
  fontSize: '35px',
};

export {
  ProfessionalSituationContent,
};
