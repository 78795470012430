import { Box, Typography } from '@mui/material';
import React from 'react';
import Confetti from 'react-confetti';
import { useDeviceSize } from '../../hooks/utils/device-size.hook';

function CongratBar() {
  const { ipadSize } = useDeviceSize();

  return (
    <Box width="100%" display="flex" alignItems="center" justifyContent="center" height="100px" marginBottom={5}>
      <Confetti
        style={{ maxWidth: '1400px', width: '100%' }}
        height={ipadSize ? 120 : 100}
      />
      <Typography
        sx={{
          backgroundImage: 'linear-gradient(90.01deg, #CCA035 1.55%, #EC9FA5 57.56%, #C8EED0 99.18%)',
          backgroundClip: 'text',
          textFillColor: 'transparent',
        }}
        textAlign="center"
        variant="h4"
      >
        Félicitations, vous serez bientôt propriétaire de ce bien !
      </Typography>
    </Box>
  );
}

export {
  CongratBar,
};
