import { useCreateProjectMutation } from '../../api';
import { useGetAccessToken } from '../auth/get-access-token.hook';

const useCreateProject = (options: any) => {
  const { headerWithAuth } = useGetAccessToken();
  const { mutate } = useCreateProjectMutation(headerWithAuth, options);

  return mutate;
};

export {
  useCreateProject,
};
