import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Box, Button, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSnackbar } from 'notistack';
import { MaxWidthContainer } from '../../utils/components/max-width-container';
import heroIcon from '../../assets/referral/icon-hero.svg';

interface Props {
  text: {
    title: string
    subtitle: string[],
    button: string
    codeLabel: string
  }
  code: string
}

function Hero({ text, code }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const linkWithRefferalCode = `${process.env.REACT_APP_AUTH0_REDIRECT_URI}?screen_hint=signup&referral_code=${code}`;

  return (
    <Box width="100%" paddingY={{ xs: 8, md: 10 }}>
      <MaxWidthContainer>
        <Box width={{ xs: '95%', md: '80%' }} display="flex">
          <Box
            width={{ xs: '100%', md: '60%' }}
            display="flex"
            alignItems={{ xs: 'center', md: 'flex-start' }}
            justifyContent={{ xs: 'center', md: 'flex-start' }}
            flexDirection="column"
          >
            <Typography
              whiteSpace={{ xs: 'inherit', sm: 'pre' }}
              variant="h2"
              textAlign={{ xs: 'center', md: 'inherit' }}
              fontWeight="500"
              marginBottom={4}
            >
              {text.title}
            </Typography>
            <Typography
              whiteSpace={{ xs: 'inherit', sm: 'pre' }}
              textAlign={{ xs: 'center', md: 'inherit' }}
              variant="h6"
              fontWeight={100}
              fontStyle="italic"
              marginBottom={5}
              lineHeight="29px"
            >
              {text.subtitle[0]}
              <Box component="u">{text.subtitle[1]}</Box>
            </Typography>
            <CopyToClipboard
              text={code && linkWithRefferalCode}
              onCopy={() => {
                if (!code) {
                  enqueueSnackbar('Une erreur est survenue');
                }
                enqueueSnackbar('Lien copié dans le presse-papiers !', { variant: 'success' });
              }}
            >
              <Button
                endIcon={<ContentCopyIcon />}
                sx={{
                  backgroundColor: (theme) => theme.palette.success.light,
                  borderRadius: '45px',
                  color: 'secondary.main',
                  padding: '16px 52px 16px 32px',
                  gap: '12px',
                }}
              >
                <Typography variant="body2" letterSpacing={{ xs: 'inherit', md: '0.1rem' }} fontWeight={500}>
                  {text.button}
                </Typography>
              </Button>
            </CopyToClipboard>
            <Typography display="flex" whiteSpace="pre" marginTop={3} variant="caption" fontWeight={700} letterSpacing="0.1em" textTransform="uppercase">
              {text.codeLabel}
              <Box fontWeight={100} component="p">{code}</Box>
            </Typography>
          </Box>
          <Box width="40%" display={{ xs: 'none', md: 'flex' }} alignItems="center" justifyContent="center">
            <Box
              component="img"
              src={heroIcon}
              sx={{
                width: '354px',
                objectFit: 'cover',
              }}
            />
          </Box>
        </Box>
      </MaxWidthContainer>
    </Box>
  );
}

export {
  Hero,
};
