const referralText = {
  hero: {
    title: 'Parrainez un ami\net recevez 1500€',
    subtitle: ['Programme Exclusif Ambassadeur 2023\nréservé aux clients ', 'Les Secrets de l’Immo'],
    button: 'Copier mon lien de parrainage ',
    codeLabel: 'Mon code Parrain : ',
  },
  timeline: {
    title: 'Plus vous parrainez, plus vous gagnez !',
    dot1: {
      value: '1500€',
      label: 'Par parrainage\n ',
    },
    dot2: {
      value: '1700€',
      label: 'Par parrainage\ndès le 3ème',
    },
    dot3: {
      value: '2000€',
      label: 'Par parrainage\ndès le 5ème',
    },
    dot4: {
      value: '2500€',
      label: 'Par parrainage\ndès le 7ème',
    },
  },
  referral: {
    title: 'Comment parrainer ?',
    dot1: 'Parlez de votre expérience avec Les Secrets de l’Immo auprès de votre\nréseau',
    dot2: 'Partagez votre lien personnalisé, vos filleuls pourront directement\nprogrammer un appel avec l’un de nos conseillers',
    dot3: 'Un filleul devient client ?\nVous recevez 1500€',
    dot4: 'Vous débloquez de nouveaux paliers et gagnez plus à chaque nouveau\nparrainage',
  },
  faq: {
    title: 'faq',
    faq: [{
      answer: 'Comment savoir si un filleul devient client ?',
      response: 'Dès que votre filleul accepte d’investir dans un bien via les Secrets de l’Immo, vous recevez un email pour vous confirmer le parrainage.',
    }, {
      answer: 'Sous combien de temps suis-je payé lorsque je parraine ?',
      response: 'Vous êtes payé dès que votre filleul achète un bien via les Secrets de l’Immo. Il faut compter 3 à 6 mois entre le premier contact et l’acte d’achat.',
    }, {
      answer: 'Que se passe-t’il si j’ai recommandé Les Secrets de l’Immo à un ami mais qu’il n’est pas passé par mon lien de parrainage ?',
      response: 'Vous pouvez nous contacter par email ou par téléphone pour nous partager l’identité de votre filleul, et ce dernier peut aussi vous mentionner auprès de son conseiller. Nous vous attribuerons le parrainage manuellement.',
    }, {
      answer: 'Est-il possible de parrainer des membres de ma famille ?',
      response: 'Oui, tant que vous n’êtes pas co-investisseur sur l’opération, vous pouvez parrainer tout type de filleul : ami, famille, collègue, connaissance, etc.',
    }],
  },
};

export {
  referralText,
};
