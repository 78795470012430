import { useState } from 'react';
import { useFindUserLoggedInQuery } from '../../api';
import { useGetAccessToken } from '../auth/get-access-token.hook';

const useFindUserLoggedIn = () => {
  const { accessToken, headerWithAuth } = useGetAccessToken();
  const [identify, setIdentify] = useState<boolean | any>(null);

  const queryInfo = useFindUserLoggedInQuery(
    headerWithAuth,
    {},
    { enabled: !!accessToken },
  );
  if (queryInfo?.data?.findUserLoggedIn?._id && !identify) {
    // @ts-ignore
    analytics.identify(queryInfo?.data?.findUserLoggedIn?._id, { ...queryInfo?.data?.findUserLoggedIn });
    setIdentify(true);
  }

  return queryInfo;
};

export { useFindUserLoggedIn };
