import {
  Accordion, AccordionDetails, AccordionSummary, Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import { Summary } from './summary';
import { useDeviceSize } from '../../hooks/utils/device-size.hook';

function Item({
  details, summary, defaultExpanded, iconLabel,
}: any) {
  const { desktopSize } = useDeviceSize();
  return (
    <Box
      width="100%"
      justifyContent="center"
      display="flex"
      marginTop={1}
    >
      <Accordion
        defaultExpanded={defaultExpanded}
        elevation={0}
        sx={{
          width: '100%',
          display: 'flex',
          bgcolor: (theme) => theme.palette.primary.light,
          borderRadius: '0px 10px 10px 10px !important',
          paddingX: { xs: 1, sm: 2 },
          paddingY: 2,
          flexDirection: 'column',
          boxSizing: 'border-box',
          boxShadow: 'none',
          ':before': {
            content: 'none',
          },
        }}
      >
        <AccordionSummary
          expandIcon={(
            <ExpandMoreIcon
              fontSize={desktopSize ? 'large' : 'small'}
              color="secondary"
            />
          )}
        >
          <Summary item={summary} iconLabel={iconLabel} />
        </AccordionSummary>
        <AccordionDetails sx={{ paddingTop: 5 }}>
          {details}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export {
  Item,
};
