import React from 'react';
import { Box, Typography } from '@mui/material';
import { DisplayValue } from '.';
import cave from '../../../assets/property/arrangement/cave.png';
import parking from '../../../assets/property/arrangement/parking.png';
import ascenseur from '../../../assets/property/arrangement/ascenseur.png';
import jardin from '../../../assets/property/arrangement/jardin.png';
import box from '../../../assets/property/arrangement/box.png';
import gardien from '../../../assets/property/arrangement/caretaker.png';
import terrasse from '../../../assets/property/arrangement/terrasse.png';
import balcon from '../../../assets/property/arrangement/balcon.png';
import fibre from '../../../assets/property/arrangement/fibre.png';
import { internetFiberOptions } from '../../../data/data';

function Facilities({ data }: any) {
  return (
    <Box>
      <Typography
        variant="h6"
        fontWeight="400"
        marginTop={3}
        color="text.primary"
        sx={{
          textAlign: { xs: 'center', sm: 'left' },
        }}
      >
        Equipements actuels
      </Typography>
      <Typography
        fontWeight="200"
        gutterBottom
        sx={{
          textAlign: { xs: 'center', sm: 'left' },
        }}
      >
        Les équipements du bien
      </Typography>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        sx={{
          justifyContent: { sm: 'left', xs: 'center' },
        }}
      >
        <DisplayValue
          title="Cave"
          value={data?.data?.getMatchingById?.property?.arrangement?.basement_number}
          icon={cave}
        />
        <DisplayValue
          title="Parking"
          value={data?.data?.getMatchingById?.property?.arrangement?.parking_lot_number}
          icon={parking}
        />
        <DisplayValue
          title="Ascenseur"
          value={data?.data?.getMatchingById?.property?.arrangement?.elevator_number}
          icon={ascenseur}
        />
        <DisplayValue
          title="Jardin"
          value={data?.data?.getMatchingById?.property?.arrangement?.garden_number}
          icon={jardin}
        />
        <DisplayValue
          title="Box"
          value={data?.data?.getMatchingById?.property?.arrangement?.box_number}
          icon={box}
        />
        <DisplayValue
          title="Gardien"
          value={data?.data?.getMatchingById?.property?.arrangement?.caretaker_number}
          icon={gardien}
        />
        <DisplayValue
          title="Terrasse"
          value={data?.data?.getMatchingById?.property?.arrangement?.terrace_number}
          icon={terrasse}
        />
        <DisplayValue
          title="Balcon"
          value={data?.data?.getMatchingById?.property?.arrangement?.balcony_number}
          icon={balcon}
        />
        <DisplayValue
          title="Fibre"
          column
          value={internetFiberOptions?.find((f) => f?.value === data?.data?.getMatchingById?.property?.arrangement?.internet_fiber)?.label}
          icon={fibre}
        />
      </Box>
    </Box>
  );
}

export {
  Facilities,
};
