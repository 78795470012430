import { Box, Tooltip, Typography } from '@mui/material';
import React from 'react';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { PropertyLayout } from '../display-property';
import { fileLabelPropertyOptionsInput } from '../../../data/data';

function PropertyDocuments({ data }: any) {
  return (
    <PropertyLayout>
      <Typography fontWeight="600" marginBottom={4} variant="h5">Documents</Typography>
      <Box
        display="flex"
        flexWrap="wrap"
        sx={{
          justifyContent: { xs: 'space-around', md: 'flex-start' },
        }}
      >
        {data?.documents?.map((e: any) => (
          <Box
            key={e?._id}
            display="flex"
            margin={1}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{
              width: { xs: 150, md: 200 },
              paddingY: { xs: 1, md: 1.5 },

            }}
          >
            <InsertDriveFileIcon fontSize="large" color="secondary" />
            <Box display="flex" alignItems="center" justifyContent="center">
              <Typography variant="body1" textAlign="center">
                {fileLabelPropertyOptionsInput?.find((f) => f?.value === e?.type)?.label}
              </Typography>
            </Box>
            <Tooltip title={e?.path.split('-').map((elem: any) => elem).slice(1).join('-')}>
              <Box
                component="a"
                target="_blank"
                href={e?.url}
                rel="noreferrer"
                style={{ textDecoration: 'none' }}
              >
                <Typography
                  width="130px"
                  variant="body2"
                  textAlign="center"
                  color="primary"
                  sx={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    textDecoration: 'underline',
                    overflow: 'hidden',
                  }}
                >
                  {e?.path.split('-').map((elem: any) => elem).slice(1).join('-')}
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        ))}
      </Box>
    </PropertyLayout>
  );
}
export {
  PropertyDocuments,
};
