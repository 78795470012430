import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  alpha, Avatar, Box, Typography,
} from '@mui/material';
import logoLsi from '../../../../assets/common/logo_lsi_blanc.svg';
import { RateLink } from '../rate-link';
import { ButtonToggle, Menu } from './menu';

function DesktopHeader({
  menu,
  disabled,
  queryInfo,
  options,
  selectedValue,
  setSearchParams,
  queryUser,
  logout,
  setSelectedValue,
  bottomShadow,
  openMenu,
  setOpenMenu,
}: any) {
  const location = useLocation();
  const navigate = useNavigate();
  const firstArgsInUrl = location.pathname?.split('/')[1];

  return (
    <Box
      width="100%"
      position="fixed"
      height="70px"
      zIndex={100000}
      top={0}
      sx={{
        display: {
          xs: 'none',
          md: 'inherit',
        },
        bgcolor: 'secondary.main',
        boxShadow: bottomShadow ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : 'none',
      }}
    >
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection="row"
      >
        <Box
          width="20%"
          height="100%"
          display="flex"
          alignItems="center"
        >
          <Box
            marginLeft={5}
            component="img"
            onClick={() => navigate('/')}
            sx={{
              objectFit: 'cover',
              width: '110px',
              cursor: 'pointer',
            }}
            src={logoLsi}
          />
        </Box>
        <Box
          height="100%"
          display="flex"
          alignItems="center"
        >
          <Box marginRight={4}>
            <RateLink />
          </Box>
          <Box
            width="320px"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            borderLeft="1px solid"
            borderColor={(theme) => alpha(theme.palette.text.secondary, 0.30)}
          >
            <Box width="20%" display="flex" alignItems="center" justifyContent="center">
              <Avatar sx={{
                width: '38px',
                height: '38px',
                textTransform: 'uppercase',
                bgcolor: 'text.secondary',
                color: 'text.primary',
                fontWeight: 500,
              }}
              >
                {queryUser?.data?.findUserLoggedIn?.email[0] || '...'}
              </Avatar>
            </Box>
            <Box width="60%" display="flex" alignItems="center">
              <Typography
                variant="body1"
                color="text.secondary"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                overflow="hidden"
              >
                {queryUser?.data?.findUserLoggedIn?.email || '...'}
              </Typography>
            </Box>
            <Box width="20%" display="flex" alignItems="center" justifyContent="center">
              <ButtonToggle mode={openMenu} setMode={setOpenMenu} />
            </Box>
          </Box>
          <Menu
            queryInfo={queryInfo}
            queryUser={queryUser}
            menu={menu}
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
            options={options}
            disabled={disabled}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            setSearchParams={setSearchParams}
            firstArgsInUrl={firstArgsInUrl}
            logout={logout}
          />
        </Box>
      </Box>
    </Box>
  );
}

export {
  DesktopHeader,
};
