import { useGetProjectsByCurrentUserQuery } from '../../api';
import { useGetAccessToken } from '../auth/get-access-token.hook';

const useGetProjectsByCurrentUser = () => {
  const { accessToken, headerWithAuth } = useGetAccessToken();
  const queryInfo = useGetProjectsByCurrentUserQuery(
    headerWithAuth,
    {},
    { enabled: !!accessToken },
  );
  return queryInfo;
};
export {
  useGetProjectsByCurrentUser,
};
