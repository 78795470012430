import React from 'react';
import { Box, Typography } from '@mui/material';
import { DisplayValue } from '.';
import renovation from '../../../assets/property/renovation.png';
import chambre from '../../../assets/property/arrangement/chambre.png';
import salle_de_bain from '../../../assets/property/arrangement/salle_de_bain.png';
import wc from '../../../assets/property/arrangement/wc.png';
import buanderie from '../../../assets/property/arrangement/buanderie.png';

function FutureLayout({ data, color }: any) {
  return (
    <Box>
      <Box
        width="100%"
        display="flex"
        sx={{
          justifyContent: { xs: 'center', sm: 'flex-start' },
        }}
      >
        <Box
          bgcolor={color?.lightGold}
          borderRadius={20}
          display="flex"
          width="fit-content"
          justifyContent="center"
          alignItems="center"
          paddingX={3}
          paddingY={0.5}
          marginTop={3}
        >
          <Box
            component="img"
            src={renovation}
            sx={{
              objectFit: 'cover', display: 'block', width: '40px', height: '100%',
            }}
          />
          <Typography marginLeft={1}>Aménagement futur</Typography>
        </Box>
      </Box>
      <Typography
        marginTop={1}
        fontWeight="200"
        gutterBottom
        sx={{
          textAlign: { xs: 'center', sm: 'left' },
        }}
      >
        Les nouvelles pièces créées à l‘issue des travaux
      </Typography>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        sx={{
          justifyContent: { xs: 'center', sm: 'left' },
        }}
      >
        <DisplayValue
          title="Chambre"
          value={data?.data?.getMatchingById?.property?.arrangement?.futureLayout?.bedroom_number}
          icon={chambre}
        />
        <DisplayValue
          title="Salle de bain"
          value={data?.data?.getMatchingById?.property?.arrangement?.futureLayout?.bathroom_number}
          icon={salle_de_bain}
        />
        <DisplayValue
          title="WC"
          value={data?.data?.getMatchingById?.property?.arrangement?.futureLayout?.water_closet_number}
          icon={wc}
        />
        <DisplayValue
          title="Buanderie"
          value={data?.data?.getMatchingById?.property?.arrangement?.futureLayout?.laundry_room_number}
          icon={buanderie}
        />
      </Box>
    </Box>
  );
}

export {
  FutureLayout,
};
