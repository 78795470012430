import React, { useRef } from 'react';
import { Box, Paper } from '@mui/material';
import { PropertyTop } from './property-top';
import { Menu } from './menu';
import { FinancialInformations } from './financial-informations';
import { GeneralInformations } from './general-informations';
import { EstimateRenovation } from './renovation';
import { ButtonTop } from './button-top';
import { AllowedMatchingState } from '../../api';
import { CongratBar } from './congrat-bar';
import { PropertyDocuments } from './property-documents';

function DisplayProperty({ data, color, queryInfo }: any) {
  const section1 = useRef();
  const section2 = useRef();
  const section3 = useRef();
  const section4 = useRef();

  return (
    <Box
      marginBottom={15}
      position="relative"
      sx={{
        marginTop: { xs: 20, sm: 20 },
        width: '95%',
      }}
    >
      <Menu section1={section1} section2={section2} section3={section3} section4={section4} />
      {data?.data?.getMatchingById?.state === AllowedMatchingState.PENDING
        && (
        <ButtonTop queryInfo={queryInfo} />
        )}
      {data?.data?.getMatchingById?.state === AllowedMatchingState.ACCEPTED
        && (
          <CongratBar />
        )}
      <PropertyTop data={data} color={color} />
      <Box
        className="informationsGenerales"
        width="100%"
        marginY={4}
        ref={section1}
      >
        <GeneralInformations data={data} color={color} />
      </Box>
      <Box className="informationsFinancieres" width="100%" marginY={4} ref={section2}>
        <FinancialInformations data={data} color={color} />
      </Box>
      <Box className="estimationTravaux" width="100%" marginY={4} ref={section3}>
        <EstimateRenovation data={data} color={color} />
      </Box>
      <Box className="propertyDocuments" width="100%" marginY={4} ref={section4}>
        <PropertyDocuments data={data} color={color} />
      </Box>
    </Box>
  );
}

export function PropertyLayout({ children }: any) {
  return (
    <Paper sx={{
      borderRadius: '5px',
      boxShadow: 'none',
      border: 'solid 0.1px black',
    }}
    >
      <Box paddingY={3} paddingX={2}>
        {children}
      </Box>
    </Paper>
  );
}

export {
  DisplayProperty,
};
