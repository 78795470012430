import React from 'react';
import { Box } from '@mui/material';
import { MaxWidthContainer } from '../../../../utils/components/max-width-container';
import { Networks } from './networks';
import { MenuLinks } from './menu-links';
import { OptionsLinks } from './options-links';

function Footer({
  menu, disabled, logout, queryUser,
}: any) {
  return (
    <Box
      width="100%"
      bgcolor="secondary.main"
      paddingY={{ xs: 5, sm: 10 }}
      display={{ xs: 'none', md: 'flex' }}
    >
      <MaxWidthContainer>
        <Box display="flex" alignItems="center" width="100%" flexDirection={{ xs: 'column', sm: 'row' }}>
          <Networks />
          <Box
            display="flex"
            width={{ xs: '100%', sm: '60%' }}
            alignItems={{ xs: 'center', sm: 'inherit' }}
            justifyContent="flex-end"
            flexDirection={{ xs: 'column', sm: 'row' }}
            sx={{
              marginRight: {
                xs: 3,
                lg: 1,
              },
            }}
          >
            <MenuLinks
              menu={menu}
              disabled={disabled}
            />
            <OptionsLinks
              logout={logout}
              queryUser={queryUser}
            />
          </Box>
        </Box>
      </MaxWidthContainer>
    </Box>
  );
}

export {
  Footer,
};
